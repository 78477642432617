<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
			Your changes have been saved.
		</b-toast>

		<b-form @submit.prevent="save">
			<b-form-group label="Funding Source ID" :invalid-feedback="requiredFeedback($v.item.enteredIdentifier)">
				<b-form-input type="text" required
					v-model.trim="$v.item.enteredIdentifier.$model" :state="getValidState($v.item.enteredIdentifier)" />
			</b-form-group>

			<b-form-group label="Funding Source Name" :invalid-feedback="requiredFeedback($v.item.name)">
				<b-form-input type="text" required
					v-model.trim="$v.item.name.$model" :state="getValidState($v.item.name)" />
			</b-form-group>
			
			<b-row>
				<b-col md>
					<b-form-group label="Funding Type" :invalid-feedback="requiredFeedback($v.item.fundingType)">
						<b-typeahead v-model.trim="$v.item.fundingType.$model"
									 :data="setOptions.fundingTypes"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Funding Subtype" :invalid-feedback="requiredFeedback($v.item.fundingSubType)">
						<b-typeahead v-model.trim="$v.item.fundingSubType.$model"
									 :data="setOptions.fundingSubTypes"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>
				</b-col>
			</b-row>

			<b-row>
				<b-col md>
					<b-form-group label="FY Funded" :invalid-feedback="requiredFeedback($v.item.fiscalYearFunded)">
						<b-form-input type="number" step="1" required
							v-model.trim="$v.item.fiscalYearFunded.$model" :state="getValidState($v.item.fiscalYearFunded)" />
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Funding Amount" :invalid-feedback="requiredFeedback($v.item.fundingAmount)">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any"
								v-model.trim.number="$v.item.fundingAmount.$model" :state="getValidState($v.item.fundingAmount)" />
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>

			<b-row>
				<b-col md>
					<b-form-group label="Obligation Date" :invalid-feedback="requiredFeedback($v.item.obligationDate)">
						<b-form-input type="date" v-model.trim="$v.item.obligationDate.$model" :state="getValidState($v.item.obligationDate)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Expiration Date" :invalid-feedback="requiredFeedback($v.item.expirationDate)">
						<b-form-input type="date" v-model.trim="$v.item.expirationDate.$model" :state="getValidState($v.item.expirationDate)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<fixed-action-bar>
				<save-button :saving="page.saving" class="mr-2" />
				<back-button />
				<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
					You have errors in the form.
				</div>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.saveConfirmShow" title="Confirm Save" scrollable size="lg">
			<error-list :errors="page.errors"></error-list>

			<b-form-group label="Add a note to describe changes?" description="Optional">
				<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
			</b-form-group>

			<div slot="modal-footer">
				<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
				<b-button type="button" variant="primary" @click="page.saveConfirmShow = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required, integer, decimal } from 'vuelidate/lib/validators';
	import _ from 'underscore';

	export default {
		name: 'SrFundingSourceForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			setOptions: {
				type: Object,
				default: () => {
					return {
						fundingTypes: [],
						fundingSubTypes: [],
					}
				}
			}
		},
		data() {
			return {
				apiUrl: 'srfundingsources',
				editRoute: 'SrFundingSourceEdit',
				page: {
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				}
			}
		},
		validations: {
			item: {
				enteredIdentifier: { required},
				name: {},
				fundingType: {},
				fundingSubType: {},
				obligationDate: {},
				fundingAmount: { decimal },
				fiscalYearFunded: { integer },
				expirationDate: {}
			}
		},
		created() {
			this.get();
		},
		methods: {
			get() {
				if (this.isUpdate) {
					this.item.obligationDate = this.dateForForm(this.item.obligationDate);
					this.item.expirationDate = this.dateForForm(this.item.expirationDate);
				}
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.isUpdate) {
						this.page.saveConfirmShow = true;
					} else {
						try {
							const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
							this.log(response.data);

							this.$router.push({ name: this.editRoute, params: { id: response.data } });
						} catch (error) {
							if (this.isApiUnauthorized(error)) this.page.showLogin = true;
							else this.page.errors = this.logError(error);
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.id}`, data, this.getTokenHeader());
						this.log(response.data);

						this.page.saveConfirmShow = false;
						this.$bvToast.show('changes-saved');
						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			launchToast() {
				this.$bvToast.show('changes-saved');
			},
			logValidations() {
				this.log(this.$v);
			},
		}
	}
</script>
