<template>
	<div class="print-container">
		<auth-container :page="$parent.page">
			<print-menu></print-menu>

			<div class="d-flex mb-4">
				<h2 class="mr-2">Flood Control Structural Repair Program<br />TPN{{$parent.item.projectNumber}}</h2>
				<div class="ml-auto text-right">
					<img :src="`${publicPath}images/logos/tsswcb-logo.svg`" alt="TSSWCB Logo" class="img-fluid" style="width:170px">
				</div>
			</div>

			<b-row>
				<b-col md>
					<b-table-simple small class="table-definitions">
						<b-tbody>
							<b-tr>
								<b-th>TPN</b-th>
								<b-td>{{valueOrNa($parent.item.projectNumber)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Application #</b-th>
								<b-td>
									<span v-if="isNullOrEmpty($parent.item.applicationId)">N/A</span>
									<span v-else>{{$parent.application.name}}</span>
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>Dam</b-th>
								<b-td>
									<span v-if="isNullOrEmpty($parent.item.damId)">N/A</span>
									<span v-else>{{$parent.dam.name}}</span>
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>Project Name</b-th>
								<b-td>{{valueOrNa($parent.item.name)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Expected Completion Date</b-th>
								<b-td>{{$parent.item.expectedCompletionDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Completion Letter Submission Date</b-th>
								<b-td>{{$parent.item.completionSubmissionDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Award Date</b-th>
								<b-td>{{$parent.item.awardDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Category</b-th>
								<b-td>{{valueOrNa($parent.item.category)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Current Phase</b-th>
								<b-td>{{valueOrNa($parent.item.currentPhase)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Project Manager</b-th>
								<b-td>{{valueOrNa($parent.item.projectManager)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Overall Status</b-th>
								<b-td>{{valueOrNa($parent.item.status)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Status Notes</b-th>
								<b-td>{{valueOrNa($parent.item.statusNote, 'None')}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-col>
			</b-row>
		</auth-container>
	</div>
</template>

<script>
	import PrintMenu from '@/components/helpers/PrintMenu';

	export default {
		name: 'SrProjectPrint',
		components: { PrintMenu }
	}
</script>