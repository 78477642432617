<template>
	<div>
		<auth-container :page="page" v-if="$route.name === 'SrAgreementEdit'">
			<h2 class="mb-4">{{agreementType}} Agreement #{{item.agreementNumber}}</h2>

			<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
				<b-tab title="Overview">
					<b-row>
						<b-col md>
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Agreement #</b-th>
										<b-td>{{valueOrNa(item.agreementNumber)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Agreement Type</b-th>
										<b-td>{{agreementType}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>TPN</b-th>
										<b-td>
											<span v-if="isNullOrEmpty(item.projectId)">N/A</span>
											<router-link v-else :to="`/sr/projects/${item.projectId}`">{{project.projectNumber}} - {{project.name}}</router-link>
										</b-td>
									</b-tr>
									<b-tr>
										<b-th>Dam</b-th>
										<b-td>
											<span v-if="isNullOrEmpty(dam.id)">N/A</span>
											<router-link v-else :to="`/dams/${dam.id}`">{{dam.name}}</router-link>
										</b-td>
									</b-tr>
									<b-tr>
										<b-th>Funding Source</b-th>
										<b-td>
											<span v-if="isNullOrEmpty(item.fundingSourceId)">N/A</span>
											<router-link v-else :to="`/sr/fundingsources/${item.fundingSourceId}`">{{fundingSource.enteredIdentifier}} - {{fundingSource.name}}</router-link>
										</b-td>
									</b-tr>

									<b-tr>
										<b-th>Offeror</b-th>
										<b-td>{{valueOrNa(item.offeror)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Offeree</b-th>
										<b-td>{{valueOrNa(item.offeree)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Status</b-th>
										<b-td>{{valueOrNa(item.status)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Execution Date</b-th>
										<b-td>{{item.executionDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Notes</b-th>
										<b-td>{{valueOrNa(item.notes, 'None')}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
						<b-col md>
							
						</b-col>
					</b-row>

					<fixed-action-bar>
						<b-button type="button" variant="primary" to="print" append target="_blank" class="mr-2">Print</b-button>
						<back-button />
						<b-button v-if="isAuthorized(roleNames.admin)" type="button" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="Amendments">
					<b-table striped responsive small show-empty :fields="amendments.fields" :items="amendments.items">
						<template #cell(edit)="data">
							<font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'edit']" class="pointer text-primary" @click="editSubItem2(data.item.id)" />
						</template>
						<template #cell(delete)="data">
							<font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'times']" class="pointer text-danger" @click="deleteSubItem2(data.item.id)" />
						</template>
						<template #empty>
							<p class="my-2">This agreement does not have any amendments entered.</p>
						</template>
					</b-table>

					<fixed-action-bar>
						<b-button v-if="!isReadOnly" type="button" variant="success" @click="createSubItem2" class="mr-2">Create Amendment</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="Reimbursements">
					<b-table striped responsive small show-empty :fields="reimbursements.fields" :items="reimbursements.items">
						<template #cell(edit)="data">
							<font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'edit']" class="pointer text-primary" @click="editSubItem(data.item.id)" />
						</template>
						<template #cell(dam)="data">
							<router-link :to="`/dams/${data.value.id}`">{{data.value.name}}</router-link>
						</template>
						<template #cell(delete)="data">
							<font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'times']" class="pointer text-danger" @click="deleteSubItem(data.item.id)" />
						</template>
						<template #empty>
							<p class="my-2">This agreement does not have any reimbursements entered.</p>
						</template>

						<template #custom-foot>
							<b-tr class="bg-light border-bottom font-weight-bold">
								<b-td colspan="4">Total</b-td>
								<b-td class="text-right">${{ sum(reimbursements.items, 'stateConstructionCost') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(reimbursements.items, 'stateLandRights') | number(2)}}</b-td>
								<b-td></b-td>
								<b-td class="text-right">${{ sum(reimbursements.items, 'stateAdminCost') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(reimbursements.items, 'federalConstructionCost') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(reimbursements.items, 'federalLandRights') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(reimbursements.items, 'sponsorConstructionCost') | number(2)}}</b-td>
								<b-td colspan="3"></b-td>
							</b-tr>
						</template>
					</b-table>

					<fixed-action-bar>
						<b-button v-if="!isReadOnly" type="button" variant="success" @click="createSubItem" class="mr-2">Create Reimbursement</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="Edit" v-if="!isReadOnly">
					<sr-agreement-form ref="itemForm" :item="item" :project="projectForForm" :set-options="setOptions" is-update @change="changesSaved"></sr-agreement-form>
				</b-tab>
				<b-tab title="Files">
					<file-manager :apiUrl="apiUrl" :files="files" :itemId="itemId" itemLabel="application" @change="filesChanged"></file-manager>
				</b-tab>
				<b-tab title="Change Log">
					<log-viewer db-table="SrAgreements" :item-id="itemId"></log-viewer>
				</b-tab>
			</b-tabs>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete this agreement?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.deleteSubItem.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.deleteSubItem.errors"></error-list>

				<p>
					Are you sure you want to delete this reimbursement?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.deleteSubItem.saving" @click.native="confirmDeleteSubItem" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.deleteSubItem.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="modal.show" title="Reimbursement Information" scrollable size="lg" hide-footer>
				<error-list :errors="modal.errors"></error-list>

				<sr-agreement-reimbursement-form :item="modal.item" :is-update="modal.isUpdate" @change="modalSaved" @cancel="cancelModal"></sr-agreement-reimbursement-form>
			</b-modal>

			<b-modal v-model="page.deleteSubItem2.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.deleteSubItem2.errors"></error-list>

				<p>
					Are you sure you want to delete this amendment?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.deleteSubItem2.saving" @click.native="confirmDeleteSubItem2" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.deleteSubItem2.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="modal2.show" title="Amendment Information" scrollable size="lg" hide-footer>
				<error-list :errors="modal2.errors"></error-list>

				<sr-agreement-amendment-form :item="modal2.item" :is-update="modal2.isUpdate" @change="modalSaved2" @cancel="cancelModal2"></sr-agreement-amendment-form>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import FileManager from '@/components/FileManager';
	import SrAgreementForm from './SrAgreementForm.vue';
	import SrAgreementReimbursementForm from './SrAgreementReimbursementForm.vue';
	import SrAgreementAmendmentForm from './SrAgreementAmendmentForm.vue';

	export default {
		name: 'SrAgreementEdit',
		components: {
			SrAgreementForm, SrAgreementReimbursementForm, SrAgreementAmendmentForm, FileManager
		},
		data() {
			return {
				apiUrl: 'sragreements',
				listRoute: 'SrAgreementList',
				subItemApiUrl: 'sragreementreimbursements',
				subItemApiUrl2: 'sragreementamendments',
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					tabIndex: 0,
					delete: {
						show: false,
						errors: [],
						saving: false
					},
					deleteSubItem: {
						show: false,
						errors: [],
						saving: false,
						id: null
					},
					deleteSubItem2: {
						show: false,
						errors: [],
						saving: false,
						id: null
					}
				},
				item: {},
				project: {},
				dam: {},
				fundingSource: {},
				projectForForm: {},
				files: [],
				setOptions: {},
				reimbursements: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'invoiceNumber', label: 'Invoice#', sortable: true },
						{ key: 'receivedDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'wasStateRetainageRequested', label: 'State Retainage?', sortable: true, class: 'text-center', formatter: (value) => { return this.boolToText(value); } },
						{ key: 'stateConstructionCost', label: 'State Constr.', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'stateLandRights', label: 'State LR', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'wasAdminRequestedInAdvance', label: 'Admin Req. in Advance?', sortable: true, class: 'text-center', formatter: (value) => { return this.boolToText(value); } },
						{ key: 'stateAdminCost', label: 'State Admin', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'federalConstructionCost', label: 'Fed. Constr.', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'federalLandRights', label: 'Fed. LR', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'sponsorConstructionCost', label: 'Sponsor Constr.', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'approvedDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'paidDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'delete', label: '' }
					],
					items: []
				},
				amendments: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'revisionNumber', label: 'Rev#', sortable: true },
						{ key: 'amendmentDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'notes', sortable: true },
						{ key: 'expirationDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'constructionPercent', label: 'Constr. %', sortable: true, class: 'text-right', formatter: (value) => { return this.numberFormat(value, 2, '%'); } },
						{ key: 'constructionAmount', label: 'Constr. $', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'profServicesPercent', label: 'Pro. Serv. %', sortable: true, class: 'text-right', formatter: (value) => { return this.numberFormat(value, 2, '%'); } },
						{ key: 'profServicesAmount', label: 'Pro. Serv. $', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'adminPercent', label: 'Admin. %', sortable: true, class: 'text-right', formatter: (value) => { return this.numberFormat(value, 2, '%'); } },
						{ key: 'adminAmount', label: 'Admin. $', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'landRightsPercent', label: 'LR %', sortable: true, class: 'text-right', formatter: (value) => { return this.numberFormat(value, 2, '%'); } },
						{ key: 'landRightsAmount', label: 'LR $', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'nonCostShareAmount', label: 'Non CS', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'revisionDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'delete', label: '' }
					],
					items: []
				},
				modal: {
					item: {},
					show: false,
					loading: false,
					errors: [],
					isUpdate: false
				},
				modal2: {
					item: {},
					show: false,
					loading: false,
					errors: [],
					isUpdate: false
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.log(response.data);
					this.item = response.data.agreement;
					this.project = response.data.project;
					this.dam = response.data.dam;
					this.fundingSource = response.data.fundingSource;
					this.files = response.data.files;
					this.setOptions = response.data.setOptions;
					this.reimbursements.items = response.data.reimbursements;
					this.amendments.items = response.data.amendments;
					this.projectForForm = response.data.projectForForm;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async changesSaved() {
				await this.get();
				this.$refs.itemForm.launchToast();
				this.page.tabIndex = 3;
			},
			async filesChanged() {
				await this.get();
				this.page.tabIndex = 4;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$router.push({ name: this.listRoute });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			deleteSubItem(id) {
				this.page.deleteSubItem.id = id;
				this.page.deleteSubItem.show = true;
			},
			async confirmDeleteSubItem() {
				if (this.page.deleteSubItem.id !== null) {
					this.page.deleteSubItem.errors = [];
					this.page.deleteSubItem.saving = true;

					try {
						await this.$http.delete(`${this.subItemApiUrl}/${this.page.deleteSubItem.id}`, this.getTokenHeader());
						this.page.deleteSubItem.show = false;
						await this.get();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.deleteSubItem.errors = this.logError(error);
					}

					this.page.deleteSubItem.saving = false;
				}
			},
			async editSubItem(id) {
				await this.getModal(id);
				this.modal.show = true;
			},
			createSubItem() {
				this.resetModal();
				this.modal.show = true;
			},
			async modalSaved() {
				this.modal.show = false;
				this.resetModal();
				await this.get();
				this.$bvToast.show('subitem-saved');
			},
			cancelModal() {
				this.modal.show = false;
				this.resetModal();
			},
			resetModal() {
				this.modal.item = {
					agreementId: Number(this.itemId),
					invoiceNumber: null,
					receivedDate: null,
					wasStateRetainageRequested: false,
					stateConstructionCost: 0,
					stateLandRights: 0,
					wasAdminRequestedInAdvance: false,
					stateAdminCost: 0,
					federalConstructionCost: 0,
					federalLandRights: 0,
					sponsorConstructionCost: 0,
					approvedDate: null,
					paidDate: null
				}
				this.modal.isUpdate = false;
			},
			async getModal(id) {
				this.modal.loading = true;
				this.modal.errors = [];

				try {
					const response = await this.$http.get(`${this.subItemApiUrl}/${id}`, this.getTokenHeader());
					this.log(response.data);
					this.modal.item = response.data;
					this.modal.isUpdate = true;
				} catch (error) {
					this.modal.errors = this.logError(error);
				}

				this.modal.loading = false;
			},
			deleteSubItem2(id) {
				this.page.deleteSubItem2.id = id;
				this.page.deleteSubItem2.show = true;
			},
			async confirmDeleteSubItem2() {
				if (this.page.deleteSubItem2.id !== null) {
					this.page.deleteSubItem2.errors = [];
					this.page.deleteSubItem2.saving = true;

					try {
						await this.$http.delete(`${this.subItemApiUrl2}/${this.page.deleteSubItem2.id}`, this.getTokenHeader());
						this.page.deleteSubItem2.show = false;
						await this.get();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.deleteSubItem2.errors = this.logError(error);
					}

					this.page.deleteSubItem2.saving = false;
				}
			},
			async editSubItem2(id) {
				await this.getModal2(id);
				this.modal2.show = true;
			},
			createSubItem2() {
				this.resetModal2();
				if (this.amendments.items.length > 0) {
					this.modal2.item.revisionNumber = this.amendments.items[0].revisionNumber + 1;
				} 
				this.modal2.show = true;
			},
			async modalSaved2() {
				this.modal2.show = false;
				this.resetModal2();
				await this.get();
				this.$bvToast.show('subitem-saved2');
			},
			cancelModal2() {
				this.modal2.show = false;
				this.resetModal2();
			},
			resetModal2() {
				this.modal2.item = {
					agreementId: Number(this.itemId),
					revisionNumber: 0,
					amendmentDate: null,
					notes: null,
					expirationDate: null,
					constructionPercent: 0,
					constructionAmount: 0,
					profServicesPercent: 0,
					profServicesAmount: 0,
					adminPercent: 0,
					adminAmount: 0,
					landRightsPercent: 0,
					landRightsAmount: 0,
					nonCostShareAmount: 0,
					revisionDate: null
				}
				this.modal2.isUpdate = false;
			},
			async getModal2(id) {
				this.modal2.loading = true;
				this.modal2.errors = [];

				try {
					const response = await this.$http.get(`${this.subItemApiUrl2}/${id}`, this.getTokenHeader());
					this.log('hi')
					this.log(response.data);
					this.modal2.item = response.data;
					this.modal2.isUpdate = true;
				} catch (error) {
					this.modal2.errors = this.logError(error);
				}

				this.modal2.loading = false;
			}
		},
		computed: {
			itemId() {
				return this.$route.params.id;
			},
			agreementType() {
				return this.agreementTypeToName(this.$route.params.atype);
			},
		}
	}
</script>