<template>
	<div>
		<auth-container :page="page" v-if="$route.name === 'SrProjectEdit'">
			<h2 class="mb-4">TPN: {{item.projectNumber}}</h2>

			<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
				<b-tab title="Overview">
					<b-row>
						<b-col md>
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>TPN</b-th>
										<b-td>{{valueOrNa(item.projectNumber)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Project Name</b-th>
										<b-td>{{valueOrNa(item.name)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Project Manager</b-th>
										<b-td>{{valueOrNa(item.projectManager)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Expected Completion Date</b-th>
										<b-td>{{item.expectedCompletionDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Completion Letter Submission Date</b-th>
										<b-td>{{item.completionSubmissionDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Award Date</b-th>
										<b-td>{{item.awardDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Dam</b-th>
										<b-td>
											<span v-if="isNullOrEmpty(item.damId)">N/A</span>
											<router-link v-else :to="`/dams/${item.damId}`">{{dam.name}}</router-link>
										</b-td>
									</b-tr>
									<b-tr v-if="!isNullOrEmpty(item.damId)">
										<b-th><font-awesome-icon :icon="['fas', 'arrow-right']" fixed-width class="mx-3" /> Total Repair Need Cost</b-th>
										<b-td>
											{{money(dam.repairNeedCostTotal)}}
										</b-td>
									</b-tr>
									<b-tr v-if="!isNullOrEmpty(item.damId)">
										<b-th><font-awesome-icon :icon="['fas', 'arrow-right']" fixed-width class="mx-3" /> Repair Completed Date</b-th>
										<b-td>
											{{valueOrNa(dam.dateRepairCompleted)}}
										</b-td>
									</b-tr>
									<b-tr v-if="!isNullOrEmpty(item.damId)">
										<b-th><font-awesome-icon :icon="['fas', 'arrow-right']" fixed-width class="mx-3" /> Total Final Repair Cost</b-th>
										<b-td>
											{{money(dam.finalRepairCostTotal)}}
										</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
						<b-col md>
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Category</b-th>
										<b-td>{{valueOrNa(item.category)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Current Phase</b-th>
										<b-td v-if="isNullOrEmpty(currentPhaseUrl)">{{valueOrNa(item.currentPhase)}}</b-td>
										<b-td v-else><router-link :to="`${currentPhaseUrl}/${item.id}`">{{valueOrNa(item.currentPhase)}}</router-link></b-td>
									</b-tr>
									<b-tr>
										<b-th>Overall Status</b-th>
										<b-td>{{valueOrNa(item.status)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Status Notes</b-th>
										<b-td>{{valueOrNa(item.statusNote, 'None')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>General Notes</b-th>
										<b-td>{{valueOrNa(item.notes, 'None')}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
					</b-row>

					<h3 class="mb-3">Application</h3>
					<div v-if="!isNullOrEmpty(item.applicationId)">
						<b-table-simple small responsive class="border-bottom">
							<b-thead class="bg-light">
								<b-tr>
									<b-th></b-th>
									<b-th>App. #</b-th>
									<b-th>Date Received</b-th>
									<b-th>Request Type</b-th>
									<b-th>Funding Cycle</b-th>
									<b-th>SLO</b-th>
									<b-th>Primary Name</b-th>
									<b-th>Alternate Name</b-th>
									<b-th>Total Est. Cost</b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr>
									<b-td>
										<router-link :to="`/sr/applications/${application.id}`" title="View/Edit">
											<font-awesome-icon :icon="['fas', 'edit']" />
										</router-link>
									</b-td>
									<b-td>{{valueOrNa(application.appNum)}}</b-td>
									<b-td>{{application.receivedDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									<b-td>{{valueOrNa(application.requestType)}}</b-td>
									<b-td>{{valueOrNa(application.fundCycle)}}</b-td>
									<b-td>{{valueOrNa(application.sloAgent)}}</b-td>
									<b-td>{{valueOrNa(application.primaryName)}}</b-td>
									<b-td>{{valueOrNa(application.alternateName)}}</b-td>
									<b-td>{{money(application.totalEstimatedCost)}}</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
					</div>
					<div v-else>
						<p>No application linked to this project.</p>
					</div>

					<fixed-action-bar>
						<b-button type="button" variant="primary" to="print" append target="_blank" class="mr-2">Print</b-button>
						<back-button />
						<b-button v-if="isAuthorized(roleNames.admin)" type="button" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="Edit" v-if="!isReadOnly">
					<sr-project-form ref="itemForm" :item="item" :dam="dam" :application="application" :set-options="setOptions" is-update @change="changesSaved"></sr-project-form>
				</b-tab>
				<b-tab title="Files">
					<file-manager :apiUrl="apiUrl" :files="files" :itemId="itemId" itemLabel="project" @change="filesChanged"></file-manager>
				</b-tab>
				<b-tab title="Change Log">
					<log-viewer db-table="SrProjects" :item-id="itemId"></log-viewer>
				</b-tab>
			</b-tabs>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete this project?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import FileManager from '@/components/FileManager';
	import SrProjectForm from './SrProjectForm.vue';

	export default {
		name: 'SrProjectEdit',
		components: {
			SrProjectForm, FileManager
		},
		data() {
			return {
				apiUrl: 'srprojects',
				listRoute: 'SrProjectList',
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					tabIndex: 0,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				item: {},
				application: {},
				dam: {},
				files: [],
				setOptions: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.log(response.data);
					this.item = response.data.project;
					this.dam = response.data.dam;
					this.files = response.data.files;
					this.application = response.data.application;
					this.setOptions = response.data.setOptions;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async changesSaved() {
				await this.get();
				this.$refs.itemForm.launchToast();
				this.page.tabIndex = 1;
			},
			async filesChanged() {
				await this.get();
				this.page.tabIndex = 2;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$router.push({ name: this.listRoute });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			}
		},
		computed: {
			itemId() {
				return this.$route.params.id;
			},
			currentPhaseUrl() {
				switch (this.item.currentPhase) {
					case 'Dam Assessment':
						return '/sr/projects/dam-assessment';
					case 'Planning':
						return '/sr/projects/plan-env-assessment';
					case 'Reaffirming Study':
						return '/sr/projects/reaffirm-study';
					case 'Design':
						return '/sr/projects/design';
					case 'Construction':
					case 'Construction w/ Inspection':
					case 'Construction w/ Prof. Services':
						return '/sr/projects/construction';
					default:
						return '';
				}
			}
		}
	}
</script>