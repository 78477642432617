<template>
	<div class="print-container">
		<auth-container :page="$parent.page">
			<print-menu></print-menu>

			<div class="d-flex mb-4">
				<h2 class="mr-2">Flood Control Structural Repair Program<br />Invoice #{{$parent.item.invoiceNumber}}</h2>
				<div class="ml-auto text-right">
					<img :src="`${publicPath}images/logos/tsswcb-logo.svg`" alt="TSSWCB Logo" class="img-fluid" style="width:170px">
				</div>
			</div>

			<b-row>
				<b-col md>
					<b-table-simple small class="table-definitions">
						<b-tbody>
							<b-tr>
								<b-th>Invoice #</b-th>
								<b-td>{{valueOrNa($parent.item.invoiceNumber)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Work Order</b-th>
								<b-td>
									#{{$parent.workOrder.workOrderNumber}} {{$parent.workOrder.name}}
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>TPN</b-th>
								<b-td>
									{{$parent.project.projectNumber}} - {{$parent.project.name}}
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>Dam</b-th>
								<b-td>
									<span v-if="isNullOrEmpty($parent.dam.id)">N/A</span>
									<span>{{$parent.dam.name}}</span>
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>Invoice Submit Date</b-th>
								<b-td>{{$parent.item.invoiceSubmitDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Service Start Date</b-th>
								<b-td>{{$parent.item.serviceStartDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Service End Date</b-th>
								<b-td>{{$parent.item.serviceEndDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Reviewer</b-th>
								<b-td>{{valueOrNa($parent.item.reviewer)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Approval Date</b-th>
								<b-td>{{$parent.item.approvalDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Notes</b-th>
								<b-td>{{valueOrNa($parent.item.notes, 'None')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Labor Amount</b-th>
								<b-td>{{money($parent.item.labor)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Labor Amount</b-th>
								<b-td>{{money($parent.item.nonLabor)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Subconsultant Amount</b-th>
								<b-td>{{money($parent.item.subconsultant)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Total</b-th>
								<b-td>{{money($parent.item.labor + $parent.item.nonLabor + $parent.item.subconsultant)}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-col>
			</b-row>
		</auth-container>
	</div>
</template>

<script>
	import PrintMenu from '@/components/helpers/PrintMenu';

	export default {
		name: 'SrApplicationPrint',
		components: { PrintMenu }
	}
</script>