<template>
	<auth-container :page="page">
		<h2 class="mb-4">Create New Reaffirming Study Phase</h2>

		<sr-reaffirm-study-form :item="item" :project="project" :showNoExist="showNoExist"></sr-reaffirm-study-form>
	</auth-container>
</template>

<script>
	import SrReaffirmStudyForm from './SrReaffirmStudyForm.vue';
	export default {
		name: 'SrReaffirmStudyCreate',
		components: {
			SrReaffirmStudyForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				item: {
					projectId: null,
					name: null,
					finalReportDate: null,
					nrcsContact: null,
					assignedAe: null,
					status: 0
				},
				project: null,
				shoNoExist: false
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				if (!this.isNullOrEmpty(this.$route.query.projectId)) {
					try {
						const response = await this.$http.get(`srprojects/getname/${this.$route.query.projectId}`, this.getTokenHeader());
						this.project = response.data;
						this.showNoExist = true;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.loading = false;
			}
		}
	}
</script>