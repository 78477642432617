<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<div v-if="!page.saveConfirmShow">
				<b-form @submit.prevent="save">
					<b-form-group label="Revision #" :invalid-feedback="requiredFeedback($v.item.revisionNumber)">
						<b-form-input type="number" v-model.trim.number="$v.item.revisionNumber.$model" :state="getValidState($v.item.revisionNumber)"></b-form-input>
					</b-form-group>

					<b-form-group label="Revision Date" :invalid-feedback="requiredFeedback($v.item.revisionDate)">
						<b-form-input type="date" v-model.trim="$v.item.revisionDate.$model" :state="getValidState($v.item.revisionDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Expiration Date" :invalid-feedback="requiredFeedback($v.item.expirationDate)">
						<b-form-input type="date" v-model.trim="$v.item.expirationDate.$model" :state="getValidState($v.item.expirationDate)"></b-form-input>
					</b-form-group>
					<b-form-group label="Revision Notes" :invalid-feedback="requiredFeedback($v.item.notes)" class="mb-4">
						<b-form-textarea v-model.trim="$v.item.notes.$model" :state="getValidState($v.item.notes)" rows="3"></b-form-textarea>
					</b-form-group>

					<h3 class="h5 border-bottom my-4">TPN/Dam Revision Data</h3>

					<b-table-simple small class="table-definitions mb-5" bordered>
						<b-tbody>
							<b-tr class="bg-light">
								<b-th>TPN/Dam</b-th>
								<b-th>Funding $</b-th>
								<b-th>Revision Date</b-th>
								<b-th>Notes</b-th>
							</b-tr>
							<b-tr v-for="(r, i) in revs" :key="i">
								<b-td>TPN {{r.projectNumber}} / {{r.nid_id}}</b-td>
								<b-td>
									<b-input-group prepend="$">
										<b-form-input type="number" step="any" v-model.trim.number="r.fundingAmount" required />
									</b-input-group>
								</b-td>
								<b-td>
									<b-form-input type="date" v-model.trim="r.revisionDate" />
								</b-td>
								<b-td>
									<b-form-input type="text" v-model.trim="r.notes" />
								</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>

					<div class="mt-4 mb-3 d-flex align-items-center">
						<save-button :saving="page.saving" class="mr-2" />
						<b-button type="button" variant="secondary" @click="cancel" class="ml-1">Cancel</b-button>
						<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
							<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
							You have errors in the form.
						</div>
					</div>
				</b-form>
			</div>
			<div v-else>
				<b-form-group label="Add a note to describe changes?" description="Optional">
					<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
				</b-form-group>

				<div class="my-3">
					<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
					<b-button type="button" variant="secondary" @click="cancel" class="ml-1">Cancel</b-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { required, integer, decimal } from 'vuelidate/lib/validators';
	import _ from 'underscore';

	export default {
		name: 'SrWorkOrderDamForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			dams: {
				type: Array,
				required: true
			},
			damRevisions: {
				type: Array,
				required: false
			}
		},
		data() {
			return {
				apiUrl: 'srworkorderrevisions',
				apiUrl2: 'srworkorderdamrevisions',
				page: {
					loading: false,
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null,
					loadingAgreements: false
				},
				revs: [],
			}
		},
		validations: {
			item: {
				workOrderId: { required, integer },
				revisionNumber: { required, integer },
				revisionDate: {},
				expirationDate: {},
				notes: {},
			}
		},
		created() {
			this.get();
		},
		methods: {
			async get() {
				if (this.isUpdate) {
					this.item.revisionDate = this.dateForForm(this.item.revisionDate);
					this.revs = this.damRevisions;
				} else {
					for (let d of this.dams) {
						this.revs.push({
							workOrderDamId: d.id,
							workOrderRevisionId: null,
							projectId: d.projectId,
							projectNumber: d.projectNumber,
							nid_id: d.nid_id,
							damId: d.damId,
							fundingAmount: 0,
							revisionDate: null,
							notes: null
						});
					}
				}
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;
				this.logValidations();

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.page.errors.length < 1) {
						if (this.isUpdate) {
							this.page.saveConfirmShow = true;
						} else {
							try {
								const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
								this.log(response.data);

								let newId = response.data;
								for (let rev of this.revs) {
									let data = {
										workOrderDamId: rev.workOrderDamId,
										workOrderRevisionId: newId,
										fundingAmount: rev.fundingAmount,
										revisionDate: rev.revisionDate,
										notes: rev.notes
									};
									const response2 = await this.$http.post(this.apiUrl2, data, this.getTokenHeader());
									this.log(response2.data);
								}

								this.$emit('change');
							} catch (error) {
								if (this.isApiUnauthorized(error)) this.page.showLogin = true;
								else this.page.errors = this.logError(error);
							}
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.id}`, data, this.getTokenHeader());
						this.log(response.data);

						for (let rev of this.revs) {
							let data1 = {
								id: rev.id,
								workOrderDamId: rev.workOrderDamId,
								workOrderRevisionId: rev.workOrderRevisionId,
								fundingAmount: rev.fundingAmount,
								revisionDate: rev.revisionDate,
								notes: rev.notes
							};
							let data2 = {
								item1: data1,
								item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
							};
							const response2 = await this.$http.put(`${this.apiUrl2}/${rev.id}`, data2, this.getTokenHeader());
							this.log(response2.data);
						}

						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			logValidations() {
				this.log(this.$v);
			},
			cancel() {
				this.$emit('cancel');
			},
		}
	}
</script>
