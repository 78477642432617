<template>
	<auth-container :page="page">
		<h2 class="mb-4">Create New Budget Plan</h2>

		<sr-plan-form :item="item" :application="application"></sr-plan-form>
	</auth-container>
</template>

<script>
	import SrPlanForm from './SrPlanForm.vue';
	export default {
		name: 'SrPlanCreate',
		components: {
			SrPlanForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				item: {
					applicationId: null,
					fundCycle: null,
					designFederalReimbursement: 0,
					designStateCost: 0,
					landRightsFederalReimbursement: 0,
					landRightsStateCost: 0,
					landRightsSponsorsCost: 0,
					constructionFederalReimbursement: 0,
					constructionStateCost: 0,
					constructionSponsorsCost: 0,
					performedDate: null,
					notes: null
				},
				application: null
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				if (!this.isNullOrEmpty(this.$route.query.appId)) {
					try {
						const response = await this.$http.get(`srapplications/${this.$route.query.appId}`, this.getTokenHeader());
						this.application = response.data.application;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.loading = false;
			}
		}
	}
</script>