<template>
	<auth-container :page="page">
		<h2 class="mb-4">Create New {{agreementType}} Agreement</h2>

		<sr-agreement-form :item="item" :set-options="setOptions"></sr-agreement-form>
	</auth-container>
</template>

<script>
	import SrAgreementForm from './SrAgreementForm.vue';

	export default {
		name: 'SrAgreementCreate',
		components: {
			SrAgreementForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				item: {
					projectId: null,
					agreementNumber: null,
					agreementType: 0,
					fundingSourceId: null,
					offeror: null,
					offeree: null,
					status: null,
					executionDate: null,
					notes: null,
				},
				setOptions: {
					offerors: [],
					offerees: [],
					statuses: [],
					fundingSources: []
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					this.item.agreementType = this.agreementTypeToNum(this.$route.params.atype);
					const response = await this.$http.get(`sragreements/options`, this.getTokenHeader());
					this.setOptions = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		},
		computed: {
			agreementType() {
				return this.agreementTypeToName(this.$route.params.atype);
			},
		}
	}
</script>