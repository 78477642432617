<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
			Your changes have been saved.
		</b-toast>

		<b-form @submit.prevent="save">
			<b-row>
				<b-col md>
					<b-form-group label="Submission #" :invalid-feedback="requiredFeedback($v.item.submissionNumber)">
						<b-form-input type="number" step="1" required
									  v-model.trim.number="$v.item.submissionNumber.$model" :state="getValidState($v.item.submissionNumber)" />
					</b-form-group>

					<b-form-group label="Submission Date" :invalid-feedback="requiredFeedback($v.item.submissionDate)">
						<b-form-input type="date" v-model.trim="$v.item.submissionDate.$model" :state="getValidState($v.item.submissionDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Agency Staff" :invalid-feedback="requiredFeedback($v.item.agencyStaff)">
						<b-typeahead v-model.trim="$v.item.agencyStaff.$model"
									:data="options.agencyStaff"
									:max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Department" :invalid-feedback="requiredFeedback($v.item.department)">
						<b-typeahead v-model.trim="$v.item.department.$model"
									 :data="setOptions.departments"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Approval Notice Date" :invalid-feedback="requiredFeedback($v.item.approvalNoticeDate)">
						<b-form-input type="date" v-model.trim="$v.item.approvalNoticeDate.$model" :state="getValidState($v.item.approvalNoticeDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Funds Arrival Date" :invalid-feedback="requiredFeedback($v.item.fundsArrivalDate)">
						<b-form-input type="date" v-model.trim="$v.item.fundsArrivalDate.$model" :state="getValidState($v.item.fundsArrivalDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Funds Left Date" :invalid-feedback="requiredFeedback($v.item.fundsLeftDate)">
						<b-form-input type="date" v-model.trim="$v.item.fundsLeftDate.$model" :state="getValidState($v.item.fundsLeftDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Submission Category" :invalid-feedback="requiredFeedback($v.item.submissionCategory)">
						<b-typeahead v-model.trim="$v.item.submissionCategory.$model"
									 :data="setOptions.submissionCategories"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group label="Notes" :invalid-feedback="requiredFeedback($v.item.notes)">
				<b-form-textarea v-model.trim="$v.item.notes.$model" :state="getValidState($v.item.notes)" rows="5"></b-form-textarea>
			</b-form-group>

			<fixed-action-bar>
				<save-button :saving="page.saving" class="mr-2" />
				<back-button />
				<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
					You have errors in the form.
				</div>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.saveConfirmShow" title="Confirm Save" scrollable size="lg">
			<error-list :errors="page.errors"></error-list>

			<b-form-group label="Add a note to describe changes?" description="Optional">
				<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
			</b-form-group>

			<div slot="modal-footer">
				<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
				<b-button type="button" variant="primary" @click="page.saveConfirmShow = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required, integer, decimal } from 'vuelidate/lib/validators';
	import _ from 'underscore';

	export default {
		name: 'SrReimbursementForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			setOptions: {
				type: Object,
				default: () => {
					return {
						departments: [],
						submissionCategories: []
					}
				}
			}
		},
		data() {
			return {
				apiUrl: 'srreimbursementsubmissions',
				editRoute: 'SrReimbursementEdit',
				page: {
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				},
				options: {
					agencyStaff: [],
				},
			}
		},
		computed: {
			itemAgencyStaff() {
				return this.item.agencyStaff;
			},
		},
		validations: {
			item: {
				submissionNumber: { required, integer },
				agencyStaff: {},
				department: {},
				submissionCategory: {},
				submissionDate: {},
				approvalNoticeDate: {},
				fundsArrivalDate: {},
				fundsLeftDate: {},
				notes: {}
			}
		},
		watch: {
			itemAgencyStaff: _.debounce(function (query) { this.find('agencyStaff', query) }, 500),
		},
		created() {
			this.get();
		},
		methods: {
			get() {
				if (this.isUpdate) {
					this.item.submissionDate = this.dateForForm(this.item.submissionDate);
					this.item.approvalNoticeDate = this.dateForForm(this.item.approvalNoticeDate);
					this.item.fundsArrivalDate = this.dateForForm(this.item.fundsArrivalDate);
					this.item.fundsLeftDate = this.dateForForm(this.item.fundsLeftDate);
				}
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.isUpdate) {
						this.page.saveConfirmShow = true;
					} else {
						try {
							const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
							this.log(response.data);

							this.$router.push({ name: this.editRoute, params: { id: response.data } });
						} catch (error) {
							if (this.isApiUnauthorized(error)) this.page.showLogin = true;
							else this.page.errors = this.logError(error);
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.id}`, data, this.getTokenHeader());
						this.log(response.data);

						this.page.saveConfirmShow = false;
						this.$bvToast.show('changes-saved');
						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			launchToast() {
				this.$bvToast.show('changes-saved');
			},
			logValidations() {
				this.log(this.$v);
			},
			async find(field, query) {
				try {
					const response = await this.$http.get(`/${this.apiUrl}/find/${field}/${query}`, this.getTokenHeader());
					this.options[field] = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			}
		}
	}
</script>
