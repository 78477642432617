<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<div v-if="!page.saveConfirmShow">
				<b-form @submit.prevent="save">
					<b-form-group label="Document Type" :invalid-feedback="requiredFeedback($v.item.docType)">
						<b-form-select v-model.trim="$v.item.docType.$model" :state="getValidState($v.item.docType)" :options="docTypes">
							<template #first>
								<b-form-select-option :value="null" disabled>-- Select a type --</b-form-select-option>
							</template>
						</b-form-select>
					</b-form-group>

					<b-form-group v-if="item.docType == 0" label="Invoice" :invalid-feedback="requiredFeedback($v.query.invoice)">
						<b-typeahead v-model.trim="$v.query.invoice.$model" required placeholder="Type to search invoices by number..."
										:data="options.invoice == null ? [] : options.invoice"
										:serializer="s => s.name" @hit="item.invoiceId = $event.id;"
										:max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group v-if="item.docType == 1" label="Agreement Reimbursement" :invalid-feedback="requiredFeedback($v.query.agreementReimbursement)">
						<b-typeahead v-model.trim="$v.query.agreementReimbursement.$model" required placeholder="Type to search agreements by number..."
										:data="options.agreementReimbursement == null ? [] : options.agreementReimbursement"
										:serializer="s => s.name" @hit="item.agreementReimbursementId = $event.id;"
										:max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Total Amount" :invalid-feedback="requiredFeedback($v.item.totalAmount)">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any"
										  v-model.trim.number="$v.item.totalAmount.$model" :state="getValidState($v.item.totalAmount)" />
						</b-input-group>
					</b-form-group>

					<b-form-group label="State Admin Amount" :invalid-feedback="requiredFeedback($v.item.stateAdminAmount)">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any"
										  v-model.trim.number="$v.item.stateAdminAmount.$model" :state="getValidState($v.item.stateAdminAmount)" />
						</b-input-group>
					</b-form-group>

					<b-form-group label="State Voucher Number" :invalid-feedback="requiredFeedback($v.item.stateVoucherNumber)">
						<b-form-input type="text" 
								v-model.trim="$v.item.stateVoucherNumber.$model" :state="getValidState($v.item.stateVoucherNumber)" />
					</b-form-group>

					<div class="mt-4 mb-3 d-flex align-items-center">
						<save-button :saving="page.saving" class="mr-2" />
						<b-button type="button" variant="secondary" @click="cancel" class="ml-1">Cancel</b-button>
						<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
							<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
							You have errors in the form.
						</div>
					</div>
				</b-form>
			</div>
			<div v-else>
				<b-form-group label="Add a note to describe changes?" description="Optional">
					<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
				</b-form-group>

				<div class="my-3">
					<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
					<b-button type="button" variant="secondary" @click="cancel" class="ml-1">Cancel</b-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { required, integer, decimal } from 'vuelidate/lib/validators';
	import _ from 'underscore';

	export default {
		name: 'SrReimbursementDocForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			invoice: {
				type: Object,
				required: false
			},
			agreementReimbursement: {
				type: Object,
				required: false
			},
		},
		data() {
			return {
				apiUrl: 'srreimbursementsubmissiondocs',
				page: {
					loading: false,
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				},
				docTypes: [
					{ value: 0, text: 'Invoice' },
					{ value: 1, text: 'Reimbursement Request' }
				],
				options: {
					invoice: [],
					agreementReimbursement: []
				},
				query: {
					invoice: '',
					agreementReimbursement: ''
				},
			}
		},
		validations: {
			item: {
				submissionId: { required, integer },
				docType: { required },
				invoiceId: {},
				agreementReimbursementId: {},
				totalAmount: { required, decimal },
				stateAdminAmount: { required, decimal },
				stateVoucherNumber: {},
			},
			query: {
				invoice: {},
				agreementReimbursement: {}
			}
		},
		created() {
			this.get();
		},
		watch: {
			queryInvoice: _.debounce(function (query) { this.findInvoices(query) }, 500),
			queryAgreementReimbursement: _.debounce(function (query) { this.findAgreementReimbursements(query) }, 500),
		},
		computed: {
			queryInvoice() {
				return this.query.invoice;
			},
			queryAgreementReimbursement() {
				return this.query.agreementReimbursement;
			},
		},
		methods: {
			async get() {
				if (!this.isNullOrEmpty(this.invoice)) {
					this.query.invoice = this.invoice.name;
					this.item.invoiceId = this.invoice.id;
				}
				if (!this.isNullOrEmpty(this.agreementReimbursement)) {
					this.query.agreementReimbursement = this.agreementReimbursement.name;
					this.item.agreementReimbursementId = this.agreementReimbursement.id;
				}
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;
				this.logValidations();

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.page.errors.length < 1) {
						if (this.item.docType == 0) this.agreementReimbursementId = null;
						if (this.item.docType == 1) this.invoiceId = null;

						if (this.isUpdate) {
							this.page.saveConfirmShow = true;
						} else {
							try {
								const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
								this.log(response.data);

								this.$emit('change');
							} catch (error) {
								if (this.isApiUnauthorized(error)) this.page.showLogin = true;
								else this.page.errors = this.logError(error);
							}
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.id}`, data, this.getTokenHeader());
						this.log(response.data);

						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			logValidations() {
				this.log(this.$v);
			},
			cancel() {
				this.$emit('cancel');
			},
			async findInvoices(query) {
				try {
					const response = await this.$http.post(`/srworkorderinvoices/find-id-post`, { value: query }, this.getTokenHeader());
					this.options.invoice = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async findAgreementReimbursements(query) {
				try {
					const response = await this.$http.post(`/sragreementreimbursements/find-id-post`, { value: query }, this.getTokenHeader());
					this.options.agreementReimbursement = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
		}
	}
</script>
