<template>
	<div>
		<auth-container :page="page" v-if="$route.name === 'SrWorkOrderEdit'">
			<h2 class="mb-4">Work Order #{{item.workOrderNumber}}</h2>

			<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
				<b-tab title="Overview">
					<b-row>
						<b-col md>
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Work Order #</b-th>
										<b-td>{{valueOrNa(item.workOrderNumber)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Name</b-th>
										<b-td>{{valueOrNa(item.name)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Execution Date</b-th>
										<b-td>{{item.executionDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Contractor</b-th>
										<b-td>{{valueOrNa(item.contractor)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Primary POC</b-th>
										<b-td>{{valueOrNa(item.pocPrimary)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Secondary POC</b-th>
										<b-td>{{valueOrNa(item.pocSecondary)}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
						<b-col md>
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Funding Type</b-th>
										<b-td>{{valueOrNa(item.fundingType)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Funding Source</b-th>
										<b-td>
											<span v-if="isNullOrEmpty(item.fundingSourceId)">N/A</span>
											<router-link v-else :to="`/sr/fundingsources/${item.fundingSourceId}`">{{fundingSource.enteredIdentifier}} - {{fundingSource.name}}</router-link>
										</b-td>
									</b-tr>
									<b-tr>
										<b-th>Project Type</b-th>
										<b-td>{{valueOrNa(item.projectType)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Project Subtype</b-th>
										<b-td>{{valueOrNa(item.projectSubtype)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Completion Date</b-th>
										<b-td>{{item.completionDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Notes</b-th>
										<b-td>{{valueOrNa(item.notes, 'None')}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
					</b-row>					

					<fixed-action-bar>
						<b-button type="button" variant="primary" to="print" append target="_blank" class="mr-2">Print</b-button>
						<back-button />
						<b-button v-if="isAuthorized(roleNames.admin)" type="button" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="Revisions">
					<b-table striped responsive small show-empty :fields="revisions.fields" :items="revisions.items">
						<template #cell(edit)="data">
							<font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'edit']" class="pointer text-primary" @click="editRevision(data.item.id)" />
						</template>
						<template #cell(delete)="data">
							<font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'times']" class="pointer text-danger" @click="deleteRevision(data.item.id)" />
						</template>
						<template #empty>
							<p class="my-2">This work order does not have any revisions entered.</p>
						</template>
					</b-table>

					<fixed-action-bar>
						<b-button v-if="!isReadOnly" type="button" variant="success" @click="createRevision" class="mr-2">Create Revision</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="Dams">
					<b-table striped responsive small show-empty :fields="dams.fields" :items="dams.items">
						<template #cell(edit)="data">
							<font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'edit']" class="pointer text-primary" @click="editRevision(data.item.workOrderRevisionId)" title="Edit current revision" />
						</template>
						<template #cell(delete)="data">
							<font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'times']" class="pointer text-danger" @click="deleteDam(data.item.id)" />
						</template>
						<template #cell(projectNumber)="data">
							<router-link :to="`/sr/projects/${data.item.projectId}`">{{data.value}}</router-link>
						</template>
						<template #cell(damId)="data">
							<router-link :to="`/dams/${data.value}`">{{data.item.nid_id}}</router-link>
						</template>
						<template #empty>
							<p class="my-2">This work order does not have any dams entered.</p>
						</template>
					</b-table>

					<fixed-action-bar>
						<b-button v-if="!isReadOnly" type="button" variant="success" @click="createDam" class="mr-2">Add Dam</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="Invoices">
					<b-table striped responsive small show-empty :fields="invoices.fields" :items="invoices.items">
						<template #cell(edit)="data">
							<router-link :to="`/sr/workorders/invoices/${data.item.id}`">
								<font-awesome-icon :icon="['fas', 'edit']" />
							</router-link>
						</template>
						<template #cell(dam)="data">
							<router-link :to="`/dams/${data.value.id}`">{{data.value.name}}</router-link>
						</template>
						<template #empty>
							<p class="my-2">This work order does not have any invoices entered.</p>
						</template>

						<template #custom-foot>
							<b-tr class="bg-light border-bottom font-weight-bold">
								<b-td colspan="7">Total</b-td>
								<b-td class="text-right">${{ sum(invoices.items, 'labor') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(invoices.items, 'nonLabor') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(invoices.items, 'subconsultant') | number(2)}}</b-td>
								<b-td colspan="2"></b-td>
							</b-tr>
						</template>
					</b-table>

					<fixed-action-bar>
						<b-button v-if="!isReadOnly" type="button" variant="success" :to="`/sr/workorders/invoices/create?workOrderId=${itemId}`" class="mr-2">Create Invoice</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="Edit" v-if="!isReadOnly">
					<sr-work-order-form ref="itemForm" :item="item" :set-options="setOptions" is-update @change="changesSaved"></sr-work-order-form>
				</b-tab>
				<b-tab title="Files">
					<file-manager :apiUrl="apiUrl" :files="files" :itemId="itemId" itemLabel="application" @change="filesChanged"></file-manager>
				</b-tab>
				<b-tab title="Change Log">
					<log-viewer db-table="SrWorkOrders" :item-id="itemId"></log-viewer>
				</b-tab>
			</b-tabs>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete this work order?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.deleteDam.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.deleteDam.errors"></error-list>

				<p>
					Are you sure you want to remove this TPN from the work order?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.deleteDam.saving" @click.native="confirmDeleteDam" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.deleteDam.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="modalDam.show" title="Work Order TPN/Dam" scrollable size="lg" hide-footer>
				<error-list :errors="modalDam.errors"></error-list>

				<sr-work-order-dam-form :item="modalDam.item" :revisions="revisionsForForm" :is-update="modalDam.isUpdate" @change="modalDamSaved" @cancel="cancelModalDam"></sr-work-order-dam-form>
			</b-modal>

			<b-modal v-model="page.deleteRevision.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.deleteRevision.errors"></error-list>

				<p>
					Are you sure you want to remove this revision from the work order?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.deleteRevision.saving" @click.native="confirmDeleteRevision" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.deleteRevision.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="modalRevision.show" title="Work Order Revision" scrollable size="xl" hide-footer>
				<error-list :errors="modalRevision.errors"></error-list>

				<sr-work-order-revision-form :item="modalRevision.item" :dams="dams.items" :dam-revisions="modalRevision.damRevisions" :is-update="modalRevision.isUpdate" @change="modalRevisionSaved" @cancel="cancelModalRevision"></sr-work-order-revision-form>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import FileManager from '@/components/FileManager';
	import SrWorkOrderForm from './SrWorkOrderForm.vue';
	import SrWorkOrderDamForm from './SrWorkOrderDamForm.vue';
	import SrWorkOrderRevisionForm from './SrWorkOrderRevisionForm.vue';

	export default {
		name: 'SrWorkOrderEdit',
		components: {
			SrWorkOrderForm, SrWorkOrderDamForm, SrWorkOrderRevisionForm, FileManager
		},
		data() {
			return {
				apiUrl: 'srworkorders',
				listRoute: 'SrWorkOrderList',
				damApiUrl: 'srworkorderdams',
				revisionApiUrl: 'srworkorderrevisions',
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					tabIndex: 0,
					delete: {
						show: false,
						errors: [],
						saving: false
					},
					deleteDam: {
						show: false,
						errors: [],
						saving: false,
						id: null
					},
					deleteRevision: {
						show: false,
						errors: [],
						saving: false,
						id: null
					}
				},
				item: {},
				fundingSource: {},
				files: [],
				setOptions: {},
				revisionsForForm: [],
				dams: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'projectNumber', label: 'TPN', sortable: true },
						{ key: 'damId', label: 'NID_ID', sortable: true },
						{ key: 'revisionNumber', label: 'Current Revision', sortable: true },
						{ key: 'fundingAmount', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'revisionDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'notes', label: 'Notes', sortable: true },
						{ key: 'delete', label: '' }
					],
					items: []
				},
				revisions: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'revisionNumber', label: 'Revision #', sortable: true },
						{ key: 'revisionDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'expirationDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'delete', label: '' }
					],
					items: []
				},
				invoices: {
					fields: [
						{ key: 'edit', label: '', stickyColumn: true },
						{ key: 'invoiceNumber', label: 'Invoice #', sortable: true },
						{ key: 'projectNumber', label: 'TPN', sortable: true },
						{ key: 'damId', label: 'NID_ID', sortable: true },
						{ key: 'invoiceSubmitDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'serviceStartDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'serviceEndDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'labor', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'nonLabor', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'subconsultant', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'reviewer', sortable: true },
						{ key: 'approvalDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
					],
					items: []
				},
				modalDam: {
					item: {},
					revision: {},
					show: false,
					loading: false,
					errors: [],
					isUpdate: false
				},
				modalRevision: {
					item: {},
					damRevisions: [],
					show: false,
					loading: false,
					errors: [],
					isUpdate: false
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.log(response.data);
					this.item = response.data.workOrder;
					this.fundingSource = response.data.fundingSource;
					this.files = response.data.files;
					this.setOptions = response.data.setOptions;
					this.dams.items = response.data.dams;
					this.invoices.items = response.data.invoices;
					this.revisions.items = response.data.revisions;
					this.revisionsForForm = response.data.revisionsForForm;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async changesSaved() {
				await this.get();
				this.$refs.itemForm.launchToast();
				this.page.tabIndex = 4;
			},
			async filesChanged() {
				await this.get();
				this.page.tabIndex = 5;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$router.push({ name: this.listRoute });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			deleteDam(id) {
				this.page.deleteDam.id = id;
				this.page.deleteDam.show = true;
			},
			async confirmDeleteDam() {
				if (this.page.deleteDam.id !== null) {
					this.page.deleteDam.errors = [];
					this.page.deleteDam.saving = true;

					try {
						await this.$http.delete(`${this.damApiUrl}/${this.page.deleteDam.id}`, this.getTokenHeader());
						this.page.deleteDam.show = false;
						await this.get();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.deleteDam.errors = this.logError(error);
					}

					this.page.deleteDam.saving = false;
				}
			},
			async editDam(id) {
				await this.getModalDam(id);
				this.modalDam.show = true;
			},
			createDam() {
				this.resetModalDam();
				this.modalDam.show = true;
			},
			async modalDamSaved() {
				this.modalDam.show = false;
				this.resetModalDam();
				await this.get();
				this.$bvToast.show('subitem-saved');
			},
			cancelModalDam() {
				this.modalDam.show = false;
				this.resetModalDam();
			},
			resetModalDam() {
				this.modalDam.item = {
					workOrderId: Number(this.itemId),
					projectId: null,
					notes: null
				}
				this.modalDam.isUpdate = false;
			},
			async getModalDam(id) {
				this.modalDam.loading = true;
				this.modalDam.errors = [];

				try {
					const response = await this.$http.get(`${this.damApiUrl}/${id}`, this.getTokenHeader());
					this.log(response.data);
					this.modalDam.item = response.data.item;
					this.modalDam.isUpdate = true;
				} catch (error) {
					this.modalDam.errors = this.logError(error);
				}

				this.modalDam.loading = false;
			},

			deleteRevision(id) {
				this.page.deleteRevision.id = id;
				this.page.deleteRevision.show = true;
			},
			async confirmDeleteRevision() {
				if (this.page.deleteRevision.id !== null) {
					this.page.deleteRevision.errors = [];
					this.page.deleteRevision.saving = true;

					try {
						await this.$http.delete(`${this.revisionApiUrl}/${this.page.deleteRevision.id}`, this.getTokenHeader());
						this.page.deleteRevision.show = false;
						await this.get();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.deleteRevision.errors = this.logError(error);
					}

					this.page.deleteRevision.saving = false;
				}
			},
			async editRevision(id) {
				await this.getModalRevision(id);
				this.modalRevision.show = true;
			},
			createRevision() {
				this.resetModalRevision();
				if (this.revisions.items.length > 0) {
					this.modalRevision.item.revisionNumber = this.revisions.items[0].revisionNumber + 1;
				}
				this.modalRevision.show = true;
			},
			async modalRevisionSaved() {
				this.modalRevision.show = false;
				this.resetModalRevision();
				await this.get();
				this.$bvToast.show('subitem-saved');
			},
			cancelModalRevision() {
				this.modalRevision.show = false;
				this.resetModalRevision();
			},
			resetModalRevision() {
				this.modalRevision.item = {
					workOrderId: Number(this.itemId),
					revisionNumber: 0,
					revisionDate: null,
					expirationDate: null,
					notes: null,
				}
				this.modalRevision.isUpdate = false;
			},
			async getModalRevision(id) {
				this.modalRevision.loading = true;
				this.modalRevision.errors = [];

				try {
					const response = await this.$http.get(`${this.revisionApiUrl}/${id}`, this.getTokenHeader());
					this.log(response.data);
					this.modalRevision.item = response.data.item;
					this.modalRevision.damRevisions = response.data.damRevisions;
					this.modalRevision.isUpdate = true;
				} catch (error) {
					this.modalRevision.errors = this.logError(error);
				}

				this.modalRevision.loading = false;
			}
		},
		computed: {
			itemId() {
				return this.$route.params.id;
			},
		}
	}
</script>