<template>
	<div>
		<auth-container :page="page" v-if="$route.name === 'SrFundingSourceEdit'">
			<h2 class="mb-4">Funding Source {{item.enteredIdentifier}}</h2>

			<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
				<b-tab title="Overview">
					<b-row>
						<b-col md>
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Funding Source ID</b-th>
										<b-td>{{valueOrNa(item.enteredIdentifier)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Funding Source Name</b-th>
										<b-td>{{valueOrNa(item.name)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Funding Type</b-th>
										<b-td>{{valueOrNa(item.fundingType)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Funding Subtype</b-th>
										<b-td>{{valueOrNa(item.fundingSubType)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Obligation Date</b-th>
										<b-td>{{item.obligationDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Funding Amount</b-th>
										<b-td>{{money(item.fundingAmount)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>FY Funded</b-th>
										<b-td>{{valueOrNa(item.fiscalYearFunded)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Expiration Date</b-th>
										<b-td>{{item.expirationDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
					</b-row>

					<fixed-action-bar>
						<back-button />
						<b-button v-if="isAuthorized(roleNames.admin)" type="button" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="Edit" v-if="!isReadOnly">
					<sr-funding-source-form ref="itemForm" :item="item" :set-options="setOptions" is-update @change="changesSaved"></sr-funding-source-form>
				</b-tab>
				<b-tab title="Change Log">
					<log-viewer db-table="SrFundingSources" :item-id="itemId"></log-viewer>
				</b-tab>
			</b-tabs>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete this project?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import SrFundingSourceForm from './SrFundingSourceForm.vue';

	export default {
		name: 'SrFundingSourceEdit',
		components: {
			SrFundingSourceForm
		},
		data() {
			return {
				apiUrl: 'srfundingsources',
				listRoute: 'SrFundingSourceList',
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					tabIndex: 0,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				item: {},
				setOptions: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.log(response.data);
					this.item = response.data.item;
					this.setOptions = response.data.setOptions;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async changesSaved() {
				await this.get();
				this.$refs.itemForm.launchToast();
				this.page.tabIndex = 1;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$router.push({ name: this.listRoute });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			}
		},
		computed: {
			itemId() {
				return this.$route.params.id;
			},
		}
	}
</script>