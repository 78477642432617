<template>
	<div class="print-container">
		<auth-container :page="$parent.page">
			<print-menu></print-menu>

			<div class="d-flex mb-4">
				<h2 class="mr-2">Flood Control Structural Repair Program<br />Budget Plan for Application #{{$parent.application.appNum}}</h2>
				<div class="ml-auto text-right">
					<img :src="`${publicPath}images/logos/tsswcb-logo.svg`" alt="TSSWCB Logo" class="img-fluid" style="width:170px">
				</div>
			</div>

			<b-row>
				<b-col md>
					<b-table-simple small class="table-definitions">
						<b-tbody>
							<b-tr>
								<b-th>Dam</b-th>
								<b-td>
									<span v-if="isNullOrEmpty($parent.application.damId)">N/A</span>
									<span v-else>{{$parent.dam.name}}</span>
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>Funding Cycle</b-th>
								<b-td>{{valueOrNa($parent.item.fundCycle)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Date Budget Performed</b-th>
								<b-td>{{$parent.item.performedDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-col>
			</b-row>

			<b-table-simple small class="table-definitions" bordered>
				<b-tbody>
					<b-tr class="bg-light">
						<b-th></b-th>
						<b-th class="text-right">Design</b-th>
						<b-th class="text-right">Land Rights</b-th>
						<b-th class="text-right">Sponsor</b-th>
						<b-th class="text-right">Total</b-th>
					</b-tr>
					<b-tr>
						<b-th class="bg-light">Federal Reimbursement</b-th>
						<b-td class="text-right">{{money($parent.item.designFederalReimbursement)}}</b-td>
						<b-td class="text-right">{{money($parent.item.landRightsFederalReimbursement)}}</b-td>
						<b-td class="text-right">{{money($parent.item.constructionFederalReimbursement)}}</b-td>
						<b-td class="bg-light text-right">{{money($parent.planTotals.federalSubtotal)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th class="bg-light">State Cost</b-th>
						<b-td class="text-right">{{money($parent.item.designStateCost)}}</b-td>
						<b-td class="text-right">{{money($parent.item.landRightsStateCost)}}</b-td>
						<b-td class="text-right">{{money($parent.item.constructionStateCost)}}</b-td>
						<b-td class="bg-light text-right">{{money($parent.planTotals.stateSubtotal)}}</b-td>
					</b-tr>
					<b-tr>
						<b-th class="bg-light">Sponsor Cost</b-th>
						<b-td class="text-right">&mdash;</b-td>
						<b-td class="text-right">{{money($parent.item.landRightsSponsorsCost)}}</b-td>
						<b-td class="text-right">{{money($parent.item.constructionSponsorsCost)}}</b-td>
						<b-td class="bg-light text-right">{{money($parent.planTotals.sponsorsSubtotal)}}</b-td>
					</b-tr>
					<b-tr class="bg-light">
						<b-th>Total</b-th>
						<b-td class="text-right">{{money($parent.planTotals.designSubtotal)}}</b-td>
						<b-td class="text-right">{{money($parent.planTotals.landRightsSubtotal)}}</b-td>
						<b-td class="text-right">{{money($parent.planTotals.constructionSubtotal)}}</b-td>
						<b-td class="text-right font-weight-bold">{{money($parent.planTotals.totalCost)}}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</auth-container>
	</div>
</template>

<script>
	import PrintMenu from '@/components/helpers/PrintMenu';

	export default {
		name: 'SrPlanPrint',
		components: { PrintMenu }
	}
</script>