<template>
	<auth-container :page="page">
		<h2 class="mb-4">Create New Work Order</h2>

		<sr-work-order-form :item="item" :set-options="setOptions"></sr-work-order-form>
	</auth-container>
</template>

<script>
	import SrWorkOrderForm from './SrWorkOrderForm.vue';

	export default {
		name: 'SrWorkOrderCreate',
		components: {
			SrWorkOrderForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				item: {
					workOrderNumber: null,
					name: null,
					executionDate: null,
					contractor: null,
					pocPrimary: null,
					pocSecondary: null,
					fundingType: null,
					fundingSourceId: null,
					projectType: null,
					projectSubtype: null,
					completionDate: null,
					notes: null
				},
				setOptions: {
					fundingTypes: [],
					projectTypes: [],
					projectSubtypes: [],
					fundingSources: []
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`srworkorders/options`, this.getTokenHeader());
					this.setOptions = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		}
	}
</script>