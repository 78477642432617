<template>
	<auth-container :page="page">
		<h2 class="mb-4">Create New Field Representative</h2>

		<sr-field-rep-form :item="item"></sr-field-rep-form>
	</auth-container>
</template>

<script>
	import SrFieldRepForm from './SrFieldRepForm.vue';
	export default {
		name: 'SrFieldRepCreate',
		components: {
			SrFieldRepForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				item: {
					firstName: null,
					lastName: null,
				}
			}
		}
	}
</script>