<template>
	<div>
		<auth-container v-if="$route.name === 'SrAgreementList'" :page="page">
			<h2 class="mb-4">{{agreementType}} Agreements</h2>

			<div class="mx-auto">
				<b-row class="mb-3" align-v="end">
					<b-col lg="6">
						<b-form inline>
							<b-input-group>
								<b-form-input v-model="table.filter" placeholder="Search..." @input="filterChg"></b-form-input>

								<b-input-group-append class="pointer mr-2">
									<b-input-group-text @click="clearFilter">
										<font-awesome-icon :icon="['fas', 'times']" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
							<b-button variant="primary" type="button" @click="table.showAdvancedFilter = !table.showAdvancedFilter">Advanced Filter</b-button>
						</b-form>
					</b-col>
					<b-col lg="6" class="text-right">
						<div class="ml-auto">
							Showing {{showingFirst}} - {{showingLast}}
							of {{table.total}}
						</div>
					</b-col>
				</b-row>

				<b-collapse v-model="table.showAdvancedFilter" class="my-3">
					<b-card bg-variant="light">
						<b-row>
							<b-col lg="4">
								<div class="form-group row">
									<label for="advancedFilter.agreementNumber" class="col-lg-5 col-form-label">Agreement #</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.agreementNumber"
													 v-model="advancedFilter.agreementNumber"
													 :data="advancedFilter.options.agreementNumber"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.dam" class="col-lg-5 col-form-label">Dam NID_ID</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.dam"
													 v-model="advancedFilter.dam"
													 :data="advancedFilter.options.dam"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.projectNumber" class="col-lg-5 col-form-label">TPN</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.projectNumber"
													 v-model="advancedFilter.projectNumber"
													 :data="advancedFilter.options.projectNumber"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.offeror" class="col-lg-5 col-form-label">Offeror</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.offeror"
													 v-model="advancedFilter.offeror"
													 :data="setOptions.offerors"
													 :minMatchingChars="1" showAllResults showOnFocus>
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.offeree" class="col-lg-5 col-form-label">Offeree</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.offeree"
													 v-model="advancedFilter.offeree"
													 :data="setOptions.offerees"
													 :minMatchingChars="1" showAllResults showOnFocus>
										</b-typeahead>
									</div>
								</div>
							</b-col>
							<b-col lg>
								<div class="form-group row">
									<label for="advancedFilter.fundingSource" class="col-lg-3 col-form-label">Funding Source</label>
									<div class="col-lg-9">
										<b-typeahead id="advancedFilter.fundingSource" style="max-width:260px"
													 v-model="advancedFilter.fundingSource"
													 :data="advancedFilter.options.fundingSource"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.status" class="col-lg-3 col-form-label">Status</label>
									<div class="col-lg-9">
										<b-typeahead id="advancedFilter.status" style="max-width:260px"
													 v-model="advancedFilter.status"
													 :data="setOptions.statuses"
													 :minMatchingChars="1" showAllResults showOnFocus>
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.executionDate.start" class="col-lg-3 col-form-label">Execution Date</label>
									<div class="col-lg-9">
										<div class="d-flex align-items-center justify-content-start">
											<div class="mr-2">
												<b-input-group>
													<b-form-datepicker id="advancedFilter.executionDate.start" v-model="advancedFilter.executionDate.start"
																	   label-no-date-selected="Start Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter.executionDate.start = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
											<div>
												<b-input-group>
													<b-form-datepicker id="advancedFilter.executionDate.end" v-model="advancedFilter.executionDate.end"
																	   label-no-date-selected="End Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter.executionDate.end = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
										</div>
									</div>
								</div>
							</b-col>
						</b-row>
						<hr class="my-3" />
						<div>
							<b-button variant="primary" type="button" @click="get" class="mr-2">Apply</b-button>
							<b-button variant="secondary" type="button" @click="clearAdvancedFilter" class="mr-2">Clear</b-button>
							<b-button variant="secondary" type="button" @click="table.showAdvancedFilter = false">Close</b-button>
						</div>
					</b-card>
				</b-collapse>

				<b-table striped responsive table-class="table-fixed-height border-bottom table-grid" sticky-header="60vh"
						 small show-empty
						 :items="items" :busy="table.loading"
						 :fields="table.fields"
						 :no-local-sorting="true"
						 :sort-by.sync="table.sort"
						 :sort-desc.sync="table.reverse"
						 @sort-changed="sortChg">
					<template #table-busy>
						<div class="text-center my-4">
							<font-awesome-icon :icon="['fas', 'spinner']" spin size="2x" />
						</div>
					</template>
					<template #cell(edit)="data">
						<router-link :to="`${data.item.id}`" append>
							<font-awesome-icon :icon="['fas', 'edit']" />
						</router-link>						
					</template>
					<template #cell(projectNumber)="data">
						<router-link :to="`/sr/projects/${data.item.projectId}`">{{data.value}}</router-link>
					</template>
					<template #cell(damId)="data">
						<router-link :to="`/dams/${data.value}`">{{data.item.nid_id}}</router-link>
					</template>
					<template #cell(fundingSourceId)="data">
						<router-link :to="`/sr/fundingsources/${data.value}`">{{data.item.fundingSource}}</router-link>
					</template>
					<template #empty>
						<h4>No records found.</h4>
					</template>
					<template #emptyfiltered>
						<h4>No records found matching "{{table.filter}}".</h4>
					</template>
				</b-table>
			</div>

			<fixed-action-bar>
				<router-link v-if="!isReadOnly" to="create" append class="btn btn-success mr-2">
					Create
				</router-link>
				<b-dropdown variant="primary" text="Download CSVs" dropup class="mr-2">
					<b-dropdown-text><json-to-csv :api-url="`${apiUrl}/export/${$route.params.atype}`" :filename="apiUrl" variant="link" text="Download Agreements" /></b-dropdown-text>
					<b-dropdown-text><json-to-csv :api-url="`sragreementamendments/export`" filename="sragreementamendments" variant="link" text="Download Agreement Amendments" /></b-dropdown-text>
					<b-dropdown-text><json-to-csv :api-url="`sragreementreimbursements/export`" filename="sragreementreimbursements" variant="link" text="Download Agreement Reimbursements" /></b-dropdown-text>
				</b-dropdown>
				<b-dropdown v-if="isAuthorized(roleNames.superAdmin)" variant="primary" text="Import" dropup class="mr-2">
					<b-dropdown-text><b-button variant="link" @click="page.showModal = true" class="mr-2" style="white-space: nowrap;">Import Agreements</b-button></b-dropdown-text>
					<b-dropdown-text><b-button variant="link" @click="page.showModal1 = true" class="mr-2" style="white-space: nowrap;">Import Agreement Amendments</b-button></b-dropdown-text>
					<b-dropdown-text><b-button variant="link" @click="page.showModal2 = true" class="mr-2" style="white-space: nowrap;">Import Agreement Reimbursements</b-button></b-dropdown-text>
				</b-dropdown>
				
				<b-pagination class="ml-auto mb-0" v-if="table.total > table.itemsPerPage" size="md" :total-rows="table.total" v-model="table.currentPage" :per-page="table.itemsPerPage" @change="pageChg" />
			</fixed-action-bar>

			<b-modal v-model="page.showModal" title="Upload Agreements" scrollable size="lg">
				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<b-alert variant="info" show>
					Note: because agreement numbers are not unique in the database, make sure you're not including rows in your CSV that already exist in the database.
					Otherwise they will be duplicated.
				</b-alert>

				<vue-dropzone ref="addFile" id="addFile" :options="dropzoneOptions" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>

				<div class="mt-3">
					<strong>IMPORTANT:</strong>
				</div>
				<ul>
					<li>Use the Download Template button to get the required columns.</li>
					<li>Column headers **must match** the template.</li>
					<li>Duplicate agreement numbers are allowed and will not be overwritten -- they will be added as new entries even if the agreement number already exists in the table.</li>
					<li>If you include tpn and funding source, the IDs must already exist in the corresponding database tables.</li>
					<li>Include the data for a single amendment. You can upload other amendments after the entry is created.</li>
				</ul>

				<div slot="modal-footer">
					<json-to-csv :json-data="template" :filename="`${apiUrl}-template`" text="Download Template" class="mr-2" />
					<b-button type="button" variant="primary" @click="page.showModal = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.showModal1" title="Upload Agreement Amendments" scrollable size="lg">
				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<b-alert variant="warning" show>
					WARNING: in order to accommodate duplicate agreement numbers, you need to include the system generated ID# for agreements in your CSV file.
					Use the Download CSVs -> Download Agreements button to get the IDs.
				</b-alert>

				<vue-dropzone ref="addFile1" id="addFile1" :options="dropzoneOptions1" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>

				<div class="mt-3">
					<strong>IMPORTANT:</strong>
				</div>
				<ul>
					<li>Use the Download Template button to get the required columns.</li>
					<li>Column headers **must match** the template.</li>
					<li>Agreement numbers must already exist in the database.</li>
					<li>Include the system generated ID in the agreementId column. Export the main agreements table to get this data.</li>
					<li>This upload form does not check for duplicate amendments.</li>
				</ul>

				<div slot="modal-footer">
					<json-to-csv :json-data="amendmentsTemplate" :filename="`sragreementamendments-template`" text="Download Template" class="mr-2" />
					<b-button type="button" variant="primary" @click="page.showModal1 = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.showModal2" title="Upload Agreement Reimbursements" scrollable size="lg">
				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<b-alert variant="warning" show>
					WARNING: in order to accommodate duplicate agreement numbers, you need to include the system generated ID# for agreements in your CSV file.
					Use the Download CSVs -> Download Agreements button to get the IDs.
				</b-alert>

				<vue-dropzone ref="addFile2" id="addFile2" :options="dropzoneOptions2" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>

				<div class="mt-3">
					<strong>IMPORTANT:</strong>
				</div>
				<ul>
					<li>Use the Download Template button to get the required columns.</li>
					<li>Column headers **must match** the template.</li>
					<li>Agreement numbers must already exist in the database.</li>
					<li>Include the system generated ID in the agreementId column. Export the main agreements table to get this data.</li>
					<li>This upload form does not check for duplicate reimbursements.</li>
				</ul>

				<div slot="modal-footer">
					<json-to-csv :json-data="reimbursementsTemplate" :filename="`sragreementreimbursements-template`" text="Download Template" class="mr-2" />
					<b-button type="button" variant="primary" @click="page.showModal2 = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
	import _ from 'underscore';

	export default {
		name: 'SrAgreementList',
		components: {
			vueDropzone: vue2Dropzone
		},
		data() {
			return {
				apiUrl: `sragreements`,
				page: {
					errors: [],
					loading: true,
					showLogin: false,
					saveErrors: [],
					showModal: false,
					showModal1: false,
					showModal2: false
				},
				dropzoneOptions: {
					url: `/api/sragreements/upload/${this.$route.params.atype}`,
					headers: this.getTokenHeader().headers,
					acceptedFiles: '.csv'
				},
				dropzoneOptions1: {
					url: `/api/sragreementamendments/upload`,
					headers: this.getTokenHeader().headers,
					acceptedFiles: '.csv'
				},
				dropzoneOptions2: {
					url: `/api/sragreementreimbursements/upload`,
					headers: this.getTokenHeader().headers,
					acceptedFiles: '.csv'
				},
				table: {
					fields: [
						{ key: 'edit', label: '', stickyColumn: true },
						{ key: 'agreementNumber', label: 'Agreement #', sortable: true },
						{ key: 'projectNumber', label: 'TPN', sortable: true },
						{ key: 'damId', label: 'NID_ID', sortable: true },
						{ key: 'fundingSourceId', label: 'Funding Source', sortable: true },
						{ key: 'offeror', sortable: true },
						{ key: 'offeree', sortable: true },
						{ key: 'status', sortable: true },
						{ key: 'executionDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
					],
					sort: 'agreementNumber',
					reverse: false,
					itemsPerPage: 100,
					filter: null,
					currentPage: 1,
					total: 0,
					loading: false,
					showAdvancedFilter: false
				},
				items: [],
				advancedFilter: {
					dam: null,
					projectNumber: null,
					fundingSource: null,
					offeror: null,
					offeree: null,
					executionDate: {
						start: null,
						end: null
					},
					status: null,
					options: {
						dam: [],
						projectNumber: [],
						fundingSource: [],
						agreementNumber: [],
					}
				},
				setOptions: {
					offerors: [],
					offerees: [],
					statuses: []
				},
				template: [{
					agreementNumber: null,
					tpn: null,
					fundingSource: null,
					offeror: null,
					offeree: null,
					status: null,
					executionDate: null,
					revisionNumber: null,
					amendmentDate: null,
					notes: null,
					expirationDate: null,
					constructionPercent: null,
					constructionAmount: null,
					profServicesPercent: null,
					profServicesAmount: null,
					adminPercent: null,
					adminAmount: null,
					landRightsPercent: null,
					landRightsAmount: null,
					nonCostShareAmount: null,
					revisionDate: null,
				}],
				amendmentsTemplate: [{
					agreementId: null,
					agreementNumber: null,
					revisionNumber: null,
					amendmentDate: null,
					notes: null,
					expirationDate: null,
					constructionPercent: null,
					constructionAmount: null,
					profServicesPercent: null,
					profServicesAmount: null,
					adminPercent: null,
					adminAmount: null,
					landRightsPercent: null,
					landRightsAmount: null,
					nonCostShareAmount: null,
					revisionDate: null
				}],
				reimbursementsTemplate: [{
					agreementId: null,
					agreementNumber: null,
					invoiceNumber: null,
					receivedDate: null,
					wasStateRetainageRequested: null,
					stateConstructionCost: null,
					stateLandRights: null,
					wasAdminRequestedInAdvance: null,
					stateAdminCost: null,
					federalConstructionCost: null,
					federalLandRights: null,
					sponsorConstructionCost: null,
					approvedDate: null,
					paidDate: null
				}]
			}
		},
		computed: {
			agreementType() {
				return this.agreementTypeToName(this.$route.params.atype);
			},
			showingFirst() {
				return this.table.total === 0 ? 0 : (this.table.currentPage - 1) * this.table.itemsPerPage + 1
			},
			showingLast() {
				var max = (this.table.currentPage - 1) * this.table.itemsPerPage + this.table.itemsPerPage
				return max > this.table.total ? this.table.total : max;
			},
			advancedFilterAgreementNumber() {
				return this.advancedFilter.agreementNumber;
			},
			advancedFilterDam() {
				return this.advancedFilter.dam;
			},
			advancedFilterProjectNumber() {
				return this.advancedFilter.projectNumber;
			},
			advancedFilterFundingSource() {
				return this.advancedFilter.fundingSource;
			},
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get',
			advancedFilterAgreementNumber: _.debounce(function (query) { this.find('agreementNumber', query) }, 500),
			advancedFilterDam: _.debounce(function (query) { this.findDams('nid_id', query) }, 500),
			advancedFilterProjectNumber: _.debounce(function (query) { this.find('projectNumber', query) }, 500),
			advancedFilterFundingSource: _.debounce(function (query) { this.find('fundingSource', query) }, 500)
		},
		methods: {
			getDb() {
				let sort = this.table.reverse ? this.table.sort + '_desc' : this.table.sort;
				let filter = this.table.filter !== null ? `&filter=${encodeURIComponent(this.table.filter)}` : '';

				let filterComplex = '';
				let filterValues = [];
				if (!this.isNullOrEmpty(this.advancedFilter.dam)) {
					filterValues.push(`dam:${this.advancedFilter.dam}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.projectNumber)) {
					filterValues.push(`projectNumber:${this.advancedFilter.projectNumber}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.fundingSource)) {
					filterValues.push(`statePo:${this.advancedFilter.fundingSource}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.offeror)) {
					filterValues.push(`offeror:${this.advancedFilter.offeror}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.offeree)) {
					filterValues.push(`offeree:${this.advancedFilter.offeree}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.agreementNumber)) {
					filterValues.push(`agreementNumber:${this.advancedFilter.agreementNumber}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.status)) {
					filterValues.push(`status:${this.advancedFilter.status}`);
				}
				if (!this.isNullOrEmpty(this.advancedFilter.executionDate.start) || !this.isNullOrEmpty(this.advancedFilter.executionDate.end)) {
					let s = this.advancedFilter.executionDate.start;
					let e = this.advancedFilter.executionDate.end;
					s = s === null ? 'any' : s;
					e = e === null ? 'any' : e;
					filterValues.push(`executionDate:${s},${e}`);
				}

				if (filterValues.length > 0) filterComplex = '&filterComplex=' + filterValues.join('|');

				return this.$http.get(`${this.apiUrl}/list/${this.$route.params.atype}?sort=${sort}&page=${this.table.currentPage}&perPage=${this.table.itemsPerPage}${filter}${filterComplex}`, this.getTokenHeader());
			},
			async filterChg() {
				this.table.currentPage = 1;
				_.debounce(await this.get(), 500);
			},
			async pageChg(page) {
				this.table.currentPage = page;
				await this.get();
			},
			async sortChg(ctx) {
				this.table.sort = ctx.sortBy;
				this.table.reverse = ctx.sortDesc;
				this.table.specialSort = undefined;

				await this.get();
			},
			async get() {
				this.page.errors = [];
				this.table.loading = true;

				this.dropzoneOptions.url = `/api/sragreements/upload/${this.$route.params.atype}`;

				try {
					const response1 = await this.$http.get(`${this.apiUrl}/options`, this.getTokenHeader());
					this.setOptions = response1.data;

					const response = await this.getDb();
					this.log(response.data);

					if (response.data.items.length == 0 && this.table.currentPage > 1) {
						this.table.currentPage--;
						await this.get();
					}

					this.items = response.data.items;
					this.table.total = response.data.total;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.table.loading = false;
				this.page.loading = false;
			},
			async fileUploaded(file, response) {
				this.log(file);
				this.log(response);
				this.page.saveErrors = [];
				await this.get();
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.page.saveErrors.push(message);
			},
			async find(field, query) {
				try {
					const response = await this.$http.get(`/${this.apiUrl}/find/${field}/${query}`, this.getTokenHeader());
					this.advancedFilter.options[field] = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async findDams(field, query) {
				try {
					const response = await this.$http.get(`/dams/find/${field}/${query}`, this.getTokenHeader());
					this.advancedFilter.options.dam = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async clearFilter() {
				this.table.filter = null;
				await this.get();
			},
			async clearAdvancedFilter() {
				this.advancedFilter = {
					dam: null,
					projectNumber: null,
					fundingSource: null,
					offeror: null,
					offeree: null,
					executionDate: {
						start: null,
						end: null
					},
					status: null,
					options: {
						dam: [],
						projectNumber: [],
						fundingSource: [],
						agreementNumber: [],
					}
				};

				await this.get();
			}
		}
	}
</script>