import Vue from 'vue';
import { mapGetters } from 'vuex';
import moment from 'moment';

Vue.mixin({
	data() {
		return {
			publicPath: process.env.BASE_URL,
			roleNames: {
				pending: 'Pending',
				user: 'User',
				admin: 'Admin',
				superAdmin: 'SuperAdmin',
				readOnly: 'ReadOnly'
			},
			mapBase: {
				zoom: 6,
				center: [31.224, -98.897],
				tileProviders: [
					{
						name: 'Topography map',
						visible: true,
						url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
						attribution: 'Sources: Esri'
					},
					{
						name: 'Street map',
						visible: false,
						url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
						attribution: 'Sources: Esri'
					},
					{
						name: 'Satellite map',
						visible: false,
						url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
						attribution: 'Sources: Esri'
					}
				]
			},
			defaultDatePickerFormatDisplay: { 'year': 'numeric', 'month': 'short', 'day': 'numeric', 'weekday': 'short' }
		}
	},
	watch: {
		'$route'() {
			var storedToken = localStorage.getItem('auth_token');
			if (!storedToken || (storedToken !== this.token)) this.$store.commit('logout');
		}
	},
	methods: {
		getTokenHeader() {
			return {
				headers: { 'Authorization': 'Bearer ' + localStorage.getItem('auth_token') }
			};
		},
		getTokenHeaderMultiPart() {
			return {
				headers: { 'Authorization': 'Bearer ' + localStorage.getItem('auth_token'), 'Content-Type': 'multipart/form-data' }
			};
		},
		isAuthorized(role) {
			console.log('auth check');
			return this.isAuthenticated && this.user.emailConfirmed && this.roles.includes(role);
		},
		logout(redirect = true) {
			localStorage.removeItem('auth_token');
			this.$store.commit('logout');
			if (redirect)
				this.$router.push('/').catch(err => { this.log(err) });
		},
		isApiUnauthorized(error) {
			return error.response && (error.response.status === 401 || error.response.status === 403);
		},
		isApiNotFound(error) {
			return error.response && error.response.status === 404;
		},
		isDevMode() {
			return process.env.NODE_ENV === 'development';
		},
		isNullOrEmpty(value) {
			return value === undefined || value === null || value === '';
		},
		capitalizeFirstLette(s) {
			return s.charAt(0).toUpperCase() + s.slice(1);
		},
		lowercaseFirstLetter(s) {
			return s.charAt(0).toLowerCase() + s.slice(1);
		},
		lowercase(s) {
			if (this.isNullOrEmpty(s)) return null;
			return s.toLowerCase();
		},
		toValidFileName(s) {
			if (this.isNullOrEmpty(s)) return s;
			let val = s.replace(/[^A-Za-z0-9_\- ]/g, '');
			val = val.replace(/ /g, '-');
			val = val.replace(/---/g, '-');
			return val.toLowerCase();
		},
		numberWithCommas(x) {
			var parts = x.toString().split(".");
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			return parts.join(".");
		},
		numberFixed(num, decimals) {
			if (this.isNullOrEmpty(num)) return num;
			return this.round(Number(num), decimals);
		},
		numberFormat(value, decimals = 1, units = '') {
			return this.numberWithCommas(this.round(Number(value), decimals)) + units;
		},
		round(num, precision) {
			let base = 10 ** precision;
			return (Math.round(num * base) / base).toFixed(precision);
		},
		roundAsNumber(num, precision) {
			return Number(this.round(num, precision));
		},
		money(value, naText = '') {
			if (this.isNullOrEmpty(value)) return naText;
			return '$' + this.numberFormat(value, 2);
		},
		boolToText(val, trueVal = 'Yes', falseVal = 'No', nullVal = '') {
			if (this.isNullOrEmpty(val)) return nullVal;
			return val ? trueVal : falseVal;
		},
		lower(s) {
			if (this.isNullOrEmpty(s)) return s;
			return s.toLowerCase();
		},
		toDate(value, format = 'llll') {
			if (value) {
				return moment(String(value)).format(format);
			}
			return '';
		},
		roundNumber(num) {
			return Math.round((num + Number.EPSILON) * 100) / 100;
		},
		sum(array, prop) {
			let total = 0;
			for (let obj of array) {
				total += Number(obj[prop]);
			}
			return total;
		},
		log(message) {
			if (this.isDevMode()) {
				console.log(message);
			}
		},
		logError(error, defaultMessage = undefined) {
			var messages = [];
			if (defaultMessage) {
				messages.push(defaultMessage);
			}

			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.log(error.response.data);
				//console.log(error.response.status);
				//console.log(error.response.headers);

				if (error.response.status === 401 || error.response.status === 403) {
					messages.push('Invalid credentials. Either your user name or password is incorrect, or you do not have permission to view this page.');
				} else if (error.response.status === 404) {
					messages.push('Requested data not found. Please check the URL in your browser and make sure you have the correct ID numbers if any.');
				} else if (error.response.data && error.response.data.errors) {
					var errors = error.response.data.errors;
					for (var key in errors) {
						for (var j = 0; j < errors[key].length; j++) {
							messages.push(errors[key][j]);
						}
					}
				} else if (error.response.data) {
					if (typeof error.response.data === 'string' || error.response.data instanceof String)
						messages.push(error.response.data);
					else
						messages.push('There was an error processing your request.');
				}

				if (error.response.data.stackTrace) {
					console.log(error.response.data.stackTrace);
				}
			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				console.log(error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error', error.message);
			}
			//console.log(error.config);

			return messages;
		},
		errorContainsKey(error, key) {
			return error.response && error.response.data && error.response.data.errors && Object.prototype.hasOwnProperty.call(error.response.data.errors, key); //error.response.data.errors.hasOwnProperty(key);
		},
		valueOrNa(value, naText = 'N/A') {
			return this.isNullOrEmpty(value) ? naText : value;
		},
		currentFiscalYear() {
			let date = moment();
			return date.month() > 8 ? date.year() + 1 : date.year();
		},
		dateForForm(value) {
			if (this.isNullOrEmpty(value)) return null;
			return moment(value).format('YYYY-MM-DD');
		},
		dateTimeForForm(value) {
			if (this.isNullOrEmpty(value)) return null;
			return moment(value).format('YYYY-MM-DDTHH:mm');
		},
		getMapLayerColor(i) {
			let colors = [
				'#0ca08c',
				'#e00236',
				'#9406ad',
				'#0062ad',
				'#c18100'
			];
			let color = colors[0];
			if (i < colors.length) {
				color = colors[i];
			}

			return color;
		},
		agreementTypeToName(type) {
			switch(type) {
				case "st_slo":
					return "State/SLO";
				case "fed":
					return "Federal";
				default: 
					return "";
			}
		},
		agreementTypeToNum(type) {
			switch(type) {
				case "st_slo":
					return 0;
				case "fed":
					return 1;
				default: 
					return 0;
			}
		},
		agreementNumToType(type) {
			switch(type) {
				case 0:
					return "st_slo";
				case 1:
					return "fed";
				default: 
					return "";
			}
		},
		agreementNumToName(type) {
			return this.agreementTypeToName(this.agreementNumToType(type));
		}
	},
	computed: {
		...mapGetters(['isAuthenticated', 'user', 'roles', 'token']),
		localStorageToken() {
			return localStorage.getItem('auth_token');
		},
		getValidState() {
			return (prop) => {
				return prop.$dirty ? !prop.$error : null;
			}
		},
		requiredFeedback() {
			return (prop) => {
				if (!prop.required) return 'Required';
			}
		},
		isReadOnly() {
			console.log('readonly check');
			return this.roles.includes(this.roleNames.readOnly);
		}
	}
})