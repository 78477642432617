<template>
	<auth-container :page="page">
		<h2 class="mb-4">Create New Funding Source</h2>

		<sr-funding-source-form :item="item" :set-options="setOptions"></sr-funding-source-form>
	</auth-container>
</template>

<script>
	import SrFundingSourceForm from './SrFundingSourceForm.vue';
	export default {
		name: 'SrFundingSourceCreate',
		components: {
			SrFundingSourceForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				item: {
					enteredIdentifier: null,
					name: null,
					fundingType: null,
					fundingSubType: null,
					obligationDate: null,
					fundingAmount: 0,
					fiscalYearFunded: this.currentFiscalYear(),
					expirationDate: null
				},
				setOptions: {
					fundingTypes: [],
					fundingSubTypes: [],
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`srfundingsources/options`, this.getTokenHeader());
					this.setOptions = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		}
	}
</script>