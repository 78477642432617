<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
			Your changes have been saved.
		</b-toast>

		<b-form @submit.prevent="save">
			<b-row>
				<b-col md>
					<b-form-group label="Application #" :invalid-feedback="requiredFeedback($v.item.appNum)">
						<b-form-input type="text" required 
									  v-model.trim="$v.item.appNum.$model" :state="getValidState($v.item.appNum)" />
					</b-form-group>

					<b-form-group label="Date Received" :invalid-feedback="requiredFeedback($v.item.receivedDate)">
						<b-form-input type="date" v-model.trim="$v.item.receivedDate.$model" :state="getValidState($v.item.receivedDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Dam" :invalid-feedback="requiredFeedback($v.query.dam)">
						<b-typeahead v-model.trim="$v.query.dam.$model" required
									 :data="options.dams"
									 :serializer="s => s.name" @hit="item.damId = $event.id"
									 :max-matches="100" :minMatchingChars="1">
							<template slot="append">
								<b-input-group-text @click="query.dam = ''" class="pointer">
									<font-awesome-icon :icon="['fas', 'times']" />
								</b-input-group-text>
							</template>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Funding Cycle" :invalid-feedback="requiredFeedback($v.item.fundCycle)">
						<b-typeahead v-model.trim="$v.item.fundCycle.$model"
									 :data="options.fundCycle"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Request Type" :invalid-feedback="requiredFeedback($v.item.requestType)">
						<b-typeahead v-model.trim="$v.item.requestType.$model"
									 :data="setOptions.requestTypes"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="SLO Agent" :invalid-feedback="requiredFeedback($v.item.sloAgent)">
						<b-typeahead v-model.trim="$v.item.sloAgent.$model"
									 :data="options.sloAgent"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Primary Name" :invalid-feedback="requiredFeedback($v.item.primaryName)">
						<b-typeahead v-model.trim="$v.item.primaryName.$model"
									 :data="options.primaryName"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Alternate Name" :invalid-feedback="requiredFeedback($v.item.alternateName)">
						<b-typeahead v-model.trim="$v.item.alternateName.$model"
									 :data="options.alternateName"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>
				</b-col>
			</b-row>

			<b-row>
				<b-col md>
					<b-form-group label="Score" :invalid-feedback="requiredFeedback($v.item.score)">
						<b-form-input type="number" v-model.trim="$v.item.score.$model" :state="getValidState($v.item.score)" />
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Score Date" :invalid-feedback="requiredFeedback($v.item.scoreDate)">
						<b-form-input type="date" v-model.trim="$v.item.scoreDate.$model" :state="getValidState($v.item.scoreDate)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-row>
				<b-col md>
					<b-form-group label="Estimated Federal Cost Share" :invalid-feedback="requiredFeedback($v.item.federalCostShare)">
						<b-input-group prepend="$">
							<b-form-input type="number" required step="any"
										  v-model.trim="$v.item.federalCostShare.$model" :state="getValidState($v.item.federalCostShare)" />
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Estimated State Cost Share" :invalid-feedback="requiredFeedback($v.item.stateCostShare)">
						<b-input-group prepend="$">
							<b-form-input type="number" required step="any"
										  v-model.trim="$v.item.stateCostShare.$model" :state="getValidState($v.item.stateCostShare)" />
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Estimated Sponsors/Applicants Cost Share" :invalid-feedback="requiredFeedback($v.item.sloCostShare)">
						<b-input-group prepend="$">
							<b-form-input type="number" required step="any"
										  v-model.trim="$v.item.sloCostShare.$model" :state="getValidState($v.item.sloCostShare)" />
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group label="Notes" :invalid-feedback="requiredFeedback($v.item.notes)">
				<b-form-textarea v-model.trim="$v.item.notes.$model" :state="getValidState($v.item.notes)" rows="5"></b-form-textarea>
			</b-form-group>

			<fixed-action-bar>
				<save-button :saving="page.saving" class="mr-2" :disabled="!$v.$anyDirty" />
				<back-button />
				<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
					You have errors in the form.
				</div>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.saveConfirmShow" title="Confirm Save" scrollable size="lg">
			<error-list :errors="page.errors"></error-list>

			<b-form-group label="Add a note to describe changes?" description="Optional">
				<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
			</b-form-group>

			<div slot="modal-footer">
				<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
				<b-button type="button" variant="primary" @click="page.saveConfirmShow = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';
	import _ from 'underscore';

	export default {
		name: 'SrApplicationForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			dam: {
				type: Object,
				required: false
			},
			setOptions: {
				type: Object,
				default: () => {
					return {
						requestTypes: [],
					}
				}
			}
		},
		data() {
			return {
				apiUrl: 'srapplications',
				editRoute: 'SrApplicationEdit',
				page: {
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				},
				options: {
					dams: [],
					fundCycle: [],
					sloAgent: [],
					primaryName: [],
					alternateName: []
				},
				query: {
					dam: ''
				}
			}
		},
		computed: {
			queryDam() {
				return this.query.dam;
			},
			itemFundCycle() {
				return this.item.fundCycle;
			},
			itemsloAgent() {
				return this.item.sloAgent;
			},
			itemPrimaryName() {
				return this.item.primaryName;
			},
			itemAlternateName() {
				return this.item.alternateName;
			}
		},
		validations: {
			item: {
				appNum: { required },
				receivedDate: {},
				damId: {},
				fundCycle: {},
				sloAgent: {},
				primaryName: {},
				alternateName: {},
				requestType: {},
				notes: {},
				scoreDate: {},
				score: {},
				federalCostShare: {},
				stateCostShare: {},
				sloCostShare: {}
			},
			query: {
				dam: { required }
			}
		},
		watch: {
			queryDam: _.debounce(function (query) { this.findDams(query) }, 500),
			itemFundCycle: _.debounce(function (query) { this.find('fundCycle', query) }, 500),
			itemsloAgent: _.debounce(function (query) { this.find('sloAgent', query) }, 500),
			itemPrimaryName: _.debounce(function (query) { this.find('primaryName', query) }, 500),
			itemAlternateName: _.debounce(function (query) { this.find('alternateName', query) }, 500)
		},
		created() {
			this.get();
		},
		methods: {
			get() {
				if (!this.isNullOrEmpty(this.dam)) this.query.dam = this.dam.name;

				if (this.isUpdate) {
					this.item.receivedDate = this.dateForForm(this.item.receivedDate);
					this.item.scoreDate = this.dateForForm(this.item.scoreDate);
				}
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.isUpdate) {
						this.page.saveConfirmShow = true;
					} else {
						try {
							const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
							this.log(response.data);

							this.$router.push({ name: this.editRoute, params: { id: response.data } });
						} catch (error) {
							if (this.isApiUnauthorized(error)) this.page.showLogin = true;
							else this.page.errors = this.logError(error);
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.id}`, data, this.getTokenHeader());
						this.log(response.data);

						this.page.saveConfirmShow = false;
						this.$bvToast.show('changes-saved');
						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			launchToast() {
				this.$bvToast.show('changes-saved');
			},
			logValidations() {
				this.log(this.$v);
			},
			async findDams(query) {
				try {
					const response = await this.$http.get(`/dams/find-id/${encodeURIComponent(query)}`, this.getTokenHeader());
					this.options.dams = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async find(field, query) {
				try {
					const response = await this.$http.get(`/${this.apiUrl}/find/${field}/${query}`, this.getTokenHeader());
					this.options[field] = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			}
		}
	}
</script>
