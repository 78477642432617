<template>
	<div>
		<div v-if="files.length > 0">
			<p>
				{{ isReadOnly ? 'View': 'Manage' }} file uploads related to this {{itemLabel}}.
			</p>

			<b-list-group v-if="files.length > 0">
				<b-list-group-item class="d-flex justify-content-between align-items-center" v-for="file in files" :key="file.fileName">
					<a :href="file.fileName" target="_blank">{{file.description}}</a>
					<font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'times']" class="pointer text-danger" @click="deleteFile(file)" />
				</b-list-group-item>
			</b-list-group>
		</div>
		<div v-else>
			<p><em>There are no files uploaded for this {{itemLabel}} yet.</em></p>
		</div>

		<div v-if="!isReadOnly">
			<hr class="my-4" />

			<h3>Upload a File</h3>

			<error-list :errors="page.errors"></error-list>

			<b-row>
				<b-col md="6">
					<b-card class="bg-light">
						<b-form-file v-model="page.fileInput" @change="selectFile" class="mb-2"
									 accept=".pdf"
									 placeholder="Choose a file or drop it here..."
									 drop-placeholder="Drop file here..."></b-form-file>

						<b-form-input type="text" required v-model="page.upload.description" placeholder="Enter a description/label" />

						<save-button type="button" variant="primary" @click.native="uploadFile" :disabled="page.saving" class="mt-2" text="Upload"></save-button>
					</b-card>
				</b-col>
			</b-row>
		</div>

		<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
			<error-list :errors="page.delete.errors"></error-list>

			<p>
				Are you sure you want to delete this file?
				This action is permanent and cannot be undone.
			</p>

			<div slot="modal-footer">
				<save-button type="button" :saving="page.delete.saving" @click.native="confirmDeleteFile" text="Delete" variant="danger" />
				<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	export default {
		name: 'FileManager',
		props: {
			apiUrl: {
				type: String,
				required: true
			},
			itemLabel: {
				type: String,
				required: true
			},
			itemId: {
				required: true
			},
			files: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				page: {
					errors: [],
					saving: false,
					fileInput: null,
					upload: {
						file: null,
						description: null
					},
					delete: {
						model: null,
						show: false,
						saving: false,
						errors: []
					}
				}
			}
		},
		methods: {
			selectFile(event) {
				this.page.upload.file = event.target.files[0];
			},
			async uploadFile() {
				this.page.saving = true;
				this.page.errors = [];
				if (this.page.upload.file === null) {
					this.page.errors.push('Please select a file to upload.');
				}
				else if (this.isNullOrEmpty(this.page.upload.description)) {
					this.page.errors.push('Please enter a label or short description of the file.');
				} else {
					try {
						let formData = new FormData();
						formData.append('file', this.page.upload.file);
						formData.append('description', this.page.upload.description);

						const response = await this.$http.post(`${this.apiUrl}/files/${this.itemId}`, formData, this.getTokenHeaderMultiPart());
						this.log(response.data);
						this.page.upload.file = null;
						this.page.fileInput = null;
						this.page.upload.description = null;
						this.$emit('change');
					} catch (error) {
						this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			deleteFile(file) {
				this.page.delete.model = file;
				this.page.delete.show = true;
			},
			async confirmDeleteFile() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.put(`${this.apiUrl}/deletefile/${this.itemId}`, this.page.delete.model, this.getTokenHeader());
					this.page.delete.show = false;
					this.$emit('change');
				} catch (error) {
					this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			}
		}
	}
</script>
