<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<div v-if="!page.saveConfirmShow">
				<b-form @submit.prevent="save">
					<b-row>
						<b-col md>
							<b-form-group label="Amendment Date" :invalid-feedback="requiredFeedback($v.item.amendmentDate)">
								<b-form-input type="date" v-model.trim="$v.item.amendmentDate.$model" :state="getValidState($v.item.amendmentDate)"></b-form-input>
							</b-form-group>

							<b-form-group label="Expiration Date" :invalid-feedback="requiredFeedback($v.item.expirationDate)">
								<b-form-input type="date" v-model.trim="$v.item.expirationDate.$model" :state="getValidState($v.item.expirationDate)"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Revision #" :invalid-feedback="requiredFeedback($v.item.revisionNumber)">
								<b-form-input type="number" v-model.trim="$v.item.revisionNumber.$model" :state="getValidState($v.item.revisionNumber)"></b-form-input>
							</b-form-group>

							<b-form-group label="Revision Date" :invalid-feedback="requiredFeedback($v.item.revisionDate)">
								<b-form-input type="date" v-model.trim="$v.item.revisionDate.$model" :state="getValidState($v.item.revisionDate)"></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row>
						<b-col md>
							<h3 class="h5 border-bottom my-4">Construction Cost Share</h3>
							<b-row>
								<b-col md>
									<b-form-group label="Reimbursable %" :invalid-feedback="requiredFeedback($v.item.constructionPercent)">
										<b-input-group append="%">
											<b-form-input type="number" step="any"
														v-model.trim.number="$v.item.constructionPercent.$model" :state="getValidState($v.item.constructionPercent)" />
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col md>
									<b-form-group label="Amount" :invalid-feedback="requiredFeedback($v.item.constructionAmount)">
										<b-input-group prepend="$">
											<b-form-input type="number" step="any"
														v-model.trim.number="$v.item.constructionAmount.$model" :state="getValidState($v.item.constructionAmount)" />
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>

							<h3 class="h5 border-bottom my-4">Professional Services Cost Share</h3>
							<b-row>
								<b-col md>
									<b-form-group label="Reimbursable %" :invalid-feedback="requiredFeedback($v.item.profServicesPercent)">
										<b-input-group append="%">
											<b-form-input type="number" step="any"
														v-model.trim.number="$v.item.profServicesPercent.$model" :state="getValidState($v.item.profServicesPercent)" />
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col md>
									<b-form-group label="Amount" :invalid-feedback="requiredFeedback($v.item.profServicesAmount)">
										<b-input-group prepend="$">
											<b-form-input type="number" step="any"
														v-model.trim.number="$v.item.profServicesAmount.$model" :state="getValidState($v.item.profServicesAmount)" />
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
						</b-col>
						<b-col md>
							<h3 class="h5 border-bottom my-4">Administrative Cost Share Expenses</h3>
							<b-row>
								<b-col md>
									<b-form-group label="Reimbursable %" :invalid-feedback="requiredFeedback($v.item.adminPercent)">
										<b-input-group append="%">
											<b-form-input type="number" step="any"
														v-model.trim.number="$v.item.adminPercent.$model" :state="getValidState($v.item.adminPercent)" />
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col md>
									<b-form-group label="Amount" :invalid-feedback="requiredFeedback($v.item.adminAmount)">
										<b-input-group prepend="$">
											<b-form-input type="number" step="any"
														v-model.trim.number="$v.item.adminAmount.$model" :state="getValidState($v.item.adminAmount)" />
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>

							<h3 class="h5 border-bottom my-4">Land Rights Cost Share</h3>
							<b-row>
								<b-col md>
									<b-form-group label="Reimbursable %" :invalid-feedback="requiredFeedback($v.item.landRightsPercent)">
										<b-input-group append="%">
											<b-form-input type="number" step="any"
														v-model.trim.number="$v.item.landRightsPercent.$model" :state="getValidState($v.item.landRightsPercent)" />
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col md>
									<b-form-group label="Amount" :invalid-feedback="requiredFeedback($v.item.landRightsAmount)">
										<b-input-group prepend="$">
											<b-form-input type="number" step="any"
														v-model.trim.number="$v.item.landRightsAmount.$model" :state="getValidState($v.item.landRightsAmount)" />
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
						</b-col>
					</b-row>

					<b-form-group label="Non Cost Share Amount" :invalid-feedback="requiredFeedback($v.item.nonCostShareAmount)">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any"
								v-model.trim.number="$v.item.nonCostShareAmount.$model" :state="getValidState($v.item.nonCostShareAmount)" />
						</b-input-group>
					</b-form-group>

					<b-form-group label="Amendment Notes" :invalid-feedback="requiredFeedback($v.item.notes)" class="mb-4">
						<b-form-textarea v-model.trim="$v.item.notes.$model" :state="getValidState($v.item.notes)" rows="5"></b-form-textarea>
					</b-form-group>

					<div class="mt-4 mb-3 d-flex align-items-center">
						<save-button :saving="page.saving" class="mr-2" />
						<b-button type="button" variant="secondary" @click="cancel" class="ml-1">Cancel</b-button>
						<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
							<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
							You have errors in the form.
						</div>
					</div>
				</b-form>
			</div>
			<div v-else>
				<b-form-group label="Add a note to describe changes?" description="Optional">
					<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
				</b-form-group>

				<div class="my-3">
					<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
					<b-button type="button" variant="secondary" @click="cancel" class="ml-1">Cancel</b-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { required, integer, decimal } from 'vuelidate/lib/validators';

	export default {
		name: 'SrAgreementAmendmentForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				apiUrl: 'sragreementamendments',
				page: {
					loading: false,
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				}
			}
		},
		validations: {
			item: {
				agreementId: { required, integer },
				revisionNumber: { required, integer },
				amendmentDate: {},
				notes: {},
				expirationDate: {},
				constructionPercent: { required, decimal },
				constructionAmount: { required, decimal },
				profServicesPercent: { required, decimal },
				profServicesAmount: { required, decimal },
				adminPercent: { required, decimal },
				adminAmount: { required, decimal },
				landRightsPercent: { required, decimal },
				landRightsAmount: { required, decimal },
				nonCostShareAmount: { required, decimal },
				revisionDate: {}
			}
		},
		created() {
			this.get();
		},
		methods: {
			async get() {
				if (this.isUpdate) {
					this.item.amendmentDate = this.dateForForm(this.item.amendmentDate);
					this.item.expirationDate = this.dateForForm(this.item.expirationDate);
					this.item.revisionDate = this.dateForForm(this.item.revisionDate);
				}
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;
				this.logValidations();

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.page.errors.length < 1) {
						if (this.isUpdate) {
							this.page.saveConfirmShow = true;
						} else {
							try {
								const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
								this.log(response.data);

								this.$emit('change');
							} catch (error) {
								if (this.isApiUnauthorized(error)) this.page.showLogin = true;
								else this.page.errors = this.logError(error);
							}
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.id}`, data, this.getTokenHeader());
						this.log(response.data);

						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			logValidations() {
				this.log(this.$v);
			},
			cancel() {
				this.$emit('cancel');
			}
		}
	}
</script>
