<template>
	<auth-container :page="page">
		<h2 class="mb-4">Create New Invoice</h2>

		<sr-invoice-form :item="item"></sr-invoice-form>
	</auth-container>
</template>

<script>
	import SrInvoiceForm from './SrInvoiceForm.vue';
	export default {
		name: 'SrInvoiceCreate',
		components: {
			SrInvoiceForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				item: {
					workOrderDamId: null,
					invoiceNumber: null,
					invoiceSubmitDate: null,
					serviceStartDate: null,
					serviceEndDate: null,
					labor: 0,
					nonLabor: 0,
					subconsultant: 0,
					reviewer: null,
					approvalDate: null,
					notes: null
				}
			}
		}
	}
</script>