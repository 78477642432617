<template>
	<div>
		<auth-container :page="page" v-if="$route.name === 'SrInvoiceHubEdit'">
			<h2 class="mb-4">HUB Contractor</h2>

			<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
				<b-tab title="Overview">
					<b-table-simple small class="table-definitions">
						<b-tbody>
							<b-tr>
								<b-th>Vendor #</b-th>
								<b-td>{{valueOrNa(item.vendorNumber)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Name</b-th>
								<b-td>{{valueOrNa(item.name)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Certification Status</b-th>
								<b-td>{{valueOrNa(item.certificationStatus)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Certification Checked Date</b-th>
								<b-td>{{item.certificationCheckedDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Updated Date</b-th>
								<b-td>{{item.updatedDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Notes</b-th>
								<b-td>{{valueOrNa(item.notes, 'None')}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>

					<fixed-action-bar>
						<back-button />
						<b-button v-if="isAuthorized(roleNames.admin)" type="button" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="HUB Invoices">
					<b-table striped responsive small show-empty :fields="invoices.fields" :items="invoices.items">
						<template #cell(edit)="data">
							<router-link :to="`/sr/invoices/${data.item.workOrderInvoiceId}`">
								<font-awesome-icon :icon="['fas', 'edit']" />
							</router-link>
						</template>						
						<template #cell(invoiceNumber)="data">
							<router-link :to="`/sr/invoices/${data.item.workOrderInvoiceId}`">{{data.value}}</router-link>
						</template>					
						<template #cell(workOrderNumber)="data">
							<router-link :to="`/sr/invoices/${data.item.workOrderId}`">{{data.value}}</router-link>
						</template>					
						<template #cell(projectNumber)="data">
							<router-link :to="`/sr/projects/${data.item.projectId}`">{{data.value}}</router-link>
						</template>
						<template #cell(damId)="data">
							<router-link :to="`/dams/${data.value}`">{{data.item.nid_id}}</router-link>
						</template>
						<template #empty>
							<p class="my-2">This HUB Contractor does not have any invoice amounts entered.</p>
						</template>
					</b-table>
				</b-tab>
				<b-tab title="Edit" v-if="!isReadOnly">
					<sr-invoice-hub-form ref="itemForm" :item="item" is-update @change="changesSaved"></sr-invoice-hub-form>
				</b-tab>
				<b-tab title="Files">
					<file-manager :apiUrl="apiUrl" :files="files" :itemId="itemId" itemLabel="application" @change="filesChanged"></file-manager>
				</b-tab>
				<b-tab title="Change Log">
					<log-viewer db-table="SrHubContractors" :item-id="itemId"></log-viewer>
				</b-tab>
			</b-tabs>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete this HUB contractor?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import FileManager from '@/components/FileManager';
	import SrInvoiceHubForm from './SrInvoiceHubForm.vue';

	export default {
		name: 'SrInvoiceHubEdit',
		components: {
			SrInvoiceHubForm, FileManager
		},
		data() {
			return {
				apiUrl: 'srhubcontractors',
				listRoute: 'SrInvoiceHubList',
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					tabIndex: 0,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				item: {},
				invoices: {
					fields: [
						{ key: 'edit', label: '', stickyColumn: true },
						{ key: 'invoiceNumber', label: 'Invoice', sortable: true },
						{ key: 'workOrderNumber', label: 'Work Order', sortable: true },
						{ key: 'projectNumber', label: 'TPN', sortable: true },
						{ key: 'damId', label: 'NID_ID', sortable: true },
						{ key: 'amount', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
					],
					items: []
				},
				files: []
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.log(response.data);
					this.item = response.data.item;
					this.invoices.items = response.data.invoices;
					this.files = response.data.files;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async changesSaved() {
				await this.get();
				this.$refs.itemForm.launchToast();
				this.page.tabIndex = 2;
			},
			async filesChanged() {
				await this.get();
				this.page.tabIndex = 3;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$router.push({ name: this.listRoute });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			}
		},
		computed: {
			itemId() {
				return this.$route.params.id;
			}
		}
	}
</script>