<template>
	<div class="print-container">
		<auth-container :page="$parent.page">
			<print-menu></print-menu>

			<div class="d-flex mb-4">
				<h2 class="mr-2">Flood Control Structural Repair Program<br />Agreement #{{$parent.item.agreementNumber}}</h2>
				<div class="ml-auto text-right">
					<img :src="`${publicPath}images/logos/tsswcb-logo.svg`" alt="TSSWCB Logo" class="img-fluid" style="width:170px">
				</div>
			</div>

			<b-row>
				<b-col md>
					<b-table-simple small class="table-definitions">
						<b-tbody>
							<b-tr>
								<b-th>Agreement #</b-th>
								<b-td>{{valueOrNa($parent.item.agreementNumber)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Agreement Type</b-th>
								<b-td>{{$parent.agreementType}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Project</b-th>
								<b-td>
									#{{$parent.project.projectNumber}} - {{$parent.project.name}}
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>Dam</b-th>
								<b-td>
									<span v-if="isNullOrEmpty($parent.dam.id)">N/A</span>
									<span v-else>{{$parent.dam.name}}</span>
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>Funding Source</b-th>
								<b-td>
									<span v-if="isNullOrEmpty($parent.item.fundingSourceId)">N/A</span>
									<span v-else>{{$parent.fundingSource.enteredIdentifier}} - {{$parent.fundingSource.name}}</span>
								</b-td>
							</b-tr>

							<b-tr>
								<b-th>Offeror</b-th>
								<b-td>{{valueOrNa($parent.item.offeror)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Offeree</b-th>
								<b-td>{{valueOrNa($parent.item.offeree)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Status</b-th>
								<b-td>{{valueOrNa($parent.item.status)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Execution Date</b-th>
								<b-td>{{$parent.item.executionDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Notes</b-th>
								<b-td>{{valueOrNa($parent.item.notes, 'None')}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-col>
			</b-row>

			<h3 class="mb-3">Amendments</h3>
			<b-table-simple small class="table-definitions" bordered v-if="$parent.amendments.items.length > 0">
				<b-tbody v-for="(r,i) in $parent.amendments.items" :key="i">
					<b-tr v-for="(f,j) in amendments.fields" :key="j">
						<b-th class="bg-light" :style="j == 0 ? 'border-top-width: 3px' : ''">{{f.label}}</b-th>
						<b-td :style="j == 0 ? 'border-top-width: 3px' : ''">{{f.formatter(r[f.key])}}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
			<p v-else>This agreement does not have any amendments entered.</p>

			<h3 class="mb-3">Reimbursements</h3>
			<b-table-simple small class="table-definitions" bordered v-if="$parent.reimbursements.items.length > 0">
				<b-tbody v-for="(r,i) in $parent.reimbursements.items" :key="i">
					<b-tr v-for="(f,j) in reimbursements.fields" :key="j">
						<b-th class="bg-light" :style="j == 0 ? 'border-top-width: 3px' : ''">{{f.label}}</b-th>
						<b-td :style="j == 0 ? 'border-top-width: 3px' : ''">{{f.formatter(r[f.key])}}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
			<p v-else>This agreement does not have any reimbursements entered.</p>
		</auth-container>
	</div>
</template>

<script>
	import PrintMenu from '@/components/helpers/PrintMenu';

	export default {
		name: 'SrAgreementPrint',
		components: { PrintMenu },
		data() {
			return {
				reimbursements: {
					fields: [
						{ key: 'invoiceNumber', label: 'Invoice#', sortable: true, formatter: (value) => { return value } },
						{ key: 'receivedDate', label: 'Received Date', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'wasStateRetainageRequested', label: 'State Retainage?', sortable: true, class: 'text-center', formatter: (value) => { return this.boolToText(value); } },
						{ key: 'stateConstructionCost', label: 'State Constr.', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'stateLandRights', label: 'State LR', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'wasAdminRequestedInAdvance', label: 'Admin Req. in Advance?', sortable: true, class: 'text-center', formatter: (value) => { return this.boolToText(value); } },
						{ key: 'stateAdminCost', label: 'State Admin', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'federalConstructionCost', label: 'Fed. Constr.', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'federalLandRights', label: 'Fed. LR', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'sponsorConstructionCost', label: 'Sponsor Constr.', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'approvedDate', label: 'Approved Date', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'paidDate', label: 'Paid Date', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
					]
				},
				amendments: {
					fields: [
						{ key: 'revisionNumber', label: 'Rev#', sortable: true, formatter: (value) => { return value } },
						{ key: 'amendmentDate', label: 'Amendment Date', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'notes', label: 'Notes', sortable: true, formatter: (value) => { return value } },
						{ key: 'expirationDate', label: 'expirationDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'constructionPercent', label: 'Constr. %', sortable: true, class: 'text-right', formatter: (value) => { return this.numberFormat(value, 2, '%'); } },
						{ key: 'constructionAmount', label: 'Constr. $', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'profServicesPercent', label: 'Pro. Serv. %', sortable: true, class: 'text-right', formatter: (value) => { return this.numberFormat(value, 2, '%'); } },
						{ key: 'profServicesAmount', label: 'Pro. Serv. $', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'adminPercent', label: 'Admin. %', sortable: true, class: 'text-right', formatter: (value) => { return this.numberFormat(value, 2, '%'); } },
						{ key: 'adminAmount', label: 'Admin. $', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'landRightsPercent', label: 'LR %', sortable: true, class: 'text-right', formatter: (value) => { return this.numberFormat(value, 2, '%'); } },
						{ key: 'landRightsAmount', label: 'LR $', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'nonCostShareAmount', label: 'Non CS', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'revisionDate', label: 'Revision Date', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
					],
				},
			}
		}
	}
</script>