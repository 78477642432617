<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
			Your changes have been saved.
		</b-toast>

		<b-alert :show="showNoExist" variant="info" dismissible>
			A dam assessment phase has not yet been entered for this project. Complete the form to create one.
		</b-alert>

		<b-form @submit.prevent="save">
			<b-row>
				<b-col md>
					<b-form-group label="Project" :invalid-feedback="requiredFeedback($v.query.project)">
						<b-typeahead v-model.trim="$v.query.project.$model" required placeholder="Type to search projects by number, name, or dam..."
									 :data="options.projects == null ? [] : options.projects"
									 :serializer="s => s.name" @hit="item.projectId = $event.id;"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Name" :invalid-feedback="requiredFeedback($v.item.name)">
						<b-form-input type="text" required
									  v-model.trim="$v.item.name.$model" :state="getValidState($v.item.name)" />
					</b-form-group>

					<b-form-group label="Final Report Date" :invalid-feedback="requiredFeedback($v.item.finalReportDate)">
						<b-form-input type="date" v-model.trim="$v.item.finalReportDate.$model" :state="getValidState($v.item.finalReportDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="NRCS Contact" :invalid-feedback="requiredFeedback($v.item.nrcsContact)">
						<b-typeahead v-model.trim="$v.item.nrcsContact.$model"
									 :data="options.nrcsContact"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Assigned A&E" :invalid-feedback="requiredFeedback($v.item.assignedAe)">
						<b-typeahead v-model.trim="$v.item.assignedAe.$model"
									:data="options.assignedAe"
									:max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Status" :invalid-feedback="requiredFeedback($v.item.status)">
						<b-input-group append="%">
							<b-form-input type="number" step="any" v-model.trim="$v.item.status.$model" :state="getValidState($v.item.status)"></b-form-input>
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>

			<hr class="my-4" />

			<b-form-group label="Notes" :invalid-feedback="requiredFeedback($v.item.notes)" class="mb-4">
				<b-form-textarea v-model.trim="$v.item.notes.$model" :state="getValidState($v.item.notes)" rows="3"></b-form-textarea>
			</b-form-group>

			<fixed-action-bar>
				<save-button :saving="page.saving" class="mr-2" />
				<back-button />
				<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
					You have errors in the form.
				</div>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.saveConfirmShow" title="Confirm Save" scrollable size="lg">
			<error-list :errors="page.errors"></error-list>

			<b-form-group label="Add a note to describe changes?" description="Optional">
				<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
			</b-form-group>

			<div slot="modal-footer">
				<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
				<b-button type="button" variant="primary" @click="page.saveConfirmShow = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required, decimal, integer } from 'vuelidate/lib/validators';
	import _ from 'underscore';

	export default {
		name: 'SrReaffirmStudyForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			project: {
				type: Object,
				required: false
			},
			showNoExist: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				apiUrl: 'srplanreaffstudies',
				editRoute: 'SrReaffirmStudyEdit',
				page: {
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				},
				options: {
					projects: [],
					nrcsContact: [],
					assignedAe: []
				},
				query: {
					project: ''
				}
			}
		},
		computed: {
			queryProject() {
				return this.query.project;
			},
			itemNrcsContact() {
				return this.item.nrcsContact;
			},
			itemAssignedAe() {
				return this.item.assignedAe;
			}
		},
		validations: {
			item: {
				projectId: { required, integer },
				name: {},
				finalReportDate: {},
				nrcsContact: {},
				assignedAe: {},
				status: { required },
				notes: {}
			},
			query: {
				project: { required }
			}
		},
		watch: {
			queryProject: _.debounce(function (query) { this.findProjects(query) }, 500),
			itemNrcsContact: _.debounce(function (query) { this.find('nrcsContact', query) }, 500),
			itemAssignedAe: _.debounce(function (query) { this.find('assignedAe', query) }, 500)
		},
		created() {
			this.get();
		},
		methods: {
			get() {
				if (!this.isNullOrEmpty(this.project)) {
					this.query.project = this.project.name;
					this.item.projectId = this.project.id;
				}

				if (this.isUpdate) {
					this.item.finalReportDate = this.dateForForm(this.item.finalReportDate);
				}
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.isUpdate) {
						this.page.saveConfirmShow = true;
					} else {
						try {
							const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
							this.log(response.data);

							this.$router.push({ name: this.editRoute, params: { id: response.data } });
						} catch (error) {
							if (this.isApiUnauthorized(error)) this.page.showLogin = true;
							else this.page.errors = this.logError(error);
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.projectId}`, data, this.getTokenHeader());
						this.log(response.data);

						this.page.saveConfirmShow = false;
						this.$bvToast.show('changes-saved');
						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			launchToast() {
				this.$bvToast.show('changes-saved');
			},
			logValidations() {
				this.log(this.$v);
			},
			async findProjects(query) {
				try {
					//const response = await this.$http.get(`/srprojects/find-id/${encodeURIComponent(query)}`, this.getTokenHeader());
					const response = await this.$http.post(`/srprojects/find-id-post`, { value: query }, this.getTokenHeader());
					this.options.projects = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async find(field, query) {
				try {
					const response = await this.$http.get(`/${this.apiUrl}/find/${field}/${query}`, this.getTokenHeader());
					this.options[field] = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			}
		}
	}
</script>
