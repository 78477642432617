import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home.vue';

import UserList from '../views/users/UserList.vue';
import UserCreate from '../views/users/UserCreate.vue';
import UserEdit from '../views/users/UserEdit.vue';

import AccountProfile from '../views/account/AccountProfile.vue';

import FundList from '../views/funds/FundList.vue';

import DamList from '../views/dams/DamList.vue';
import DamCreate from '../views/dams/DamCreate.vue';
import DamEdit from '../views/dams/DamEdit.vue';

import SwcdList from '../views/swcds/SwcdList.vue';
import SwcdCreate from '../views/swcds/SwcdCreate.vue';
import SwcdEdit from '../views/swcds/SwcdEdit.vue';

import MapLayerList from '../views/maplayers/MapLayerList.vue';
import MapLayerCreate from '../views/maplayers/MapLayerCreate.vue';
import MapLayerEdit from '../views/maplayers/MapLayerEdit.vue';

import OmDashboard from '../views/om/OmDashboard.vue';
import SponsorList from '../views/om/sponsors/SponsorList.vue';
import SponsorEdit from '../views/om/sponsors/SponsorEdit.vue';
import SponsorCreate from '../views/om/sponsors/SponsorCreate.vue';

import ActivityList from '../views/om/activities/ActivityList.vue';
import ActivityEdit from '../views/om/activities/ActivityEdit.vue';
import ActivityCreate from '../views/om/activities/ActivityCreate.vue';

import ProposalList from '../views/om/proposals/ProposalList.vue';
import ProposalEdit from '../views/om/proposals/ProposalEdit.vue';
import ProposalCreate from '../views/om/proposals/ProposalCreate.vue';
import ProposalPrint from '../views/om/proposals/ProposalPrint.vue';

import ReimbursementList from '../views/om/reimbursements/ReimbursementList.vue';
import ReimbursementEdit from '../views/om/reimbursements/ReimbursementEdit.vue';
import ReimbursementCreate from '../views/om/reimbursements/ReimbursementCreate.vue';
import ReimbursementPrint from '../views/om/reimbursements/ReimbursementPrint.vue';

import InkindMatchList from '../views/om/inkindmatches/InkindMatchList.vue';
import InkindMatchEdit from '../views/om/inkindmatches/InkindMatchEdit.vue';
import InkindMatchCreate from '../views/om/inkindmatches/InkindMatchCreate.vue';
import InkindMatchPrint from '../views/om/inkindmatches/InkindMatchPrint.vue';

import OmReportsList from '../views/om/reports/OmReportsList.vue';
import OmPracticeReport from '../views/om/reports/OmPracticeReport.vue';
import OmProposalFundingReport from '../views/om/reports/OmProposalFundingReport.vue';

import SrDashboard from '../views/sr/SrDashboard.vue';
import SrContactList from '../views/sr/contacts/SrContactList.vue';
import SrContactEdit from '../views/sr/contacts/SrContactEdit.vue';
import SrContactCreate from '../views/sr/contacts/SrContactCreate.vue';

import SrFieldRepList from '../views/sr/fieldreps/SrFieldRepList.vue';
import SrFieldRepEdit from '../views/sr/fieldreps/SrFieldRepEdit.vue';
import SrFieldRepCreate from '../views/sr/fieldreps/SrFieldRepCreate.vue';

import SrApplicationList from '../views/sr/applications/SrApplicationList.vue';
import SrApplicationEdit from '../views/sr/applications/SrApplicationEdit.vue';
import SrApplicationCreate from '../views/sr/applications/SrApplicationCreate.vue';
import SrApplicationPrint from '../views/sr/applications/SrApplicationPrint.vue';

import SrPlanList from '../views/sr/plans/SrPlanList.vue';
import SrPlanEdit from '../views/sr/plans/SrPlanEdit.vue';
import SrPlanCreate from '../views/sr/plans/SrPlanCreate.vue';
import SrPlanPrint from '../views/sr/plans/SrPlanPrint.vue';

import SrProjectList from '../views/sr/projects/SrProjectList.vue';
import SrProjectEdit from '../views/sr/projects/SrProjectEdit.vue';
import SrProjectCreate from '../views/sr/projects/SrProjectCreate.vue';
import SrProjectPrint from '../views/sr/projects/SrProjectPrint.vue';

import SrAgreementList from '../views/sr/agreements/SrAgreementList.vue';
import SrAgreementEdit from '../views/sr/agreements/SrAgreementEdit.vue';
import SrAgreementCreate from '../views/sr/agreements/SrAgreementCreate.vue';
import SrAgreementPrint from '../views/sr/agreements/SrAgreementPrint.vue';

import SrWorkOrderList from '../views/sr/workorders/SrWorkOrderList.vue';
import SrWorkOrderEdit from '../views/sr/workorders/SrWorkOrderEdit.vue';
import SrWorkOrderCreate from '../views/sr/workorders/SrWorkOrderCreate.vue';
import SrWorkOrderPrint from '../views/sr/workorders/SrWorkOrderPrint.vue';

import SrInvoiceList from '../views/sr/invoices/SrInvoiceList.vue';
import SrInvoiceEdit from '../views/sr/invoices/SrInvoiceEdit.vue';
import SrInvoiceCreate from '../views/sr/invoices/SrInvoiceCreate.vue';
import SrInvoicePrint from '../views/sr/invoices/SrInvoicePrint.vue';

import SrInvoiceHubList from '../views/sr/hubs/SrInvoiceHubList.vue';
import SrInvoiceHubEdit from '../views/sr/hubs/SrInvoiceHubEdit.vue';
import SrInvoiceHubCreate from '../views/sr/hubs/SrInvoiceHubCreate.vue';

import SrDesignList from '../views/sr/projects/design/SrDesignList.vue';
import SrDesignEdit from '../views/sr/projects/design/SrDesignEdit.vue';
import SrDesignCreate from '../views/sr/projects/design/SrDesignCreate.vue';
import SrDesignPrint from '../views/sr/projects/design/SrDesignPrint.vue';

import SrConstructionList from '../views/sr/projects/construction/SrConstructionList.vue';
import SrConstructionEdit from '../views/sr/projects/construction/SrConstructionEdit.vue';
import SrConstructionCreate from '../views/sr/projects/construction/SrConstructionCreate.vue';
import SrConstructionPrint from '../views/sr/projects/construction/SrConstructionPrint.vue';

import SrDamAssessmentList from '../views/sr/projects/dam-assessment/SrDamAssessmentList.vue';
import SrDamAssessmentEdit from '../views/sr/projects/dam-assessment/SrDamAssessmentEdit.vue';
import SrDamAssessmentCreate from '../views/sr/projects/dam-assessment/SrDamAssessmentCreate.vue';
import SrDamAssessmentPrint from '../views/sr/projects/dam-assessment/SrDamAssessmentPrint.vue';

import SrPlanEnvAssessmentList from '../views/sr/projects/plan-env-assessment/SrPlanEnvAssessmentList.vue';
import SrPlanEnvAssessmentEdit from '../views/sr/projects/plan-env-assessment/SrPlanEnvAssessmentEdit.vue';
import SrPlanEnvAssessmentCreate from '../views/sr/projects/plan-env-assessment/SrPlanEnvAssessmentCreate.vue';
import SrPlanEnvAssessmentPrint from '../views/sr/projects/plan-env-assessment/SrPlanEnvAssessmentPrint.vue';

import SrReaffirmStudyList from '../views/sr/projects/reaffirm-study/SrReaffirmStudyList.vue';
import SrReaffirmStudyEdit from '../views/sr/projects/reaffirm-study/SrReaffirmStudyEdit.vue';
import SrReaffirmStudyCreate from '../views/sr/projects/reaffirm-study/SrReaffirmStudyCreate.vue';
import SrReaffirmStudyPrint from '../views/sr/projects/reaffirm-study/SrReaffirmStudyPrint.vue';

import SrFundingSourceList from '../views/sr/fundingsources/SrFundingSourceList.vue';
import SrFundingSourceEdit from '../views/sr/fundingsources/SrFundingSourceEdit.vue';
import SrFundingSourceCreate from '../views/sr/fundingsources/SrFundingSourceCreate.vue';

import SrReimbursementList from '../views/sr/reimbursements/SrReimbursementList.vue';
import SrReimbursementEdit from '../views/sr/reimbursements/SrReimbursementEdit.vue';
import SrReimbursementCreate from '../views/sr/reimbursements/SrReimbursementCreate.vue';
import SrReimbursementPrint from '../views/sr/reimbursements/SrReimbursementPrint.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/', name: 'Home', component: Home, meta: { title: 'Dashboard' },
        children: [
            {
                path: 'account', name: 'AccountProfile', component: AccountProfile, meta: { title: 'Account' }
            },
            {
                path: 'om', name: 'OmDashboard', component: OmDashboard, meta: { title: 'O&M' },
                children: [
                    {
                        path: 'sponsors', name: 'SponsorList', component: SponsorList, meta: { title: 'Sponsors' },
                        children: [
                            {
                                path: 'create', name: 'SponsorCreate', component: SponsorCreate, meta: { title: 'Create' }
                            },
                            {
                                path: ':id', name: 'SponsorEdit', component: SponsorEdit, meta: { title: 'Edit' }
                            }
                        ]
                    },
                    {
                        path: 'activities', name: 'ActivityList', component: ActivityList, meta: { title: 'Eligible Practices' },
                        children: [
                            {
                                path: 'create', name: 'ActivityCreate', component: ActivityCreate, meta: { title: 'Create' }
                            },
                            {
                                path: ':id', name: 'ActivityEdit', component: ActivityEdit, meta: { title: 'Edit' }
                            }
                        ]
                    },
                    {
                        path: 'proposals', name: 'ProposalList', component: ProposalList, meta: { title: 'Proposals' },
                        children: [
                            {
                                path: 'create', name: 'ProposalCreate', component: ProposalCreate, meta: { title: 'Create' }
                            },
                            {
                                path: ':id', name: 'ProposalEdit', component: ProposalEdit, meta: { title: 'Edit' },
                                children: [
                                    {
                                        path: 'print', name: 'ProposalPrint', component: ProposalPrint, meta: { title: 'Print' }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'reimbursements', name: 'ReimbursementList', component: ReimbursementList, meta: { title: 'Reimbursement Requests' },
                        children: [
                            {
                                path: 'create', name: 'ReimbursementCreate', component: ReimbursementCreate, meta: { title: 'Create' }
                            },
                            {
                                path: ':id', name: 'ReimbursementEdit', component: ReimbursementEdit, meta: { title: 'Edit' },
                                children: [
                                    {
                                        path: 'print', name: 'ReimbursementPrint', component: ReimbursementPrint, meta: { title: 'Print' }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'inkindmatches', name: 'InkindMatchList', component: InkindMatchList, meta: { title: 'In-Kind Match Reports' },
                        children: [
                            {
                                path: 'create', name: 'InkindMatchCreate', component: InkindMatchCreate, meta: { title: 'Create' }
                            },
                            {
                                path: ':id', name: 'InkindMatchEdit', component: InkindMatchEdit, meta: { title: 'Edit' },
                                children: [
                                    {
                                        path: 'print', name: 'InkindMatchPrint', component: InkindMatchPrint, meta: { title: 'Print' }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'reports', name: 'OmReportsList', component: OmReportsList, meta: { title: 'Reports' },
                        children: [
                            {
                                path: 'practices', name: 'OmPracticeReport', component: OmPracticeReport, meta: { title: 'Practices' }
                            },
                            {
                                path: 'proposalfunding', name: 'OmProposalFundingReport', component: OmProposalFundingReport, meta: { title: '31.529.A Funding Report' }
                            }
                        ]
                    }
                ]
            },
            {
                path: 'sr', name: 'SrDashboard', component: SrDashboard, meta: { title: 'Structural Repair' },
                children: [
                    {
                        path: 'contacts', name: 'SrContactList', component: SrContactList, meta: { title: 'Contacts' },
                        children: [
                            {
                                path: 'create', name: 'SrContactCreate', component: SrContactCreate, meta: { title: 'Create' }
                            },
                            {
                                path: ':id', name: 'SrContactEdit', component: SrContactEdit, meta: { title: 'Edit' }
                            }
                        ]
                    },
					{
                        path: 'fieldreps', name: 'SrFieldRepList', component: SrFieldRepList, meta: { title: 'Field Reps' },
                        children: [
                            {
                                path: 'create', name: 'SrFieldRepCreate', component: SrFieldRepCreate, meta: { title: 'Create' }
                            },
                            {
                                path: ':id', name: 'SrFieldRepEdit', component: SrFieldRepEdit, meta: { title: 'Edit' }
                            }
                        ]
                    },
                    {
                        path: 'applications', name: 'SrApplicationList', component: SrApplicationList, meta: { title: 'Applications' },
                        children: [
                            {
                                path: 'create', name: 'SrApplicationCreate', component: SrApplicationCreate, meta: { title: 'Create' }
                            },
                            {
                                path: ':id', name: 'SrApplicationEdit', component: SrApplicationEdit, meta: { title: 'Edit' },
                                children: [
                                    {
                                        path: 'print', name: 'SrApplicationPrint', component: SrApplicationPrint, meta: { title: 'Print' }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'plans', name: 'SrPlanList', component: SrPlanList, meta: { title: 'Plans' },
                        children: [
                            {
                                path: 'create', name: 'SrPlanCreate', component: SrPlanCreate, meta: { title: 'Create' }
                            },
                            {
                                path: ':id', name: 'SrPlanEdit', component: SrPlanEdit, meta: { title: 'Edit' },
                                children: [
                                    {
                                        path: 'print', name: 'SrPlanPrint', component: SrPlanPrint, meta: { title: 'Print' }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'projects', name: 'SrProjectList', component: SrProjectList, meta: { title: 'Projects' },
                        children: [
                            {
                                path: 'dam-assessment', name: 'SrDamAssessmentList', component: SrDamAssessmentList, meta: { title: 'Dam Assessment Phases' },
                                children: [
                                    {
                                        path: 'create', name: 'SrDamAssessmentCreate', component: SrDamAssessmentCreate, meta: { title: 'Create' }
                                    },
                                    {
                                        path: ':id', name: 'SrDamAssessmentEdit', component: SrDamAssessmentEdit, meta: { title: 'Edit' },
                                        children: [
                                            {
                                                path: 'print', name: 'SrDamAssessmentPrint', component: SrDamAssessmentPrint, meta: { title: 'Print' }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                path: 'plan-env-assessment', name: 'SrPlanEnvAssessmentList', component: SrPlanEnvAssessmentList, meta: { title: 'Plan & Env. Assessment Phases' },
                                children: [
                                    {
                                        path: 'create', name: 'SrPlanEnvAssessmentCreate', component: SrPlanEnvAssessmentCreate, meta: { title: 'Create' }
                                    },
                                    {
                                        path: ':id', name: 'SrPlanEnvAssessmentEdit', component: SrPlanEnvAssessmentEdit, meta: { title: 'Edit' },
                                        children: [
                                            {
                                                path: 'print', name: 'SrPlanEnvAssessmentPrint', component: SrPlanEnvAssessmentPrint, meta: { title: 'Print' }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                path: 'reaffirm-study', name: 'SrReaffirmStudyList', component: SrReaffirmStudyList, meta: { title: 'Reaffirming Study Phases' },
                                children: [
                                    {
                                        path: 'create', name: 'SrReaffirmStudyCreate', component: SrReaffirmStudyCreate, meta: { title: 'Create' }
                                    },
                                    {
                                        path: ':id', name: 'SrReaffirmStudyEdit', component: SrReaffirmStudyEdit, meta: { title: 'Edit' },
                                        children: [
                                            {
                                                path: 'print', name: 'SrReaffirmStudyPrint', component: SrReaffirmStudyPrint, meta: { title: 'Print' }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                path: 'design', name: 'SrDesignList', component: SrDesignList, meta: { title: 'Design Phases' },
                                children: [
                                    {
                                        path: 'create', name: 'SrDesignCreate', component: SrDesignCreate, meta: { title: 'Create' }
                                    },
                                    {
                                        path: ':id', name: 'SrDesignEdit', component: SrDesignEdit, meta: { title: 'Edit' },
                                        children: [
                                            {
                                                path: 'print', name: 'SrDesignPrint', component: SrDesignPrint, meta: { title: 'Print' }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                path: 'construction', name: 'SrConstructionList', component: SrConstructionList, meta: { title: 'Construction Phases' },
                                children: [
                                    {
                                        path: 'create', name: 'SrConstructionCreate', component: SrConstructionCreate, meta: { title: 'Create' }
                                    },
                                    {
                                        path: ':id', name: 'SrConstructionEdit', component: SrConstructionEdit, meta: { title: 'Edit' },
                                        children: [
                                            {
                                                path: 'print', name: 'SrConstructionPrint', component: SrConstructionPrint, meta: { title: 'Print' }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                path: 'create', name: 'SrProjectCreate', component: SrProjectCreate, meta: { title: 'Create' }
                            },
                            {
                                path: ':id', name: 'SrProjectEdit', component: SrProjectEdit, meta: { title: 'Edit' },
                                children: [
                                    {
                                        path: 'print', name: 'SrProjectPrint', component: SrProjectPrint, meta: { title: 'Print' }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'agreements/:atype', name: 'SrAgreementList', component: SrAgreementList, meta: { title: 'Agreements' },
                        children: [
                            {
                                path: 'create', name: 'SrAgreementCreate', component: SrAgreementCreate, meta: { title: 'Create' }
                            },
                            {
                                path: ':id', name: 'SrAgreementEdit', component: SrAgreementEdit, meta: { title: 'Edit' },
                                children: [
                                    {
                                        path: 'print', name: 'SrAgreementPrint', component: SrAgreementPrint, meta: { title: 'Print' }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'workorders', name: 'SrWorkOrderList', component: SrWorkOrderList, meta: { title: 'Work Orders' },
                        children: [
                            
                            
                            {
                                path: 'create', name: 'SrWorkOrderCreate', component: SrWorkOrderCreate, meta: { title: 'Create' }
                            },
                            {
                                path: ':id', name: 'SrWorkOrderEdit', component: SrWorkOrderEdit, meta: { title: 'Edit' },
                                children: [
                                    {
                                        path: 'print', name: 'SrWorkOrderPrint', component: SrWorkOrderPrint, meta: { title: 'Print' }
                                    }
                                ]
                            }
                        ]
                    },
					{
						path: 'invoices', name: 'SrInvoiceList', component: SrInvoiceList, meta: { title: 'Invoices' },
						children: [
							{
								path: 'create', name: 'SrInvoiceCreate', component: SrInvoiceCreate, meta: { title: 'Create' }
							},
							{
								path: ':id', name: 'SrInvoiceEdit', component: SrInvoiceEdit, meta: { title: 'Edit' },
								children: [
									{
										path: 'print', name: 'SrInvoicePrint', component: SrInvoicePrint, meta: { title: 'Print' }
									}
								]
							}
						]
					},
					{
						path: 'hubs', name: 'SrInvoiceHubList', component: SrInvoiceHubList, meta: { title: 'HUB Contractors' },
						children: [
							{
								path: 'create', name: 'SrInvoiceHubCreate', component: SrInvoiceHubCreate, meta: { title: 'Create' }
							},
							{
								path: ':id', name: 'SrInvoiceHubEdit', component: SrInvoiceHubEdit, meta: { title: 'Edit' }
							}
						]
					},
					{
                        path: 'fundingsources', name: 'SrFundingSourceList', component: SrFundingSourceList, meta: { title: 'Funding Sources' },
                        children: [
                            {
                                path: 'create', name: 'SrFundingSourceCreate', component: SrFundingSourceCreate, meta: { title: 'Create' }
                            },
                            {
                                path: ':id', name: 'SrFundingSourceEdit', component: SrFundingSourceEdit, meta: { title: 'Edit' }
                            }
                        ]
                    },
					{
                        path: 'reimbursements', name: 'SrReimbursementList', component: SrReimbursementList, meta: { title: 'Reimbursement Submissions' },
                        children: [
                            {
                                path: 'create', name: 'SrReimbursementCreate', component: SrReimbursementCreate, meta: { title: 'Create' }
                            },
                            {
                                path: ':id', name: 'SrReimbursementEdit', component: SrReimbursementEdit, meta: { title: 'Edit' },
                                children: [
                                    {
                                        path: 'print', name: 'SrReimbursementPrint', component: SrReimbursementPrint, meta: { title: 'Print' }
                                    }
                                ]
                            }
                        ]
                    },
                ]
			},
            {
                path: 'dams', name: 'DamList', component: DamList, meta: { title: 'Dams' },
                children: [
                    {
                        path: 'create', name: 'DamCreate', component: DamCreate, meta: { title: 'Create' }
                    },
                    {
                        path: ':id', name: 'DamEdit', component: DamEdit, meta: { title: 'Edit' }
                    }
                ]
            },
            {
                path: 'swcds', name: 'SwcdList', component: SwcdList, meta: { title: 'SWCDs' },
                children: [
                    {
                        path: 'create', name: 'SwcdCreate', component: SwcdCreate, meta: { title: 'Create' }
                    },
                    {
                        path: ':id', name: 'SwcdEdit', component: SwcdEdit, meta: { title: 'Edit' }
                    }
                ]
            },
            {
                path: 'maplayers', name: 'MapLayerList', component: MapLayerList, meta: { title: 'Map Layers' },
                children: [
                    {
                        path: 'create', name: 'MapLayerCreate', component: MapLayerCreate, meta: { title: 'Create' }
                    },
                    {
                        path: ':id', name: 'MapLayerEdit', component: MapLayerEdit, meta: { title: 'Edit' }
                    }
                ]
            },
            {
                path: 'funds', name: 'FundList', component: FundList, meta: { title: 'Funds' }
            },
            {
                path: 'users', name: 'UserList', component: UserList, meta: { title: 'Users' },
                children: [
                    {
                        path: 'create', name: 'UserCreate', component: UserCreate, meta: { title: 'Create' }
                    },
                    {
                        path: ':id', name: 'UserEdit', component: UserEdit, meta: { title: 'Edit' }
                    }
                ]
            }
        ]
    },
    {
        path: '*', redirect: '/'
    }
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
});

export default router
