<template>
	<div>
		<auth-container :page="page" v-if="$route.name === 'SrReimbursementEdit'">
			<h2 class="mb-4">Reimbursement Submission #{{item.submissionNumber}}</h2>

			<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
				<b-tab title="Overview">
					<b-row>
						<b-col md>
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Submission #</b-th>
										<b-td>{{valueOrNa(item.submissionNumber)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Submission Date</b-th>
										<b-td>{{item.submissionDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Agency Staff</b-th>
										<b-td>{{valueOrNa(item.agencyStaff)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Department</b-th>
										<b-td>{{valueOrNa(item.department)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Submission Category</b-th>
										<b-td>{{valueOrNa(item.submissionCategory, 'None')}}</b-td>
									</b-tr>
									
								</b-tbody>
							</b-table-simple>
						</b-col>
						<b-col md>
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Approval Notice Date</b-th>
										<b-td>{{item.approvalNoticeDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Funds Arrival Date</b-th>
										<b-td>{{item.fundsArrivalDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Funds Left Date</b-th>
										<b-td>{{item.fundsLeftDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Notes</b-th>
										<b-td>{{valueOrNa(item.notes, 'None')}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
					</b-row>

					<fixed-action-bar>
						<b-button type="button" variant="primary" to="print" append target="_blank" class="mr-2">Print</b-button>
						<back-button />
						<b-button v-if="isAuthorized(roleNames.admin)" type="button" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="Documents">
					<b-table striped responsive small show-empty :fields="docs.fields" :items="docs.items">
						<template #cell(edit)="data">
							<font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'edit']" class="pointer text-primary" @click="editSubItem(data.item.id)" />
						</template>
						<template #cell(delete)="data">
							<font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'times']" class="pointer text-danger" @click="deleteSubItem(data.item.id)" />
						</template>
						<template #cell(invoice)="data">
							<router-link v-if="!isNullOrEmpty(data.value)" :to="`/sr/invoices/${data.item.invoiceId}`">{{data.value}}</router-link>
						</template>
						<template #cell(agreementReimbursement)="data">
							<router-link v-if="!isNullOrEmpty(data.value)" :to="`/sr/agreements/${data.item.agreementType}/${data.item.agreementId}`">{{data.value}}</router-link>
						</template>
						<template #empty>
							<p class="my-2">This reimbursement submission does not have any documents entered.</p>
						</template>

						<template #custom-foot>
							<b-tr class="bg-light border-bottom font-weight-bold">
								<b-td colspan="4">Total</b-td>
								<b-td class="text-right">${{ sum(docs.items, 'totalAmount') | number(2)}}</b-td>
								<b-td class="text-right">${{ sum(docs.items, 'stateAdminAmount') | number(2)}}</b-td>
								<b-td colspan="2"></b-td>
							</b-tr>
						</template>
					</b-table>

					<fixed-action-bar>
						<b-button v-if="!isReadOnly" type="button" variant="success" @click="createSubItem" class="mr-2">Create Document</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="Edit" v-if="!isReadOnly">
					<sr-reimbursement-form ref="itemForm" :item="item" :set-options="setOptions" is-update @change="changesSaved"></sr-reimbursement-form>
				</b-tab>
				<b-tab title="Files">
					<file-manager :apiUrl="apiUrl" :files="files" :itemId="itemId" itemLabel="application" @change="filesChanged"></file-manager>
				</b-tab>
				<b-tab title="Change Log">
					<log-viewer db-table="SrWorkOrderInvoices" :item-id="itemId"></log-viewer>
				</b-tab>
			</b-tabs>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete this submission?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.deleteSubItem.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.deleteSubItem.errors"></error-list>

				<p>
					Are you sure you want to delete this document?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.deleteSubItem.saving" @click.native="confirmDeleteSubItem" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.deleteSubItem.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="modal.show" title="HUB Contractor Invoice Amount" scrollable size="lg" hide-footer>
				<error-list :errors="modal.errors"></error-list>

				<sr-reimbursement-doc-form :item="modal.item" 
					:invoice="modal.isUpdate ? modal.invoice : null" :agreement-reimbursement="modal.isUpdate ? modal.agreementReimbursement : null"
					:is-update="modal.isUpdate" @change="modalSaved" @cancel="cancelModal"></sr-reimbursement-doc-form>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import FileManager from '@/components/FileManager';
	import SrReimbursementForm from './SrReimbursementForm.vue';
	import SrReimbursementDocForm from './SrReimbursementDocForm.vue';

	export default {
		name: 'SrReimbursementEdit',
		components: {
			SrReimbursementForm, SrReimbursementDocForm, FileManager
		},
		data() {
			return {
				apiUrl: 'srreimbursementsubmissions',
				listRoute: 'SrReimbursementList',
				subItemApiUrl: 'srreimbursementsubmissiondocs',
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					tabIndex: 0,
					delete: {
						show: false,
						errors: [],
						saving: false
					},
					deleteSubItem: {
						show: false,
						errors: [],
						saving: false,
						id: null
					},
				},
				item: {},
				files: [],
				setOptions: {
					departments: [],
					submissionCategories: []
				},
				docs: {
					fields: [
						{ key: 'edit', label: '', stickyColumn: true },
						{ key: 'docType', label: 'Type', sortable: true },
						{ key: 'invoice', sortable: true },
						{ key: 'agreementReimbursement', sortable: true },
						{ key: 'totalAmount', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'stateAdminAmount', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'stateVoucherNumber', label: 'State Voucher #', sortable: true },
						{ key: 'delete', label: '' }
					],
					items: []
				},
				modal: {
					item: {},
					invoice: {},
					agreementReimbursement: {},
					show: false,
					loading: false,
					errors: [],
					isUpdate: false
				},
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.log(response.data);
					this.item = response.data.item;
					this.files = response.data.files;
					this.docs.items = response.data.docs;
					this.setOptions = response.data.setOptions;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async changesSaved() {
				await this.get();
				this.$refs.itemForm.launchToast();
				this.page.tabIndex = 2;
			},
			async filesChanged() {
				await this.get();
				this.page.tabIndex = 3;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$router.push({ name: this.listRoute });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			deleteSubItem(id) {
				this.page.deleteSubItem.id = id;
				this.page.deleteSubItem.show = true;
			},
			async confirmDeleteSubItem() {
				if (this.page.deleteSubItem.id !== null) {
					this.page.deleteSubItem.errors = [];
					this.page.deleteSubItem.saving = true;

					try {
						await this.$http.delete(`${this.subItemApiUrl}/${this.page.deleteSubItem.id}`, this.getTokenHeader());
						this.page.deleteSubItem.show = false;
						await this.get();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.deleteSubItem.errors = this.logError(error);
					}

					this.page.deleteSubItem.saving = false;
				}
			},
			async editSubItem(id) {
				await this.getModal(id);
				this.modal.show = true;
			},
			createSubItem() {
				this.resetModal();
				this.modal.show = true;
			},
			async modalSaved() {
				this.modal.show = false;
				this.resetModal();
				await this.get();
				this.$bvToast.show('subitem-saved');
			},
			cancelModal() {
				this.modal.show = false;
				this.resetModal();
			},
			resetModal() {
				this.modal.item = {
					submissionId: Number(this.itemId),
					docType: null,
					invoiceId: null,
					agreementReimbursementId: null,
					totalAmount: 0,
					stateAdminAmount: 0,
					stateVoucherNumber: null,
				}
				this.modal.isUpdate = false;
			},
			async getModal(id) {
				this.modal.loading = true;
				this.modal.errors = [];

				try {
					const response = await this.$http.get(`${this.subItemApiUrl}/${id}`, this.getTokenHeader());
					this.log(response.data);
					this.modal.item = response.data.item;
					this.modal.invoice = response.data.invoice;
					this.modal.agreementReimbursement = response.data.agreementReimbursement;
					this.modal.isUpdate = true;
				} catch (error) {
					this.modal.errors = this.logError(error);
				}

				this.modal.loading = false;
			},
		},
		computed: {
			itemId() {
				return this.$route.params.id;
			}
		}
	}
</script>