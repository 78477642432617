<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
			Your changes have been saved.
		</b-toast>

		<b-form @submit.prevent="save">
			<b-row>
				<b-col md>
					<b-form-group label="Agreement #" :invalid-feedback="requiredFeedback($v.item.agreementNumber)">
						<b-form-input type="text" required 
										v-model.trim="$v.item.agreementNumber.$model" :state="getValidState($v.item.agreementNumber)" />
					</b-form-group>

					<b-form-group label="Project" :invalid-feedback="requiredFeedback($v.query.project)">
						<b-typeahead v-model.trim="$v.query.project.$model" required placeholder="Type to search projects by number, name, or dam..."
										:data="options.projects == null ? [] : options.projects"
										:serializer="s => s.name" @hit="item.projectId = $event.id;"
										:max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Funding Source" :invalid-feedback="requiredFeedback($v.item.fundingSourceId)">
						<b-form-select :options="setOptions.fundingSources"
							v-model.trim="$v.item.fundingSourceId.$model" :state="getValidState($v.item.fundingSourceId)">
							<b-form-select-option :value="null">-- Select a funding source --</b-form-select-option>	
						</b-form-select>
					</b-form-group>


					<b-form-group label="Offeror" :invalid-feedback="requiredFeedback($v.item.offeror)">
						<b-typeahead v-model.trim="$v.item.offeror.$model"
									 :data="setOptions.offerors"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Offeree" :invalid-feedback="requiredFeedback($v.item.offeree)">
						<b-typeahead v-model.trim="$v.item.offeree.$model"
									 :data="setOptions.offerees"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Status" :invalid-feedback="requiredFeedback($v.item.status)">
						<b-typeahead v-model.trim="$v.item.status.$model"
									 :data="setOptions.statuses"
									 :minMatchingChars="1" showAllResults showOnFocus>
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Execution Date" :invalid-feedback="requiredFeedback($v.item.executionDate)">
						<b-form-input type="date" v-model.trim="$v.item.executionDate.$model" :state="getValidState($v.item.executionDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Notes" :invalid-feedback="requiredFeedback($v.item.notes)" class="mb-4">
						<b-form-textarea v-model.trim="$v.item.notes.$model" :state="getValidState($v.item.notes)" rows="5"></b-form-textarea>
					</b-form-group>
				</b-col>
			</b-row>

			<div v-if="!isUpdate">
				<h3 class="h5 border-bottom my-4">Initial Amendment Data</h3>

				<b-row>
					<b-col md>
						<b-form-group label="Amendment Date" :invalid-feedback="requiredFeedback($v.amendment.amendmentDate)">
							<b-form-input type="date" v-model.trim="$v.amendment.amendmentDate.$model" :state="getValidState($v.amendment.amendmentDate)"></b-form-input>
						</b-form-group>

						<b-form-group label="Expiration Date" :invalid-feedback="requiredFeedback($v.amendment.expirationDate)">
							<b-form-input type="date" v-model.trim="$v.amendment.expirationDate.$model" :state="getValidState($v.amendment.expirationDate)"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col md>
						<b-form-group label="Revision #">
							<b-form-input type="number" v-model.trim="$v.amendment.revisionNumber.$model" readonly></b-form-input>
						</b-form-group>

						<b-form-group label="Revision Date" :invalid-feedback="requiredFeedback($v.amendment.revisionDate)">
							<b-form-input type="date" v-model.trim="$v.amendment.revisionDate.$model" :state="getValidState($v.amendment.revisionDate)"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row>
					<b-col md>
						<h3 class="h5 border-bottom my-4">Construction Cost Share</h3>
						<b-row>
							<b-col md>
								<b-form-group label="Reimbursable %" :invalid-feedback="requiredFeedback($v.amendment.constructionPercent)">
									<b-input-group append="%">
										<b-form-input type="number" step="any"
													v-model.trim.number="$v.amendment.constructionPercent.$model" :state="getValidState($v.amendment.constructionPercent)" />
									</b-input-group>
								</b-form-group>
							</b-col>
							<b-col md>
								<b-form-group label="Amount" :invalid-feedback="requiredFeedback($v.amendment.constructionAmount)">
									<b-input-group prepend="$">
										<b-form-input type="number" step="any"
													v-model.trim.number="$v.amendment.constructionAmount.$model" :state="getValidState($v.amendment.constructionAmount)" />
									</b-input-group>
								</b-form-group>
							</b-col>
						</b-row>

						<h3 class="h5 border-bottom my-4">Professional Services Cost Share</h3>
						<b-row>
							<b-col md>
								<b-form-group label="Reimbursable %" :invalid-feedback="requiredFeedback($v.amendment.profServicesPercent)">
									<b-input-group append="%">
										<b-form-input type="number" step="any"
													v-model.trim.number="$v.amendment.profServicesPercent.$model" :state="getValidState($v.amendment.profServicesPercent)" />
									</b-input-group>
								</b-form-group>
							</b-col>
							<b-col md>
								<b-form-group label="Amount" :invalid-feedback="requiredFeedback($v.amendment.profServicesAmount)">
									<b-input-group prepend="$">
										<b-form-input type="number" step="any"
													v-model.trim.number="$v.amendment.profServicesAmount.$model" :state="getValidState($v.amendment.profServicesAmount)" />
									</b-input-group>
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
					<b-col md>
						<h3 class="h5 border-bottom my-4">Administrative Cost Share Expenses</h3>
						<b-row>
							<b-col md>
								<b-form-group label="Reimbursable %" :invalid-feedback="requiredFeedback($v.amendment.adminPercent)">
									<b-input-group append="%">
										<b-form-input type="number" step="any"
													v-model.trim.number="$v.amendment.adminPercent.$model" :state="getValidState($v.amendment.adminPercent)" />
									</b-input-group>
								</b-form-group>
							</b-col>
							<b-col md>
								<b-form-group label="Amount" :invalid-feedback="requiredFeedback($v.amendment.adminAmount)">
									<b-input-group prepend="$">
										<b-form-input type="number" step="any"
													v-model.trim.number="$v.amendment.adminAmount.$model" :state="getValidState($v.amendment.adminAmount)" />
									</b-input-group>
								</b-form-group>
							</b-col>
						</b-row>

						<h3 class="h5 border-bottom my-4">Land Rights Cost Share</h3>
						<b-row>
							<b-col md>
								<b-form-group label="Reimbursable %" :invalid-feedback="requiredFeedback($v.amendment.landRightsPercent)">
									<b-input-group append="%">
										<b-form-input type="number" step="any"
													v-model.trim.number="$v.amendment.landRightsPercent.$model" :state="getValidState($v.amendment.landRightsPercent)" />
									</b-input-group>
								</b-form-group>
							</b-col>
							<b-col md>
								<b-form-group label="Amount" :invalid-feedback="requiredFeedback($v.amendment.landRightsAmount)">
									<b-input-group prepend="$">
										<b-form-input type="number" step="any"
													v-model.trim.number="$v.amendment.landRightsAmount.$model" :state="getValidState($v.amendment.landRightsAmount)" />
									</b-input-group>
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
				</b-row>

				<b-form-group label="Non Cost Share Amount" :invalid-feedback="requiredFeedback($v.amendment.nonCostShareAmount)">
					<b-input-group prepend="$">
						<b-form-input type="number" step="any"
							v-model.trim.number="$v.amendment.nonCostShareAmount.$model" :state="getValidState($v.amendment.nonCostShareAmount)" />
					</b-input-group>
				</b-form-group>

				<b-form-group label="Amendment Notes" :invalid-feedback="requiredFeedback($v.amendment.notes)" class="mb-4">
					<b-form-textarea v-model.trim="$v.amendment.notes.$model" :state="getValidState($v.amendment.notes)" rows="5"></b-form-textarea>
				</b-form-group>
			</div>			

			<fixed-action-bar>
				<save-button :saving="page.saving" class="mr-2" />
				<back-button />
				<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
					You have errors in the form.
				</div>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.saveConfirmShow" title="Confirm Save" scrollable size="lg">
			<error-list :errors="page.errors"></error-list>

			<b-form-group label="Add a note to describe changes?" description="Optional">
				<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
			</b-form-group>

			<div slot="modal-footer">
				<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
				<b-button type="button" variant="primary" @click="page.saveConfirmShow = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required, integer, decimal } from 'vuelidate/lib/validators';
	import _ from 'underscore';

	export default {
		name: 'SrAgreementForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			project: {
				type: Object,
				required: false
			},
			setOptions: {
				type: Object,
				default: () => {
					return {
						offerors: [],
						offerees: [],
						statuses: [],
						fundingSources: []
					}
				}
			}
		},
		data() {
			return {
				apiUrl: 'sragreements',
				apiUrl2: 'sragreementamendments',
				editRoute: 'SrAgreementEdit',
				page: {
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				},
				options: {
					projects: []
				},
				query: {
					project: '',
				},
				amendment: {
					agreementId: 0,
					revisionNumber: 0,
					amendmentDate: null,
					notes: null,
					expirationDate: null,
					constructionPercent: 0,
					constructionAmount: 0,
					profServicesPercent: 0,
					profServicesAmount: 0,
					adminPercent: 0,
					adminAmount: 0,
					landRightsPercent: 0,
					landRightsAmount: 0,
					nonCostShareAmount: 0,
					revisionDate: null
				}
			}
		},
		computed: {
			queryProject() {
				return this.query.project;
			},
		},
		validations: {
			item: {
				projectId: { required, integer },
				agreementNumber: { required },
				agreementType: { required },
				fundingSourceId: {},
				offeror: {},
				offeree: {},
				status: {},
				executionDate: {},
				notes: {},
			},
			amendment: {
				agreementId: {},
				revisionNumber: { required, integer },
				amendmentDate: {},
				notes: {},
				expirationDate: {},
				constructionPercent: { required, decimal },
				constructionAmount: { required, decimal },
				profServicesPercent: { required, decimal },
				profServicesAmount: { required, decimal },
				adminPercent: { required, decimal },
				adminAmount: { required, decimal },
				landRightsPercent: { required, decimal },
				landRightsAmount: { required, decimal },
				nonCostShareAmount: { required, decimal },
				revisionDate: {}
			},
			query: {
				project: { required }
			}
		},
		watch: {
			queryProject: _.debounce(function (query) { this.findProjects(query) }, 500),
		},
		created() {
			this.get();
		},
		methods: {
			get() {
				if (!this.isNullOrEmpty(this.project)) {
					this.query.project = this.project.name;
					this.item.projectId = this.project.id;
				}

				if (this.isUpdate) {
					this.item.executionDate = this.dateForForm(this.item.executionDate);
				}
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.isUpdate) {
						this.page.saveConfirmShow = true;
					} else {
						try {
							const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
							this.log(response.data);

							let newId = response.data;
							this.amendment.agreementId = newId;
							const response2 = await this.$http.post(this.apiUrl2, this.amendment, this.getTokenHeader());
							this.log(response2.data);

							this.$router.push({ name: this.editRoute, params: { id: newId } });
						} catch (error) {
							if (this.isApiUnauthorized(error)) this.page.showLogin = true;
							else this.page.errors = this.logError(error);
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.id}`, data, this.getTokenHeader());
						this.log(response.data);

						this.page.saveConfirmShow = false;
						this.$bvToast.show('changes-saved');
						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			launchToast() {
				this.$bvToast.show('changes-saved');
			},
			logValidations() {
				this.log(this.$v);
			},
			async findProjects(query) {
				try {
					//const response = await this.$http.get(`/srprojects/find-id/${encodeURIComponent(query)}`, this.getTokenHeader());
					const response = await this.$http.post(`/srprojects/find-id-post`, { value: query }, this.getTokenHeader());
					this.options.projects = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
		}
	}
</script>
