<template>
	<div class="print-container">
		<auth-container :page="$parent.page">
			<print-menu></print-menu>

			<div class="d-flex mb-4">
				<h2 class="mr-2">Flood Control Structural Repair Program<br />Application #{{$parent.item.appNum}}</h2>
				<div class="ml-auto text-right">
					<img :src="`${publicPath}images/logos/tsswcb-logo.svg`" alt="TSSWCB Logo" class="img-fluid" style="width:170px">
				</div>
			</div>

			<b-row>
				<b-col md>
					<b-table-simple small class="table-definitions">
						<b-tbody>
							<b-tr>
								<b-th>Request Type</b-th>
								<b-td>{{valueOrNa($parent.item.requestType)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Dam</b-th>
								<b-td>
									<span v-if="isNullOrEmpty($parent.item.damId)">N/A</span>
									<span v-else>{{$parent.dam.name}}</span>
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>SLO Agent</b-th>
								<b-td>{{valueOrNa($parent.item.sloAgent)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Primary Name</b-th>
								<b-td>{{valueOrNa($parent.item.primaryName)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Alternate Name</b-th>
								<b-td>{{valueOrNa($parent.item.alternateName)}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-col>
				<b-col md>
					<b-table-simple small class="table-definitions">
						<b-tbody>
							<b-tr>
								<b-th>Date Received</b-th>
								<b-td>{{$parent.item.receivedDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Funding Cycle</b-th>
								<b-td>{{valueOrNa($parent.item.fundCycle)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Score</b-th>
								<b-td>{{valueOrNa($parent.item.score)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Score Date</b-th>
								<b-td>{{$parent.item.scoreDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Notes</b-th>
								<b-td>{{valueOrNa($parent.item.notes, 'None')}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-col>
			</b-row>

			<h3 class="mb-3">Cost Estimates</h3>
			<b-table-simple small class="border-bottom">
				<b-thead class="bg-light">
					<b-tr>
						<b-th>Total Estimated Cost</b-th>
						<b-th>Estimated Federal Cost Share</b-th>
						<b-th>Estimated State Cost Share</b-th>
						<b-th>Estimated Sponsors/Applicants Cost Share</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr>
						<b-td>${{$parent.totalEstimatedCost | number(2)}}</b-td>
						<b-td>${{$parent.item.federalCostShare | number(2)}}</b-td>
						<b-td>${{$parent.item.stateCostShare | number(2)}}</b-td>
						<b-td>${{$parent.item.sloCostShare | number(2)}}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</auth-container>
	</div>
</template>

<script>
	import PrintMenu from '@/components/helpers/PrintMenu';

	export default {
		name: 'SrApplicationPrint',
		components: { PrintMenu }
	}
</script>