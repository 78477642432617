<template>
	<auth-container :page="page">
		<h2 class="mb-4">Create New Design Phase</h2>

		<sr-design-form :item="item" :project="project" :set-options="setOptions" :showNoExist="showNoExist"></sr-design-form>
	</auth-container>
</template>

<script>
	import SrDesignForm from './SrDesignForm.vue';
	export default {
		name: 'SrDesignCreate',
		components: {
			SrDesignForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				item: {
					projectId: null,
					assignedAe: null,
					pointOfContact: null,
					status: 0,
					notes: null,
					approvingAgent: null,
					finalStatus: null,
					finalDate: null,
					finalContractDate: null
				},
				project: null,
				setOptions: {},
				shoNoExist: false
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response2 = await this.$http.get(`srdesigns/options`, this.getTokenHeader());
					this.setOptions = response2.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				if (!this.isNullOrEmpty(this.$route.query.projectId)) {
					try {
						const response = await this.$http.get(`srprojects/getname/${this.$route.query.projectId}`, this.getTokenHeader());
						this.project = response.data;
						this.showNoExist = true;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.loading = false;
			}
		}
	}
</script>