<template>
	<auth-container :page="page">
		<h2 class="mb-4">Create New Reimbursement Submission</h2>

		<sr-reimbursement-form :item="item" :set-options="setOptions"></sr-reimbursement-form>
	</auth-container>
</template>

<script>
	import SrReimbursementForm from './SrReimbursementForm.vue';
	export default {
		name: 'SrReimbursementCreate',
		components: {
			SrReimbursementForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				item: {
					submissionNumber: 0,
					submissionDate: null,
					agencyStaff: null,
					department: null,
					approvalNoticeDate: null,
					fundsArrivalDate: null,
					fundsLeftDate: null,
					submissionCategory: null,
					notes: null
				},
				setOptions: {
					departments: [],
					submissionCategories: []
				},
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`srreimbursementsubmissions/options`, this.getTokenHeader());
					this.setOptions = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		}
	}
</script>