<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<div v-if="!page.saveConfirmShow">
				<b-form @submit.prevent="save">
					<b-form-group label="Project" :invalid-feedback="requiredFeedback($v.query.project)">
						<b-typeahead v-model.trim="$v.query.project.$model" required placeholder="Type to search projects by number, name, or dam..."
										:data="options.projects == null ? [] : options.projects"
										:serializer="s => s.name" @hit="item.projectId = $event.id;"
										:max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<b-form-group label="Notes" :invalid-feedback="requiredFeedback($v.item.notes)" class="mb-4">
						<b-form-textarea v-model.trim="$v.item.notes.$model" :state="getValidState($v.item.notes)" rows="3"></b-form-textarea>
					</b-form-group>

					<b-form-group label="Current Revision" :invalid-feedback="requiredFeedback($v.rev.workOrderRevisionId)">
						<b-form-select :options="revisions"
							v-model.trim="$v.rev.workOrderRevisionId.$model" :state="getValidState($v.rev.workOrderRevisionId)">
							<b-form-select-option :value="null">-- Select a revision from this work order --</b-form-select-option>	
						</b-form-select>
					</b-form-group>

					<b-form-group label="Funding Amount" :invalid-feedback="requiredFeedback($v.rev.fundingAmount)">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any"
										  v-model.trim.number="$v.rev.fundingAmount.$model" :state="getValidState($v.rev.fundingAmount)" />
						</b-input-group>
					</b-form-group>

					<b-form-group label="Revision Date" :invalid-feedback="requiredFeedback($v.rev.revisionDate)">
						<b-form-input type="date" v-model.trim="$v.rev.revisionDate.$model" :state="getValidState($v.rev.revisionDate)"></b-form-input>
					</b-form-group>

					<b-form-group label="Revision Notes" :invalid-feedback="requiredFeedback($v.rev.notes)" class="mb-4">
						<b-form-textarea v-model.trim="$v.rev.notes.$model" :state="getValidState($v.rev.notes)" rows="3"></b-form-textarea>
					</b-form-group>

					<div class="mt-4 mb-3 d-flex align-items-center">
						<save-button :saving="page.saving" class="mr-2" />
						<b-button type="button" variant="secondary" @click="cancel" class="ml-1">Cancel</b-button>
						<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
							<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
							You have errors in the form.
						</div>
					</div>
				</b-form>
			</div>
			<div v-else>
				<b-form-group label="Add a note to describe changes?" description="Optional">
					<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
				</b-form-group>

				<div class="my-3">
					<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
					<b-button type="button" variant="secondary" @click="cancel" class="ml-1">Cancel</b-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { required, integer, decimal } from 'vuelidate/lib/validators';
	import _ from 'underscore';

	export default {
		name: 'SrWorkOrderDamForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			project: {
				type: Object,
				required: false
			},
			revisions: {
				type: Array,
				required: true
			}
		},
		data() {
			return {
				apiUrl: 'srworkorderdams',
				apiUrl2: 'srworkorderdamrevisions',
				page: {
					loading: false,
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null,
					loadingAgreements: false
				},
				options: {
					projects: []
				},
				query: {
					project: '',
				},
				rev: {
					workOrderDamId: null,
					workOrderRevisionId: null,
					fundingAmount: 0,
					revisionDate: null,
					notes: null
				}
			}
		},
		validations: {
			item: {
				workOrderId: { required, integer },
				projectId: { required, integer },
				notes: {}
			},
			query: {
				project: { required }
			},
			rev: {
				workOrderDamId: {},
				workOrderRevisionId: { required, integer },
				fundingAmount: { required, decimal },
				revisionDate: {},
				notes: {}
			}
		},
		computed: {
			queryProject() {
				return this.query.project;
			},
		},
		watch: {
			queryProject: _.debounce(function (query) { this.findProjects(query) }, 500),
		},
		created() {
			this.get();
		},
		methods: {
			async get() {
				if (!this.isNullOrEmpty(this.project)) {
					this.query.project = this.project.name;
					this.item.projectId = this.project.id;
				}

				if (this.revisions.length > 0) {
					this.rev.workOrderRevisionId = this.revisions[0].value;
				}

				if (this.isUpdate) {
					this.item.revisionDate = this.dateForForm(this.item.revisionDate);
				}
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;
				this.logValidations();

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.page.errors.length < 1) {
						if (this.isUpdate) {
							this.page.saveConfirmShow = true;
						} else {
							try {
								const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
								this.log(response.data);

								let newId = response.data;
								this.rev.workOrderDamId = newId;
								const response2 = await this.$http.post(this.apiUrl2, this.rev, this.getTokenHeader());
								this.log(response2.data);

								this.$emit('change');
							} catch (error) {
								if (this.isApiUnauthorized(error)) this.page.showLogin = true;
								else this.page.errors = this.logError(error);
							}
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.id}`, data, this.getTokenHeader());
						this.log(response.data);

						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			logValidations() {
				this.log(this.$v);
			},
			cancel() {
				this.$emit('cancel');
			},
			async findProjects(query) {
				try {
					//const response = await this.$http.get(`/srprojects/find-id/${encodeURIComponent(query)}`, this.getTokenHeader());
					const response = await this.$http.post(`/srprojects/find-id-post`, { value: query }, this.getTokenHeader());
					this.options.projects = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
		}
	}
</script>
