<template>
	<div class="print-container">
		<auth-container :page="$parent.page">
			<print-menu></print-menu>

			<div class="d-flex mb-4">
				<h2 class="mr-2">Flood Control Structural Repair Program<br />Dam Assessment Phase for TPN {{$parent.project.projectNumber}}</h2>
				<div class="ml-auto text-right">
					<img :src="`${publicPath}images/logos/tsswcb-logo.svg`" alt="TSSWCB Logo" class="img-fluid" style="width:170px">
				</div>
			</div>

			<b-row>
				<b-col md>
					<b-table-simple small class="table-definitions">
						<b-tbody>
							<b-tr>
								<b-th>Project</b-th>
								<b-td>
									#{{$parent.project.projectNumber}} - {{$parent.project.name}}
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>Dam</b-th>
								<b-td>
									<span v-if="isNullOrEmpty($parent.dam.id)">N/A</span>
									<span v-else>{{$parent.dam.name}}</span>
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>Name</b-th>
								<b-td>{{valueOrNa($parent.item.name)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Final Report</b-th>
								<b-td>{{$parent.item.finalReportDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>NRCS Contact</b-th>
								<b-td>{{valueOrNa($parent.item.nrcsContact)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Assigned A&amp;E</b-th>
								<b-td>{{valueOrNa($parent.item.assignedAe)}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Status</b-th>
								<b-td>{{$parent.item.status | Number(2)}}%</b-td>
							</b-tr>
							<b-tr>
								<b-th>Notes</b-th>
								<b-td>{{valueOrNa($parent.item.notes, 'None')}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-col>
			</b-row>
		</auth-container>
	</div>
</template>

<script>
	import PrintMenu from '@/components/helpers/PrintMenu';

	export default {
		name: 'SrDamAssessmentPrint',
		components: { PrintMenu }
	}
</script>