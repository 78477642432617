<template>
	<div>
		<auth-container :page="page" v-if="$route.name === 'SrInvoiceEdit'">
			<h2 class="mb-4">Invoice #{{item.invoiceNumber}}</h2>

			<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
				<b-tab title="Overview">
					<b-row>
						<b-col md>
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Invoice #</b-th>
										<b-td>{{valueOrNa(item.invoiceNumber)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Work Order</b-th>
										<b-td>
											<router-link :to="`/sr/workorders/${workOrder.id}`">#{{workOrder.workOrderNumber}} {{workOrder.name}}</router-link>
										</b-td>
									</b-tr>
									<b-tr>
										<b-th>TPN</b-th>
										<b-td>
											<router-link :to="`/sr/projects/${project.id}`">{{project.projectNumber}} - {{project.name}}</router-link>
										</b-td>
									</b-tr>
									<b-tr>
										<b-th>Dam</b-th>
										<b-td>
											<span v-if="isNullOrEmpty(dam.id)">N/A</span>
											<router-link v-else :to="`/dams/${dam.id}`">{{dam.name}}</router-link>
										</b-td>
									</b-tr>
									<b-tr>
										<b-th>Invoice Submit Date</b-th>
										<b-td>{{item.invoiceSubmitDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Service Start Date</b-th>
										<b-td>{{item.serviceStartDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Service End Date</b-th>
										<b-td>{{item.serviceEndDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Reviewer</b-th>
										<b-td>{{valueOrNa(item.reviewer)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Approval Date</b-th>
										<b-td>{{item.approvalDate | date('ddd, MMM D, YYYY', 'N/A')}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Notes</b-th>
										<b-td>{{valueOrNa(item.notes, 'None')}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
						<b-col md>
							<b-table-simple small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Labor Amount</b-th>
										<b-td>{{money(item.labor)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Labor Amount</b-th>
										<b-td>{{money(item.nonLabor)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Subconsultant Amount</b-th>
										<b-td>{{money(item.subconsultant)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Total</b-th>
										<b-td>{{money(item.labor + item.nonLabor + item.subconsultant)}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-col>
					</b-row>

					<fixed-action-bar>
						<b-button type="button" variant="primary" to="print" append target="_blank" class="mr-2">Print</b-button>
						<back-button />
						<b-button v-if="isAuthorized(roleNames.admin)" type="button" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="HUB Invoices">
					<b-table striped responsive small show-empty :fields="hubs.fields" :items="hubs.items">
						<template #cell(edit)="data">
							<font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'edit']" class="pointer text-primary" @click="editSubItem(data.item.id)" />
						</template>
						<template #cell(delete)="data">
							<font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'times']" class="pointer text-danger" @click="deleteSubItem(data.item.id)" />
						</template>
						<template #cell(vendor)="data">
							<router-link :to="`/sr/hubs/${data.item.hubContractorId}`">{{data.value}}</router-link>
						</template>
						<template #empty>
							<p class="my-2">This invoice does not have any HUB amounts entered.</p>
						</template>

						<template #custom-foot>
							<b-tr class="bg-light border-bottom font-weight-bold">
								<b-td colspan="2">Total</b-td>
								<b-td class="text-right">${{ sum(hubs.items, 'amount') | number(2)}}</b-td>
								<b-td></b-td>
							</b-tr>
						</template>
					</b-table>

					<fixed-action-bar>
						<b-button v-if="!isReadOnly" type="button" variant="success" @click="createSubItem" class="mr-2">Create HUB Invoice Amount</b-button>
					</fixed-action-bar>
				</b-tab>
				<b-tab title="Edit" v-if="!isReadOnly">
					<sr-invoice-form ref="itemForm" :item="item" :work-order-dam="workOrderDam" is-update @change="changesSaved"></sr-invoice-form>
				</b-tab>
				<b-tab title="Files">
					<file-manager :apiUrl="apiUrl" :files="files" :itemId="itemId" itemLabel="application" @change="filesChanged"></file-manager>
				</b-tab>
				<b-tab title="Change Log">
					<log-viewer db-table="SrWorkOrderInvoices" :item-id="itemId"></log-viewer>
				</b-tab>
			</b-tabs>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete this invoice?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.deleteSubItem.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.deleteSubItem.errors"></error-list>

				<p>
					Are you sure you want to delete this HUB invoice amount?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.deleteSubItem.saving" @click.native="confirmDeleteSubItem" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.deleteSubItem.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="modal.show" title="HUB Contractor Invoice Amount" scrollable size="lg" hide-footer>
				<error-list :errors="modal.errors"></error-list>

				<sr-invoice-hub-amount-form :item="modal.item" :hubs="hubOptions" :is-update="modal.isUpdate" @change="modalSaved" @cancel="cancelModal"></sr-invoice-hub-amount-form>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import FileManager from '@/components/FileManager';
	import SrInvoiceForm from './SrInvoiceForm.vue';
	import SrInvoiceHubAmountForm from './SrInvoiceHubAmountForm.vue';

	export default {
		name: 'SrInvoiceEdit',
		components: {
			SrInvoiceForm, SrInvoiceHubAmountForm, FileManager
		},
		data() {
			return {
				apiUrl: 'srworkorderinvoices',
				listRoute: 'SrInvoiceList',
				subItemApiUrl: 'srworkorderinvoicehubs',
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					tabIndex: 0,
					delete: {
						show: false,
						errors: [],
						saving: false
					},
					deleteSubItem: {
						show: false,
						errors: [],
						saving: false,
						id: null
					},
				},
				item: {},
				dam: {},
				project: {},
				workOrder: {},
				workOrderDam: {},
				files: [],
				hubOptions: [],
				hubs: {
					fields: [
						{ key: 'edit', label: '', stickyColumn: true },
						{ key: 'vendor', sortable: true },
						{ key: 'amount', sortable: true, class: 'text-right', formatter: (value) => { return this.money(value); } },
						{ key: 'delete', label: '' }
					],
					items: []
				},
				modal: {
					item: {},
					show: false,
					loading: false,
					errors: [],
					isUpdate: false
				},
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.log(response.data);
					this.item = response.data.item;
					this.dam = response.data.dam;
					this.project = response.data.project;
					this.workOrder = response.data.workOrder;
					this.workOrderDam = response.data.workOrderDamForForm;
					this.files = response.data.files;
					this.hubs.items = response.data.hubs;
					this.hubOptions = response.data.hubOptions;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async changesSaved() {
				await this.get();
				this.$refs.itemForm.launchToast();
				this.page.tabIndex = 2;
			},
			async filesChanged() {
				await this.get();
				this.page.tabIndex = 3;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`${this.apiUrl}/${this.itemId}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$router.push({ name: this.listRoute });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			deleteSubItem(id) {
				this.page.deleteSubItem.id = id;
				this.page.deleteSubItem.show = true;
			},
			async confirmDeleteSubItem() {
				if (this.page.deleteSubItem.id !== null) {
					this.page.deleteSubItem.errors = [];
					this.page.deleteSubItem.saving = true;

					try {
						await this.$http.delete(`${this.subItemApiUrl}/${this.page.deleteSubItem.id}`, this.getTokenHeader());
						this.page.deleteSubItem.show = false;
						await this.get();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.deleteSubItem.errors = this.logError(error);
					}

					this.page.deleteSubItem.saving = false;
				}
			},
			async editSubItem(id) {
				await this.getModal(id);
				this.modal.show = true;
			},
			createSubItem() {
				this.resetModal();
				this.modal.show = true;
			},
			async modalSaved() {
				this.modal.show = false;
				this.resetModal();
				await this.get();
				this.$bvToast.show('subitem-saved');
			},
			cancelModal() {
				this.modal.show = false;
				this.resetModal();
			},
			resetModal() {
				this.modal.item = {
					workOrderInvoiceId: Number(this.itemId),
					hubContractorId: null,
					amount: null
				}
				this.modal.isUpdate = false;
			},
			async getModal(id) {
				this.modal.loading = true;
				this.modal.errors = [];

				try {
					const response = await this.$http.get(`${this.subItemApiUrl}/${id}`, this.getTokenHeader());
					this.log(response.data);
					this.modal.item = response.data.item;
					this.modal.isUpdate = true;
				} catch (error) {
					this.modal.errors = this.logError(error);
				}

				this.modal.loading = false;
			},
		},
		computed: {
			itemId() {
				return this.$route.params.id;
			}
		}
	}
</script>