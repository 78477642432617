<template>
	<div>
		<error-list :errors="page.errors"></error-list>
		<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
			Your changes have been saved.
		</b-toast>

		<b-form @submit.prevent="save">
			<b-row>
				<b-col md>
					<b-form-group label="Application #" :invalid-feedback="requiredFeedback($v.query.appNum)">
						<b-typeahead v-model.trim="$v.query.appNum.$model" required
									 :data="options.appNums"
									 :serializer="s => s.name" @hit="item.applicationId = $event.id; item.fundCycle = $event.fundCycle"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Funding Cycle" :invalid-feedback="requiredFeedback($v.item.fundCycle)">
						<b-typeahead v-model.trim="$v.item.fundCycle.$model"
									 :data="options.fundCycle"
									 :max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Date Budget Performed" :invalid-feedback="requiredFeedback($v.item.performedDate)">
						<b-form-input type="date" v-model.trim="$v.item.performedDate.$model" :state="getValidState($v.item.performedDate)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group label="Notes" :invalid-feedback="requiredFeedback($v.item.notes)" class="mb-4">
				<b-form-textarea v-model.trim="$v.item.notes.$model" :state="getValidState($v.item.notes)" rows="5"></b-form-textarea>
			</b-form-group>

			<b-row>
				<b-col md>
					<b-form-group label="Federal Reimbursement for Design" :invalid-feedback="requiredFeedback($v.item.designFederalReimbursement)">
						<b-input-group prepend="$">
							<b-form-input type="number" required step="any"
										  v-model.trim.number="$v.item.designFederalReimbursement.$model" :state="getValidState($v.item.designFederalReimbursement)" />
						</b-input-group>
					</b-form-group>
					<b-form-group label="State Cost for Design" :invalid-feedback="requiredFeedback($v.item.designStateCost)">
						<b-input-group prepend="$">
							<b-form-input type="number" required step="any"
										  v-model.trim.number="$v.item.designStateCost.$model" :state="getValidState($v.item.designStateCost)" />
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Federal Reimbursement for Land Rights" :invalid-feedback="requiredFeedback($v.item.landRightsFederalReimbursement)">
						<b-input-group prepend="$">
							<b-form-input type="number" required step="any"
										  v-model.trim.number="$v.item.landRightsFederalReimbursement.$model" :state="getValidState($v.item.landRightsFederalReimbursement)" />
						</b-input-group>
					</b-form-group>
					<b-form-group label="State Cost for Land Rights" :invalid-feedback="requiredFeedback($v.item.landRightsStateCost)">
						<b-input-group prepend="$">
							<b-form-input type="number" required step="any"
										  v-model.trim.number="$v.item.landRightsStateCost.$model" :state="getValidState($v.item.landRightsStateCost)" />
						</b-input-group>
					</b-form-group>
					<b-form-group label="Sponsor Cost for Land Rights" :invalid-feedback="requiredFeedback($v.item.landRightsSponsorsCost)">
						<b-input-group prepend="$">
							<b-form-input type="number" required step="any"
										  v-model.trim.number="$v.item.landRightsSponsorsCost.$model" :state="getValidState($v.item.landRightsSponsorsCost)" />
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Federal Reimbursement for Construction" :invalid-feedback="requiredFeedback($v.item.constructionFederalReimbursement)">
						<b-input-group prepend="$">
							<b-form-input type="number" required step="any"
										  v-model.trim.number="$v.item.constructionFederalReimbursement.$model" :state="getValidState($v.item.constructionFederalReimbursement)" />
						</b-input-group>
					</b-form-group>
					<b-form-group label="State Cost for Construction" :invalid-feedback="requiredFeedback($v.item.constructionStateCost)">
						<b-input-group prepend="$">
							<b-form-input type="number" required step="any"
										  v-model.trim.number="$v.item.constructionStateCost.$model" :state="getValidState($v.item.constructionStateCost)" />
						</b-input-group>
					</b-form-group>
					<b-form-group label="Sponsor Cost for Construction" :invalid-feedback="requiredFeedback($v.item.constructionSponsorsCost)">
						<b-input-group prepend="$">
							<b-form-input type="number" required step="any"
										  v-model.trim.number="$v.item.constructionSponsorsCost.$model" :state="getValidState($v.item.constructionSponsorsCost)" />
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Federal Subtotal">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any" v-model.number="federalSubtotal" readonly />
						</b-input-group>
					</b-form-group>
					<b-form-group label="State Subtotal">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any" v-model.number="stateSubtotal" readonly />
						</b-input-group>
					</b-form-group>
					<b-form-group label="Sponsor Subtotal">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any" v-model.number="sponsorsSubtotal" readonly />
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>

			<b-row>
				<b-col md>
					<b-form-group label="Design Subtotal">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any" v-model.number="designSubtotal" readonly />
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Land Rights Subtotal">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any" v-model.number="landRightsSubtotal" readonly />
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Construction Subtotal">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any" v-model.number="constructionSubtotal" readonly />
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Total Cost">
						<b-input-group prepend="$">
							<b-form-input type="number" step="any" v-model.number="totalCost" readonly />
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>

			<fixed-action-bar>
				<save-button :saving="page.saving" class="mr-2" />
				<back-button />
				<div v-if="$v.$invalid && $v.$anyDirty" class="ml-auto my-0 py-1 alert alert-warning">
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-1 text-danger" />
					You have errors in the form.
				</div>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.saveConfirmShow" title="Confirm Save" scrollable size="lg">
			<error-list :errors="page.errors"></error-list>

			<b-form-group label="Add a note to describe changes?" description="Optional">
				<b-form-input type="text" maxLength="100" v-model="page.saveDescription" />
			</b-form-group>

			<div slot="modal-footer">
				<save-button :saving="page.saving" class="mr-2" type="button" @click.native="confirmSave" />
				<b-button type="button" variant="primary" @click="page.saveConfirmShow = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required, decimal, integer } from 'vuelidate/lib/validators';
	import _ from 'underscore';

	export default {
		name: 'SrPlanForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			item: {
				type: Object,
				required: true
			},
			application: {
				type: Object,
				required: false
			}
		},
		data() {
			return {
				apiUrl: 'srbudgetplans',
				editRoute: 'SrPlanEdit',
				page: {
					errors: [],
					validated: false,
					saving: false,
					saveConfirmShow: false,
					saveDescription: null
				},
				options: {
					appNums: [],
					fundCycle: []
				},
				query: {
					appNum: ''
				}
			}
		},
		computed: {
			queryAppNum() {
				return this.query.appNum;
			},
			itemFundCycle() {
				return this.item.fundCycle;
			},
			designSubtotal() {
				return this.item.designFederalReimbursement + this.item.designStateCost;
			},
			landRightsSubtotal() {
				return this.item.landRightsFederalReimbursement + this.item.landRightsStateCost + this.item.landRightsSponsorsCost;
			},
			constructionSubtotal() {
				return this.item.constructionFederalReimbursement + this.item.constructionStateCost + this.item.constructionSponsorsCost;
			},
			federalSubtotal() {
				return this.item.designFederalReimbursement + this.item.landRightsFederalReimbursement + this.item.constructionFederalReimbursement;
			},
			stateSubtotal() {
				return this.item.designStateCost + this.item.landRightsStateCost + this.item.constructionStateCost;
			},
			sponsorsSubtotal() {
				return this.item.landRightsSponsorsCost + this.item.constructionSponsorsCost;
			},
			totalCost() {
				return this.federalSubtotal + this.stateSubtotal + this.sponsorsSubtotal;
			}
		},
		validations: {
			item: {
				applicationId: { required, integer },
				performedDate: {},
				fundCycle: {},
				designFederalReimbursement: { required, decimal },
				designStateCost: { required, decimal },
				landRightsFederalReimbursement: { required, decimal },
				landRightsStateCost: { required, decimal },
				landRightsSponsorsCost: { required, decimal },
				constructionFederalReimbursement: { required, decimal },
				constructionStateCost: { required, decimal },
				constructionSponsorsCost: { required, decimal },
				notes: {}
			},
			query: {
				appNum: { required }
			}
		},
		watch: {
			queryAppNum: _.debounce(function (query) { this.findAppNums(query) }, 500),
			itemFundCycle: _.debounce(function (query) { this.find('fundCycle', query) }, 500)
		},
		created() {
			this.get();
		},
		methods: {
			get() {
				if (!this.isNullOrEmpty(this.application)) {
					this.query.appNum = this.application.appNum;
					this.item.applicationId = this.application.id;
					this.item.fundCycle = this.application.fundCycle;
				}
			},
			async save() {
				this.log(this.item);
				this.page.errors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					if (this.isUpdate) {
						this.page.saveConfirmShow = true;
					} else {
						try {
							const response = await this.$http.post(this.apiUrl, this.item, this.getTokenHeader());
							this.log(response.data);

							this.$router.push({ name: this.editRoute, params: { id: response.data } });
						} catch (error) {
							if (this.isApiUnauthorized(error)) this.page.showLogin = true;
							else this.page.errors = this.logError(error);
						}
					}
				}

				this.page.saving = false;
			},
			async confirmSave() {
				this.page.errors = [];
				this.page.saving = true;

				if (this.isUpdate) {
					try {
						let data = {
							item1: this.item,
							item2: this.isNullOrEmpty(this.page.saveDescription) ? "Changes saved." : this.page.saveDescription
						};
						const response = await this.$http.put(`${this.apiUrl}/${this.item.applicationId}`, data, this.getTokenHeader());
						this.log(response.data);

						this.page.saveConfirmShow = false;
						this.$bvToast.show('changes-saved');
						this.$emit('change');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			launchToast() {
				this.$bvToast.show('changes-saved');
			},
			logValidations() {
				this.log(this.$v);
			},
			async findAppNums(query) {
				try {
					const response = await this.$http.get(`/srapplications/find-id/${encodeURIComponent(query)}`, this.getTokenHeader());
					this.options.appNums = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async find(field, query) {
				try {
					const response = await this.$http.get(`/${this.apiUrl}/find/${field}/${query}`, this.getTokenHeader());
					this.options[field] = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			}
		}
	}
</script>
