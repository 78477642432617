<template>
	<auth-container :page="page">
		<h2 class="mb-4">Field Representative Information</h2>

		<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
			<b-tab title="Overview">
				<b-row>
					<b-col md>
						<b-table-simple small class="table-definitions">
							<b-tbody>
								<b-tr>
									<b-th>First Name</b-th>
									<b-td>{{item.firstName}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>Last Name</b-th>
									<b-td>{{item.lastName}}</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
					</b-col>
					<b-col md>
						<h4 class="mb-3">SWCDs</h4>
						<b-table-simple small responsive class="border-bottom" v-if="swcds.length > 0">
							<b-thead class="bg-light">
								<b-tr>
									<b-th></b-th>
									<b-th>SWCD #</b-th>
									<b-th>Name</b-th>
									<b-th v-if="!isReadOnly"></b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr v-for="(s,i) in swcds" :key="i">
									<b-td>
										<router-link :to="`/swcds/${s.id}`" title="View/Edit">
											<font-awesome-icon :icon="['fas', 'edit']" />
										</router-link>
									</b-td>
									<b-td>{{valueOrNa(s.id)}}</b-td>
									<b-td>{{valueOrNa(s.name)}}</b-td>
									<b-td v-if="!isReadOnly">
										<button class="plain text-danger" title="Remove" @click="askDelete(s.id)">
											<font-awesome-icon :icon="['fas', 'times']" />
										</button>
									</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
						<div v-else>
							<p>This rep is not assigned to any SWCDs.</p>
						</div>

						<div v-if="!isReadOnly">
							<b-button variant="primary" @click="page.add.show = true">Add SWCD</b-button>
						</div>
					</b-col>
				</b-row>

				<b-modal v-model="page.delete.show" size="md" title="Confirm removal" no-close-on-backdrop no-close-on-esc hide-header-close>
					<error-list :errors="page.delete.errors"></error-list>

					<p>
						Are you sure you want to remove this rep from SWCD <strong>{{page.delete.swcd}}</strong>?
					</p>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Remove" variant="danger" />
						<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>

				<b-modal v-model="page.add.show" size="md" title="Add SWCD">
					<error-list :errors="page.add.errors"></error-list>

					<b-form-group label="SWCD">
						<b-typeahead id="page.add.swcd"
							v-model="page.add.swcd"
							:data="options.swcd"
							:serializer="s => `${s.id} - ${s.name}`" @hit="page.add.swcdId = $event.id"
							:max-matches="100" :minMatchingChars="1">
						</b-typeahead>
					</b-form-group>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.add.saving" @click.native="addSwcd" text="Add SWCD" />
						<b-button type="button" variant="secondary" @click="page.add.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>

				<fixed-action-bar>
					<back-button />
				</fixed-action-bar>
			</b-tab>
			<b-tab title="Edit" v-if="!isReadOnly">
				<sr-field-rep-form ref="itemForm" :item="item" is-update @change="changesSaved"></sr-field-rep-form>
			</b-tab>
			<b-tab title="Change Log">
				<log-viewer db-table="SrFieldReps" :item-id="itemId"></log-viewer>
			</b-tab>
		</b-tabs>
	</auth-container>
</template>

<script>
	import SrFieldRepForm from './SrFieldRepForm.vue';
	import _ from 'underscore';

	export default {
		name: 'SrFieldRepEdit',
		components: {
			SrFieldRepForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					tabIndex: 0,
					delete: {
						swcd: null,
						show: false,
						errors: [],
						saving: false
					},
					add: {
						swcd: null,
						swcdId: null,
						show: false,
						errors: [],
						saving: false
					}
				},
				options: {
					swcd: []
				},
				item: {},
				swcds: []
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get',
			itemSwcd: _.debounce(function (query) { this.findSwcds(query) }, 500)
		},
		computed: {
			itemId() {
				return this.$route.params.id;
			},
			itemSwcd() {
				return this.page.add.swcd;
			},
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`srfieldreps/${this.itemId}`, this.getTokenHeader());
					this.log(response.data);
					this.item = response.data.contact;

					const response2 = await this.$http.get(`srfieldrepswcds/swcds/${this.itemId}`, this.getTokenHeader());
					this.log(response2.data);
					this.swcds = response2.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async changesSaved() {
				await this.get();
				this.$refs.itemForm.launchToast();
			},
			askDelete(id, name) {
				this.page.delete.swcd = id;
				this.page.delete.show = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`srfieldrepswcds/${this.page.delete.swcd}/${this.item.id}`, this.getTokenHeader());
					this.page.delete.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			async addSwcd() {
				this.page.add.errors = [];
				this.page.add.saving = true;

				try {
					let data = {
						swcdId: this.page.add.swcdId,
						fieldRepId: this.item.id
					};
					await this.$http.post(`srfieldrepswcds`, data, this.getTokenHeader());
					this.page.add.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.add.errors = this.logError(error);
				}

				this.page.add.saving = false;
			},
			async findSwcds(query) {
				try {
					const response = await this.$http.get(`/swcds/find-id/${query}`, this.getTokenHeader());
					this.options.swcd = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
		}
	}
</script>