<template>
	<div>
		<auth-container v-if="$route.name === 'SrReimbursementList'" :page="page">
			<h2 class="mb-4">Reimbursement Submissions</h2>

			<div class="mx-auto">
				<b-row class="mb-3" align-v="end">
					<b-col lg="6">
						<b-form inline>
							<b-input-group>
								<b-form-input v-model="table.filter" placeholder="Search..." @input="filterChg"></b-form-input>

								<b-input-group-append class="pointer mr-2">
									<b-input-group-text @click="clearFilter">
										<font-awesome-icon :icon="['fas', 'times']" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
							<b-button variant="primary" type="button" @click="table.showAdvancedFilter = !table.showAdvancedFilter">Advanced Filter</b-button>
						</b-form>
					</b-col>
					<b-col lg="6" class="text-right">
						<div class="ml-auto">
							Showing {{showingFirst}} - {{showingLast}}
							of {{table.total}}
						</div>
					</b-col>
				</b-row>

				<b-collapse v-model="table.showAdvancedFilter" class="my-3">
					<b-card bg-variant="light">
						<b-row>
							<b-col lg="4">
								<div class="form-group row">
									<label for="advancedFilter.invoiceNumber" class="col-lg-5 col-form-label">Submission #</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.submissionNumber"
													 v-model="advancedFilter.submissionNumber"
													 :data="advancedFilter.options.submissionNumber"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.agencyStaff" class="col-lg-5 col-form-label">Agency Staff</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.agencyStaff"
													 v-model="advancedFilter.agencyStaff"
													 :data="advancedFilter.options.agencyStaff"
													 :max-matches="100" :minMatchingChars="1">
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.department" class="col-lg-5 col-form-label">Department</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.department"
													 v-model="advancedFilter.department"
													 :data="setOptions.departments"
													 :minMatchingChars="1" showAllResults showOnFocus>
										</b-typeahead>
									</div>
								</div>

								<div class="form-group row">
									<label for="advancedFilter.submissionCategory" class="col-lg-5 col-form-label">Submission Category</label>
									<div class="col-lg-7">
										<b-typeahead id="advancedFilter.submissionCategory"
													 v-model="advancedFilter.submissionCategory"
													 :data="setOptions.submissionCategories"
													 :minMatchingChars="1" showAllResults showOnFocus>
										</b-typeahead>
									</div>
								</div>
							</b-col>
							<b-col lg>
								<div class="form-group row" v-for="field in filterDateFields" :key="field.value">
									<label for="advancedFilter[field.value].start" class="col-lg-4 col-form-label">{{field.text}}</label>
									<div class="col-lg-8">
										<div class="d-flex align-items-center justify-content-start">
											<div class="mr-2">
												<b-input-group>
													<b-form-datepicker id="advancedFilter[field.value].start" v-model="advancedFilter[field.value].start"
																	   label-no-date-selected="Start Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter[field.value].start = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
											<div>
												<b-input-group>
													<b-form-datepicker id="advancedFilter[field.value].end" v-model="advancedFilter[field.value].end"
																	   label-no-date-selected="End Date" :date-format-options="defaultDatePickerFormatDisplay"></b-form-datepicker>

													<b-input-group-append class="pointer">
														<b-input-group-text @click="advancedFilter[field.value].end = null">
															<font-awesome-icon :icon="['fas', 'times']" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</div>
										</div>
									</div>
								</div>
							</b-col>
						</b-row>
						<hr class="my-3" />
						<div>
							<b-button variant="primary" type="button" @click="get" class="mr-2">Apply</b-button>
							<b-button variant="secondary" type="button" @click="clearAdvancedFilter" class="mr-2">Clear</b-button>
							<b-button variant="secondary" type="button" @click="table.showAdvancedFilter = false">Close</b-button>
						</div>
					</b-card>
				</b-collapse>

				<b-table striped responsive table-class="table-fixed-height border-bottom table-grid" sticky-header="60vh"
						 small show-empty
						 :items="items" :busy="table.loading"
						 :fields="table.fields"
						 :no-local-sorting="true"
						 :sort-by.sync="table.sort"
						 :sort-desc.sync="table.reverse"
						 @sort-changed="sortChg">
					<template #table-busy>
						<div class="text-center my-4">
							<font-awesome-icon :icon="['fas', 'spinner']" spin size="2x" />
						</div>
					</template>
					<template #cell(edit)="data">
						<router-link :to="`${data.item.id}`" append>
							<font-awesome-icon :icon="['fas', 'edit']" />
						</router-link>
					</template>
					<template #empty>
						<h4>No records found.</h4>
					</template>
					<template #emptyfiltered>
						<h4>No records found matching "{{table.filter}}".</h4>
					</template>
				</b-table>
			</div>

			<fixed-action-bar>
				<router-link v-if="!isReadOnly" to="create" append class="btn btn-success mr-2">
					Create
				</router-link>
				<json-to-csv :api-url="`${apiUrl}/export`" :filename="apiUrl" class="mr-2" />
				<b-button v-if="isAuthorized(roleNames.superAdmin)" variant="primary" @click="page.showModal = true" class="mr-2">Import</b-button>
				<b-pagination class="ml-auto mb-0" v-if="table.total > table.itemsPerPage" size="md" :total-rows="table.total" v-model="table.currentPage" :per-page="table.itemsPerPage" @change="pageChg" />
			</fixed-action-bar>

			<b-modal v-model="page.showModal" title="Upload" scrollable size="lg">
				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<vue-dropzone ref="addFile" id="addFile" :options="dropzoneOptions" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>

				<div class="mt-3">
					<strong>IMPORTANT:</strong>
				</div>
				<ul>
					<li>Use the Download Template button to get the required columns.</li>
					<li>Column headers **must match** the template.</li>
				</ul>

				<div slot="modal-footer">
					<json-to-csv :json-data="template" :filename="`${apiUrl}-template`" text="Download Template" class="mr-2" />
					<b-button type="button" variant="primary" @click="page.showModal = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
	import _ from 'underscore';

	export default {
		name: 'SrReimbursementList',
		components: {
			vueDropzone: vue2Dropzone
		},
		data() {
			return {
				apiUrl: 'srreimbursementsubmissions',
				page: {
					errors: [],
					loading: true,
					showLogin: false,
					saveErrors: [],
					showModal: false
				},
				dropzoneOptions: {
					url: '/api/srreimbursementsubmissions/upload/',
					headers: this.getTokenHeader().headers,
					acceptedFiles: '.csv'
				},
				table: {
					fields: [
						{ key: 'edit', label: '', stickyColumn: true },
						{ key: 'submissionNumber', label: 'Submission #', sortable: true },
						{ key: 'submissionDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'agencyStaff', sortable: true },
						{ key: 'department', sortable: true },
						{ key: 'approvalNoticeDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'fundsArrivalDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'fundsLeftDate', sortable: true, formatter: (value) => { return this.toDate(value, 'M/D/YYYY') } },
						{ key: 'submissionCategory', sortable: true },
					],
					sort: 'submissionDate',
					reverse: true,
					itemsPerPage: 100,
					filter: null,
					currentPage: 1,
					total: 0,
					loading: false,
					showAdvancedFilter: false
				},
				items: [],
				advancedFilter: {
					submissionNumber: null,
					agencyStaff: null,
					department: null,
					submissionCategory: null,
					submissionDate: {
						start: null,
						end: null
					},
					approvalNoticeDate: {
						start: null,
						end: null
					},
					fundsArrivalDate: {
						start: null,
						end: null
					},
					fundsLeftDate: {
						start: null,
						end: null
					},
					options: {
						submissionNumber: [],
						agencyStaff: [],
					}
				},
				setOptions: {
					departments: [],
					submissionCategories: []
				},
				template: [{
					submissionNumber: null,
					submissionDate: null,
					agencyStaff: null,
					department: null,
					approvalNoticeDate: null,
					fundsArrivalDate: null,
					fundsLeftDate: null,
					submissionCategory: null,
				}],
				filterDateFields: [
					{ value: 'submissionDate', text: 'Submission Date' },
					{ value: 'approvalNoticeDate', text: 'Approval Notice Date' },
					{ value: 'fundsArrivalDate', text: 'Funds Arrival Date' },
					{ value: 'fundsLeftDate', text: 'Funds Left Date' },
				]
			}
		},
		computed: {
			showingFirst() {
				return (this.table.currentPage - 1) * this.table.itemsPerPage + 1
			},
			showingLast() {
				var max = (this.table.currentPage - 1) * this.table.itemsPerPage + this.table.itemsPerPage
				return max > this.table.total ? this.table.total : max;
			},
			advancedFilterSubmissionNumber() {
				return this.advancedFilter.submissionNumber;
			},
			advancedFilterAgencyStaff() {
				return this.advancedFilter.agencyStaff;
			},
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get',
			advancedFilterSubmissionNumber: _.debounce(function (query) { this.find('submissionNumber', query) }, 500),
			advancedFilterAgencyStaff: _.debounce(function (query) { this.find('agencyStaff', query) }, 500),
		},
		methods: {
			getDb() {
				let sort = this.table.reverse ? this.table.sort + '_desc' : this.table.sort;
				let filter = this.table.filter !== null ? `&filter=${encodeURIComponent(this.table.filter)}` : '';

				let filterComplex = '';
				let filterValues = [];

				let stringFields = ['submissionNumber', 'agencyStaff', 'department', 'submissionCategory'];
				let twoPartFields = ['submissionDate', 'approvalNoticeDate', 'fundsArrivalDate', 'fundsLeftDate'];

				for (let f of stringFields) {
					if (!this.isNullOrEmpty(this.advancedFilter[f])) {
						filterValues.push(`${f}:${this.advancedFilter[f]}`);
					}
				}

				for (let f of twoPartFields) {
					if (!this.isNullOrEmpty(this.advancedFilter[f].start) || !this.isNullOrEmpty(this.advancedFilter[f].end)) {
						let s = this.advancedFilter[f].start;
						let e = this.advancedFilter[f].end;
						s = s === null ? 'any' : s;
						e = e === null ? 'any' : e;
						filterValues.push(`${f}:${s},${e}`);
					}
				}

				if (filterValues.length > 0) filterComplex = '&filterComplex=' + filterValues.join('|');

				return this.$http.get(`${this.apiUrl}?sort=${sort}&page=${this.table.currentPage}&perPage=${this.table.itemsPerPage}${filter}${filterComplex}`, this.getTokenHeader());
			},
			async filterChg() {
				this.table.currentPage = 1;
				_.debounce(await this.get(), 500);
			},
			async pageChg(page) {
				this.table.currentPage = page;
				await this.get();
			},
			async sortChg(ctx) {
				this.table.sort = ctx.sortBy;
				this.table.reverse = ctx.sortDesc;
				this.table.specialSort = undefined;

				await this.get();
			},
			async get() {
				this.page.errors = [];
				this.table.loading = true;

				try {
					const response1 = await this.$http.get(`${this.apiUrl}/options`, this.getTokenHeader());
					this.setOptions = response1.data;

					const response = await this.getDb();
					this.log(response.data);

					if (response.data.items.length == 0 && this.table.currentPage > 1) {
						this.table.currentPage--;
						await this.get();
					}

					this.items = response.data.items;
					this.table.total = response.data.total;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.table.loading = false;
				this.page.loading = false;
			},
			async fileUploaded(file, response) {
				this.log(file);
				this.log(response);
				this.page.saveErrors = [];
				await this.get();
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.page.saveErrors.push(message);
			},
			async find(field, query) {
				try {
					const response = await this.$http.get(`/${this.apiUrl}/find/${field}/${query}`, this.getTokenHeader());
					this.advancedFilter.options[field] = response.data != '' ? response.data : [];
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			},
			async clearFilter() {
				this.table.filter = null;
				await this.get();
			},
			async clearAdvancedFilter() {
				this.advancedFilter = {
					submissionNumber: null,
					agencyStaff: null,
					department: null,
					submissionCategory: null,
					submissionDate: {
						start: null,
						end: null
					},
					approvalNoticeDate: {
						start: null,
						end: null
					},
					fundsArrivalDate: {
						start: null,
						end: null
					},
					fundsLeftDate: {
						start: null,
						end: null
					},
					options: {
						submissionNumber: [],
						agencyStaff: [],
					}
				};

				await this.get();
			}
		}
	}
</script>